import React, { Component } from "react";

import { themeColors } from "js/react/sharedStyles";
import { Icon } from "./Icon";
import { DisabledStateToolTip } from "./ToolTip";
import styled from "styled-components";
import { tinycolor } from "js/vendor";

const StyledButton = styled.button`
    pointer-events: auto;
    cursor: pointer;
    background: ${props => props.backgroundColor};
    color: ${props => props.color};
    border-radius: ${props => props.rounded ? "18px" : "2px"};
    border: none;
    font-size: ${props => {
        if (props.size == "large") {
            return 14;
        } else if (props.size == "small") {
            return 11;
        } else {
            return 12;
        }
    }}px;
    font-family: Source Sans Pro;
    font-weight: 600;
    letter-spacing: 0.025em;
    width: ${props => props.width ? (props.width + "px") : "auto"};
    height: ${props => props.height || 36}px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: ${props => props.hoverColor ?? "black"};
        border-radius: ${props => props.rounded ? "18px" : "2px"};
        opacity: 0;
        z-index: 0;
        transition: opacity 0.2s ease-in-out;
    }

    span {
        z-index: 1;
        text-transform: uppercase;
    }

    .icon {
        z-index: 1;
    }

    .icon + span {
        margin-left: 8px;
    }

    .bai-icon {
        color: ${props => props.iconColor} !important;  
    }

    &:hover {
        &:before {
            opacity: ${props => props.hoverColor ? 1 : 0.2};
        }
    }

    &:disabled {
        //background: #DAE9F0 !important;
        //color: white !important;
        cursor: not-allowed !important;
        opacity: 0.5;
    }
`;

export class Button extends Component {
    render() {
        const { outline, solid, large, rounded = false, unframed = false, style = {}, small, blue, warning, width, height, disabledTooltip, ...rest } = this.props;

        let icon = React.Children.toArray(this.props.children).filter(child => child.type == Icon)[0];
        let label = React.Children.toArray(this.props.children).filter(child => child.type != Icon);

        let backgroundColor = "#d1d1d1";
        let color = "white";
        let iconColor = "white";
        let hoverColor;
        let border = "none";

        if (blue) {
            backgroundColor = themeColors.ui_blue;
        } else if (warning) {
            backgroundColor = themeColors.warning;
        } else if (this.props.color) {
            backgroundColor = this.props.color;
            hoverColor = tinycolor(backgroundColor).darken(10).toString();
        } else if (unframed) {
            backgroundColor = "transparent";
            color = "#333";
            iconColor = themeColors.ui_blue;
            hoverColor = themeColors.selection;
            style.justifyContent = "flex-start";
        } else {
            backgroundColor = themeColors.rollover;
            color = "rgba(0,0,0,0.76)";
            iconColor = "rgba(0,0,0,0.76)";
            style.border = `solid 1px ${themeColors.ui_blue}`;
            hoverColor = themeColors.selection;
        }

        let size = null;
        if (large) {
            size = "large";
        } else if (small) {
            size = "small";
        }

        return (
            <DisabledStateToolTip {...this.props}>
                <StyledButton className="bai-button"
                    width={width} height={height}
                    backgroundColor={backgroundColor}
                    color={color}
                    iconColor={iconColor}
                    hoverColor={hoverColor}
                    size={size}
                    unframed={unframed}
                    rounded={rounded}
                    style={style}
                    onMouseDown={event => event.preventDefault()}
                    onKeyDown={event => event.preventDefault()}
                    {...rest}
                >
                    {icon}
                    {label.length > 0 && <span>{label}</span>}
                </StyledButton>
            </DisabledStateToolTip>
        );
    }
}

