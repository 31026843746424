import { isPPTAddin } from "js/config";
import { FeatureType } from "js/core/models/features";
import {
    ShowConfirmationDialog,
    ShowDialog,
    ShowErrorDialog,
    ShowWarningDialog
} from "js/react/components/Dialogs/BaseDialog";
import TeamSlideDialog from "js/react/views/TeamResources/dialogs/TeamSlideDialog";
import LockedSharedSlideDialog from "js/react/views/TeamResources/dialogs/LockedSharedSlideDialog";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import AppController from "js/core/AppController";

export async function handleEditSharedSlide() {
    if (isPPTAddin) {
        ShowWarningDialog({
            title: "Sorry, this Team Slide cannot be edited.",
            message: "Team Slides cannot be edited from PowerPoint."
        });
        return;
    }

    const currentSlide = PresentationEditorController.currentSlide;
    const workspaceId = AppController.workspaceId;
    const organizationId = AppController.orgId;
    const user = AppController.user;

    if (user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, workspaceId) && currentSlide.isLibrarySlideInCurrentUserOrg()) {
        if (await ShowConfirmationDialog({
            title: "This is a Shared Slide and cannot be edited from within a presentation. Would you like to open the Properties menu?",
            message: "Edits to Shared Slides are universal and can be made from the Properties menu or Team Resources.",
            okButtonLabel: "View slide properties"
        })) {
            ShowDialog(TeamSlideDialog, {
                libraryItemId: currentSlide.get("libraryItemId")
            });
        }
    } else if (currentSlide.isLibrarySlideInCurrentUserOrg()) {
        // if the user is a member and the team slide is in their org, let them know they need to contact an owner or librarian
        ShowDialog(LockedSharedSlideDialog, {
            organizationId
        });
    } else {
        // otherwise let them know they cant
        ShowErrorDialog({
            title: "Sorry, this Team Slide is not editable by you.",
            message: "This Team Slide is not in your workspace and you don't have permissions to edit it."
        });
    }
}
