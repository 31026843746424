import React, { Component } from "reactn";
import { Avatar, Button, Icon, IconButton } from "@material-ui/core";
import moment from "moment";
import styled from "styled-components";

import constants from "common/constants";
const { Notification: { EventType, EventAction }, SlideStatusProps } = constants;
import { app } from "js/namespaces";
import { Gap10 } from "js/react/components/Gap";
import { getStaticUrl } from "js/config";
import ProvideAccessDialog from "js/react/views/ProvideAccessDialog/ProvideAccessDialog";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import getLogger, { LogGroup } from "js/core/logger";
import AppController from "js/core/AppController";

const logger = getLogger(LogGroup.NOTIFICATION);

const logo = getStaticUrl("/images/beautifulai-logos/beautifulai-avatar.svg");

const NotificationContainer = styled.div`
    width: 100%;
    padding: 5px 10px 5px 20px;
    display: grid;
    grid-template-columns: 120px 300px 1fr 170px 45px;
    margin-bottom: 10px;
    background: white;
    color: #333;
    font-size: 14px;
    align-items: center;  
  
  
    .author {
      display: flex;
      align-items: center;
    }  
`;

export class Notification extends Component {
    markAsRead() {
        const { id } = this.props;

        AppController.notificationsService.markAsRead(id)
            .catch(err => logger.error(err, "[Notification] AppController.notificationsService.markAsRead() failed", { id }));
    }

    render() {
        const {
            createdAt,
            readAt,
            userPresentationId,
            event: {
                type,
                action,
                triggeredByUser,
                presentationName,
                isPresentationDeleted,
                presentationUrl,
                presentationPrivacy,
                slideIndex,
                slideStatus,
                permissionType,
                teamName,
                teamInviteUrl,
                isPresentationLinkDeleted,
                amount,
                senderIsBai,
                isTaggedInComment,
                isAssignedToSlide,
                presentationLinkName,
                username,
                exportType,
            }
        } = this.props;

        const getTextSpan = () => {
            let slideName = typeof slideIndex === "number"
                ? `slide #${slideIndex + 1}`
                : "a removed slide";
            const presentationText = isPresentationDeleted ? "a removed presentation" : presentationName;
            const permissionWord = permissionType === "write" ? "edit" : "view";
            const teamNameText = teamName ? (action === EventAction.ADDED_TO_TEAM ? `folder ${teamName}` : `${teamName} team`) : "a removed team";

            if (type === EventType.ASSIGN) {
                return <span>assigned you to <strong>{slideName} of {presentationText}</strong></span>;
            }

            // Adjusting slide name if user is assigned to it
            if (isAssignedToSlide && typeof slideIndex === "number") {
                slideName = "your slide";
            }

            if (type === EventType.COMMENT) {
                if (isTaggedInComment) {
                    return <span>tagged you in a comment on <strong>{slideName} of {presentationText}</strong></span>;
                } else {
                    return <span>commented on <strong>{slideName} of {presentationText}</strong></span>;
                }
            }

            if (type === EventType.EDIT) {
                if (action === EventAction.SLIDE_EDITED) {
                    if (userPresentationId) {
                        return <span>updated a team slide that affects <strong>{slideName} of {presentationText}</strong></span>;
                    } else {
                        return <span>edited <strong>{slideName} of {presentationText}</strong></span>;
                    }
                }
                if (action === EventAction.SLIDE_MOVED) {
                    return <span>moved <strong>{slideName} of {presentationText}</strong></span>;
                }
                if (action === EventAction.SLIDE_ADDED) {
                    return <span>added <strong>{slideName} to {presentationText}</strong></span>;
                }
                if (action === EventAction.SLIDE_REMOVED) {
                    return <span>removed <strong>a slide from {presentationText}</strong></span>;
                }
                if (action === EventAction.SLIDE_STATUS_CHANGED) {
                    return <span>changed the status of <strong>{slideName} of {presentationText}</strong> to <strong>{SlideStatusProps[slideStatus].label}</strong></span>;
                }
                if (action === EventAction.PRESENTATION_RENAMED) {
                    return <span>renamed <strong>{presentationText}</strong></span>;
                }
                if (action === EventAction.PRESENTATION_THEME_CHANGED) {
                    return <span>changed the theme of <strong>{presentationText}</strong></span>;
                }
                if (action === EventAction.PRESENTATION_PRIVACY_CHANGED) {
                    return <span>changed the privacy of <strong>{presentationText}</strong> to <strong>{presentationPrivacy}</strong></span>;
                }
            }

            if (type === EventType.PERMISSION) {
                if (action === EventAction.PERMISSION_GRANTED) {
                    return <span>invited you to {permissionWord} <strong>{presentationText}</strong></span>;
                }
                if (action === EventAction.PERMISSION_REQUESTED) {
                    return <span>requested to {permissionWord} <strong>{presentationText}</strong></span>;
                }
            }

            if (type === EventType.TEAM) {
                if (action === EventAction.INVITED_TO_TEAM) {
                    return <span>invited you to join <strong>{teamNameText}</strong></span>;
                }
                if (action === EventAction.ADDED_TO_TEAM) {
                    return <span>added you to <strong>{teamNameText}</strong></span>;
                }
                if (action === EventAction.ACCEPTED_INVITE_TO_TEAM) {
                    return <span>has joined your team, <strong>{teamNameText}</strong></span>;
                }
            }

            if (type === EventType.PRESENTATION_LINK) {
                if (action === EventAction.PRESENTATION_LINK_CREATED) {
                    if (isPresentationLinkDeleted) {
                        return <span>shared a removed link</span>;
                    } else {
                        return <span>shared a link to <strong>{presentationText}</strong></span>;
                    }
                }
            }

            if (type === EventType.REFERRAL) {
                if (action === EventAction.CONVERSION) {
                    return <span>You earned <strong>${amount / 100}</strong> in referral credit!</span>;
                }
            }

            if (type === EventType.PLAYER_DOWNLOAD) {
                if (action === EventAction.PRESENTATION_EXPORTED) {
                    const displayUsername = username !== "logged out" ? username : "Anonymous viewer";
                    return <span><strong>{displayUsername}</strong> downloaded a {exportType} file from <strong>{presentationName} ({presentationLinkName})</strong></span>;
                }
            }
        };

        const getActionButton = () => {
            if (type === EventType.PERMISSION && action === EventAction.PERMISSION_REQUESTED && !isPresentationDeleted) {
                return (<Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                        this.markAsRead();

                        ShowDialog(ProvideAccessDialog, {
                            notification: this.props
                        });
                    }}>
                    Edit access
                </Button>);
            } else if (type === EventType.COMMENT && presentationUrl && !isPresentationDeleted) {
                return (<Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                        var presentationWindow = window.open(presentationUrl);
                        presentationWindow.addEventListener("load", function() {
                            function clickShowComments() {
                                var showCommentsElement = presentationWindow.document.getElementById("show-comments");
                                if (showCommentsElement && showCommentsElement.textContent.trim().length > 0) {
                                    showCommentsElement.click();
                                } else {
                                    setTimeout(clickShowComments, 100);
                                }
                            }
                            setTimeout(clickShowComments, 1000);
                        });
                        this.markAsRead();
                    }}>
                    Open Comment
                </Button>);
            } else if (presentationUrl && !isPresentationDeleted) {
                return (<Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                        window.open(presentationUrl);
                        this.markAsRead();
                    }}>
                    Open Presentation
                </Button>);
            } else if (teamInviteUrl) {
                return (<Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                        window.open(teamInviteUrl);
                        this.markAsRead();
                    }}>
                    Join team
                </Button>);
            }
        };

        return (
            <NotificationContainer>
                <div className="timestamp">{moment(createdAt).fromNow()}</div>
                {senderIsBai ? (
                    <div className="author">
                        <Avatar
                            src={logo}
                            alt="Beautiful.ai"
                        />
                        <Gap10 />
                        Beautiful.ai
                    </div>
                ) : (
                    <div className="author">
                        <Avatar
                            src={triggeredByUser.photoURL}
                            alt={triggeredByUser.displayName}
                        >
                            {!triggeredByUser.photoURL && <Icon>person</Icon>}
                        </Avatar>
                        <Gap10 />
                        {triggeredByUser.displayName}
                        {app.user.id === triggeredByUser.uid && " (you)"}
                    </div>
                )}
                <div className="text">{getTextSpan()}</div>
                <div className="action">{getActionButton()}</div>
                {!readAt &&
                    <IconButton onClick={() => this.markAsRead()}><Icon>clear</Icon></IconButton>
                }
            </NotificationContainer>

        );
    }
}
