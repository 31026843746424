import React, { Component } from "react";
import styled from "styled-components";

import {
    Close as CloseIcon,
    Mic as MicIcon
} from "@material-ui/icons";
import { ThemeProvider } from "@material-ui/core/styles";

import { BlueButton } from "js/react/components/UiComponents";
import { Gap10, Gap20 } from "js/react/components/Gap";
import { AudioEditor } from "./Components/AudioEditor/AudioEditor";
import { BoxButton } from "js/react/views/AnimationPanel/Components/BoxButton";
import { PositionType, SlideRecordingType } from "common/constants";
import { ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import { themeColors } from "js/react/sharedStyles";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { dialogTheme } from "js/react/materialThemeOverrides";
import { PropertyPanelHeader } from "js/editor/ElementEditor/PropertyPanelHeader";
import { VideoOverlayPropertyPanel } from "./VideoOverlayPropertyPanel";
import { PropertyPanelContainer, PropertySection } from "../../../EditorComponents/PropertyPanel";
import { ToggleButton, ToggleButtons, VerticalToggleButton } from "js/Components/ToggleButtons";
import { Icon } from "js/Components/Icon";
// import { VideoOverlayPropertyPanel } from "./VideoOverlayPropertyPanel";

export const AnimationPanelTabType = {
    ANIMATION: "animation",
    AUDIO: "audio"
};

const Container = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    width: 100%;
    background: #f1f1f1;
`;

const ContentContainer = styled.div`
    width: 100%;
    height: calc(100% - 50px);
    padding: 10px;
    color: #333;
    position: relative;
`;

const ControlsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const PreviewButtonContainer = styled.div`
    width: 100%;
    padding: 10px;
`;

const PreviewButton = styled(BlueButton)`
    &&& {
        height: 40px;
        pointer-events: auto;
        border-radius: 0;
    }
`;

const Message = styled.div`
    font-size: 12px;
    color: ${themeColors.darkGray};
`;

class RecordPanel extends Component {
    constructor(props) {
        super(props);

        const canvas = this.props.currentCanvasController.canvas;

        let type = SlideRecordingType.NONE;
        if (this.hasAudio(canvas)) {
            type = SlideRecordingType.AUDIO;
        } else if (this.hasVideo(canvas)) {
            type = SlideRecordingType.VIDEO;
        }

        this.state = {
            slideRecordingType: type
        };
    }

    componentDidMount() {
        if (this.state.slideRecordingType == SlideRecordingType.VIDEO) {
            if (!this.props.selectedElements.includes(this.props.currentCanvasController.canvas.layouter.elements.videoOverlay)) {
                PresentationEditorController.selectionLayerController.setSelectedElements([this.props.currentCanvasController.canvas.layouter.elements.videoOverlay]);
            }
        }
    }

    hasAudio = canvas => {
        return canvas.dataModel.has("audioAsset");
    }

    hasVideo = canvas => {
        return canvas.layouter.model.elements.videoOverlay != null;
    }

    setSlideRecordingType = async type => {
        const canvas = this.props.currentCanvasController.canvas;

        switch (type) {
            case SlideRecordingType.NONE:
                if (this.hasAudio(canvas)) {
                    if (!(await ShowConfirmationDialog({
                        title: "Do you want to remove the audio track currently assigned to this slide?"
                    }))) {
                        return;
                    }
                }
                if (this.hasVideo(canvas)) {
                    if (!(await ShowConfirmationDialog({
                        title: "Do you want to remove the video bubble currently assigned to this slide?"
                    }))) {
                        return;
                    }
                }

                canvas.dataModel.update({
                    audioAsset: null
                });
                canvas.layouter.model.elements.videoOverlay = null;
                canvas.updateCanvasModel();

                this.setState({ slideRecordingType: SlideRecordingType.NONE });
                break;
            case SlideRecordingType.AUDIO:
                if (this.hasVideo(canvas)) {
                    if (!(await ShowConfirmationDialog({
                        title: "Do you want to remove the video bubble currently assigned to this slide?"
                    }))) {
                        return;
                    }
                }

                canvas.layouter.model.elements.videoOverlay = null;
                canvas.updateCanvasModel();

                this.setState({ slideRecordingType: SlideRecordingType.AUDIO });
                break;
            case SlideRecordingType.VIDEO:
                if (this.hasAudio(canvas)) {
                    if (await ShowConfirmationDialog({
                        title: "Do you want to remove the audio track currently assigned to this slide?"
                    })) {
                        canvas.dataModel.update({
                            audioAsset: null
                        });
                    } else {
                        return;
                    }
                }

                if (!this.hasVideo(canvas)) {
                    canvas.layouter.model.elements.videoOverlay = {
                        x: 0.015625,
                        y: 0.694444444444,
                        videoAssetId: "",
                        size: 200
                    };
                    await canvas.updateCanvasModel(false);
                    canvas.layouter.elements.videoOverlay?.setPosition(PositionType.BOTTOM_LEFT, false);
                }

                PresentationEditorController.selectionLayerController.setSelectedElements([canvas.layouter.elements.videoOverlay]);

                this.setState({ slideRecordingType: SlideRecordingType.VIDEO });
                break;
        }
    }

    render() {
        const { isReadOnly, canvasController, selectionLayerController } = this.props;
        const { slideRecordingType } = this.state;

        const canvas = canvasController.canvas;

        const recordingOptions = [{
            selected: slideRecordingType === SlideRecordingType.NONE,
            onClick: () => this.setSlideRecordingType(SlideRecordingType.NONE),
            IconType: CloseIcon,
            labelText: "None",
            width: "calc((100% - 10px) / 3)",
            containerStyle: { marginRight: "5px" }
        }, {
            selected: slideRecordingType === SlideRecordingType.AUDIO,
            onClick: () => this.setSlideRecordingType(SlideRecordingType.AUDIO),
            IconType: MicIcon,
            labelText: "Audio",
            width: "calc((100% - 10px) / 3)",
            containerStyle: { marginRight: "5px" }
        }, {
            selected: slideRecordingType === SlideRecordingType.VIDEO,
            onClick: () => this.setSlideRecordingType(SlideRecordingType.VIDEO),
            // There is no 'video_camera_front' icon within material ui
            icon: "video_camera_front",
            labelText: "Video",
            width: "calc((100% - 10px) / 3)"
        }];

        return (
            // <ThemeProvider theme={dialogTheme}>
            <PropertyPanelContainer>
                <PropertyPanelHeader>
                    Slide Narration
                </PropertyPanelHeader>
                <PropertySection>
                    <ToggleButtons value={slideRecordingType}
                        onChange={this.setSlideRecordingType}
                    >
                        <VerticalToggleButton value={SlideRecordingType.NONE}>
                            <Icon>close</Icon>
                            None
                        </VerticalToggleButton>
                        <VerticalToggleButton value={SlideRecordingType.AUDIO}>
                            <Icon>mic</Icon>
                            Audio
                        </VerticalToggleButton>
                        <VerticalToggleButton value={SlideRecordingType.VIDEO}>
                            <Icon>video_camera_front</Icon>
                            Video
                        </VerticalToggleButton>
                    </ToggleButtons>
                </PropertySection>
                {/*<ControlsContainer>*/}
                {/*    {recordingOptions*/}
                {/*        .map((option, index) => (<BoxButton*/}
                {/*            key={index} {...option} />))}*/}
                {/*</ControlsContainer>*/}
                {/*<Gap20 />*/}

                {slideRecordingType === SlideRecordingType.AUDIO &&
                    <PropertySection>
                        <AudioEditor
                            canvas={canvas}
                            isReadOnly={isReadOnly}
                        />
                    </PropertySection>
                }

                {slideRecordingType === SlideRecordingType.VIDEO &&
                    <VideoOverlayPropertyPanel element={canvas.layouter.elements.videoOverlay} />
                }
            </PropertyPanelContainer>
            // </ThemeProvider>
        );
    }
}

export default RecordPanel;
