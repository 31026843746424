import React, { Component, useEffect } from "react";
import { DragPreviewImage, useDrag } from "react-dnd";

import { PropertySectionNote } from "../../EditorComponents/PropertyPanel";
import PresentationEditorController from "../PresentationEditor/PresentationEditorController";
import { AuthoringElementType, CalloutType, CALLOUTS_MAPPING } from "../../../common/constants";
import { addCalloutToContainer } from "../PresentationEditor/Components/AddElementDropZone";
import * as geom from "../../core/utilities/geom";
import { ImageOption, ImageOptionList } from "../../Components/ImageOptionList";
import { StaticImage } from "../../Components/StaticImage";

export const CALLOUTS = [
    CALLOUTS_MAPPING[CalloutType.BOX],
    CALLOUTS_MAPPING[CalloutType.CIRCLE],
    CALLOUTS_MAPPING[CalloutType.FLEX_CIRCLE],
    CALLOUTS_MAPPING[CalloutType.DIAMOND],
    CALLOUTS_MAPPING[CalloutType.CAPSULE],
    CALLOUTS_MAPPING[CalloutType.TEXT],
    CALLOUTS_MAPPING[CalloutType.CONTENT_AND_TEXT],
    CALLOUTS_MAPPING[CalloutType.TIMELINE_TEXT],
    CALLOUTS_MAPPING[CalloutType.BULLET_TEXT],
    CALLOUTS_MAPPING[CalloutType.NUMBERED_TEXT],
    CALLOUTS_MAPPING[CalloutType.LETTERED_TEXT],
    CALLOUTS_MAPPING[CalloutType.CONTENT],
    CALLOUTS_MAPPING[CalloutType.LINE_ARROW]
];

export const DraggableCallout = ({ label, value, image, currentCanvas, onClose, containerElement }) => {
    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: "elements",
        item: { label, value, onClose },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId()
        }),
    }));

    useEffect(() => {
        PresentationEditorController.setShowAddElementDropZone(isDragging);
    }, [isDragging]);

    return (
        <>
            <DragPreviewImage connect={preview} />
            <div ref={drag} onMouseUp={() => {
                const { width, height } = containerElement.getDefaultModel(AuthoringElementType.CALLOUT, { calloutType: value });
                addCalloutToContainer(containerElement, value, new geom.Point((currentCanvas.CANVAS_WIDTH / 2) - (width / 2), (currentCanvas.CANVAS_HEIGHT / 2) - (height / 2)));
                onClose && onClose();
            }}>
                <StaticImage src={image} />
            </div>
        </>
    );
};

export class CalloutsPane extends Component {
    render() {
        const { currentCanvasController, selectedElements, setIsDragging, onClose, showMore } = this.props;

        const canvas = currentCanvasController.canvas;
        const template = canvas.slideTemplate;
        const callouts = canvas.getCalloutsElement();

        // // If a single callout is selected, show the callout properties
        // if (selectedElements.length == 1 && selectedElements[0].findClosestOfType("CalloutsCanvas")) {
        //     return <SelectedElementPane {...this.props} />;
        // }

        // If the slide does not support callouts, show a note
        if (!callouts || !template.allowCallouts) {
            return (
                <PropertySectionNote>
                    This slide does not support callouts.
                </PropertySectionNote>
            );
        }

        let availableCalloutTypes;
        if (showMore) {
            availableCalloutTypes = [CalloutType.TEXT, CalloutType.BOX, CalloutType.BULLET_TEXT, CalloutType.CONTENT_AND_TEXT, CalloutType.CIRCLE, CalloutType.NUMBERED_TEXT, CalloutType.CONTENT, CalloutType.LINE_ARROW];
        } else {
            availableCalloutTypes = [CalloutType.TEXT, CalloutType.BOX, CalloutType.BULLET_TEXT, CalloutType.CONTENT_AND_TEXT];
        }

        availableCalloutTypes = availableCalloutTypes.map(type => CALLOUTS.find(callout => callout.value === type));
        return (
            <ImageOptionList cols={4}>
                {availableCalloutTypes.map(callout => (
                    <ImageOption value={callout.value} key={callout.label} label={callout.label} background="none" labelGap={0}>
                        <DraggableCallout
                            {...callout}
                            containerElement={callouts}
                            currentCanvas={canvas}
                            key={callout.value}
                            setIsDragging={setIsDragging}
                            onClose={onClose}
                        />
                    </ImageOption>
                ))}
            </ImageOptionList>
        );
    }
}

