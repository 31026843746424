import React, { Component } from "react";
import styled from "styled-components";

import Api from "js/core/api";
import { app } from "js/namespaces";
import AppController from "js/core/AppController";
import { FeatureType } from "common/features";
import { trackActivity } from "js/core/utilities/utilities";
import { UIPane, UIPaneHeader, UIPaneContents, TextButton } from "js/react/components/UiComponents";
import { ShowConfirmationDialog, ShowErrorDialog, ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import isOrganizationSsoEnabled from "common/utils/isOrganizationSsoEnabled";
import Loadable from "js/react/components/Loadable";
import { themeColors } from "js/react/sharedStyles";
import getLogger from "js/core/logger";

import { SSOConfigDialog } from "./SSOConfigPane";
import { SalesforceIntegrationDialog } from "./SalesforceIntegration";

const logger = getLogger();

const SectionCard = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 30px;

    .section-label {
        .section-title {
            font-size: 20px;
            font-weight: 600;
        }
        .section-description {
            font-size: 14px;
            color: #666;
        }
    }
`;

export default class IntegrationsPane extends Component {
    state = {
        isLoading: true,
        isSalesforceConnected: false,
        isSsoEnabled: false,
        isStrictSSOEnabled: false,
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try {
            this.setState({ isLoading: true });
            const { organizationId } = this.props;

            const salesforce = await Api.salesforceToken.get({ orgId: organizationId });
            const organization = await Api.sso.get({ id: organizationId });

            this.setState({
                isSalesforceConnected: salesforce.hasTokens,
                isSsoEnabled: isOrganizationSsoEnabled(organization),
                isStrictSSOEnabled: isOrganizationSsoEnabled(organization) && organization.sso.strict
            });
        } catch (err) {
            logger.error(err, "[IntegrationsPane] loadData() failed");

            ShowErrorDialog({
                error: "Error",
                message: "Failed to load your data. Please email support@beautiful.ai for assistance."
            });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    handleSSOClick = () => {
        ShowDialog(SSOConfigDialog, {
            organizationId: this.props.organizationId,
            onDisconnect: () => {
                this.setState({ isSsoEnabled: false, isStrictSSOEnabled: false });
            },
            onConnect: () => this.loadData()
        });
    }

    handleSalesforceClick = () => {
        const { isSalesforceConnected } = this.state;

        if (!isSalesforceConnected) {
            ShowDialog(SalesforceIntegrationDialog, {
                organizationId: this.props.organizationId,
                onConnect: () => {
                    this.setState({ isSalesforceConnected: true });
                }
            });
        } else {
            ShowConfirmationDialog({
                title: "Disconnect Salesforce",
                message: "Are you sure you want to disconnect Salesforce?",
                okButtonLabel: "Disconnect",
                acceptCallback: async () => {
                    try {
                        await Api.salesforceToken.delete({ orgId: this.props.organizationId });

                        trackActivity("SalesforceIntegration", "Disable", null, null, { orgId: this.props.organizationId });

                        logger.info("Salesforce tokens deleted");
                        this.setState({ isSalesforceConnected: false });
                    } catch (err) {
                        logger.error(err, "handleDisconnectSalesforce() failed");
                        ShowErrorDialog({ title: "Failed to disconnect Salesforce", message: err.message });
                    }
                }
            });
        }
    }

    isPaneAvailable = pane => {
        if (pane === "salesforce") {
            const hasSalesforceIntegration = app.user.features.isFeatureEnabled(FeatureType.SALESFORCE_INTEGRATION, this.props.organizationId);
            const { prohibitSalesforceIntegration } = AppController.currentTeam.get("workspaceSettings") || {};
            return hasSalesforceIntegration && !prohibitSalesforceIntegration;
        }
        return true;
    };

    render() {
        const { isLoading, isSalesforceConnected, isSsoEnabled, isStrictSSOEnabled } = this.state;

        return (
            <Loadable isLoading={isLoading}>
                <UIPane>
                    <UIPaneHeader>Integrations</UIPaneHeader>
                    <UIPaneContents>
                        {
                            this.isPaneAvailable("sso") && (
                                <SectionCard>
                                    <div className="section-label">
                                        <div className="section-title">Single Sign-On</div>
                                        <div className="section-description">
                                            {isSsoEnabled
                                                ? `SSO is enabled and ${isStrictSSOEnabled ? `required` : `optional`} for your organization.`
                                                : "SSO is not yet enabled for your organization."}
                                        </div>
                                    </div>
                                    <TextButton color="primary" onClick={this.handleSSOClick}>
                                        {isSsoEnabled ? "Edit Configuration" : "Enable SSO"}
                                    </TextButton>
                                </SectionCard>
                            )
                        }

                        {
                            this.isPaneAvailable("salesforce") && (
                                <SectionCard>
                                    <div className="section-label">
                                        <div className="section-title">Salesforce</div>
                                        <div className="section-description">
                                            {isSalesforceConnected
                                                ? "Salesforce is enabled for your organization"
                                                : "Salesforce is not yet enabled for your organization"}
                                        </div>
                                    </div>
                                    <TextButton
                                        style={{
                                            color: isSalesforceConnected ? themeColors.warning : themeColors.ui_blue,
                                        }}
                                        onClick={this.handleSalesforceClick}
                                    >
                                        {isSalesforceConnected
                                            ? "Disconnect Salesforce"
                                            : "Enable Salesforce"}
                                    </TextButton>
                                </SectionCard>
                            )
                        }
                    </UIPaneContents>
                </UIPane>
            </Loadable>
        );
    }
}
