import { GlobalStateController } from "bai-react-global-state";
import { ds } from "js/core/models/dataService";
import { AssetType } from "common/constants";
import AppController from "js/core/AppController";
import { builtInFonts } from "common/fontConstants";
import { fontManager } from "js/core/services/fonts";
import { _, tinycolor } from "js/vendor";
import { app } from "js/namespaces";

const initialState = {
    activeThemeEditorPanel: null,
    themeOrPresentationId: null,
    editedTheme: null
};

class ThemeEditorController extends GlobalStateController {
    constructor(initialState) {
        super(initialState);
    }

    get activeThemeEditorPanel() {
        return this._state.activeThemeEditorPanel;
    }

    get themeOrPresentationId() {
        return this._state.themeOrPresentationId;
    }

    get editedTheme() {
        return this._state.editedTheme;
    }

    loadTheme(theme) {

    }

    getColor(color) {
        let paletteColor = null;
        if ((color instanceof tinycolor) && color.isValid()) {
            paletteColor = color.clone();
        } else if (this._state.editedTheme?.colors) {
            paletteColor = tinycolor(this._state.editedTheme?.colors[color]);
        } else if (app.currentTheme?.palette) {
            paletteColor = app.currentTheme.palette.getColor(color);
        }

        if ((paletteColor instanceof tinycolor) && paletteColor.isValid()) {
            return paletteColor;
        } else {
            return tinycolor(color);
        }
    }

    // Returns a subset of all palette colors for use in gradient editor
    gradientPaletteColors() {
        let themeColors = this._state.editedTheme.colors;
        let paletteColors = {};
        for (let colorName of Object.keys(themeColors)) {
            if (colorName.startsWith("accent") ||
                colorName.startsWith("background") ||
                colorName.startsWith("theme")) {
                paletteColors[colorName] = themeColors[colorName];
            }
        }
        return paletteColors;
    }

    setThemeOrPresentationId(value) {
        return this._updateState({
            themeOrPresentationId: value,
        });
    }

    setActiveThemeEditorPanel(value) {
        return this._updateState({
            activeThemeEditorPanel: value,
        });
    }

    setEditedTheme(value) {
        return this._updateState({
            editedTheme: value,
        });
    }

    async loadFonts(theme) {
        const fontAssets = await ds.assets.getAssetsByType(AssetType.FONT, AppController.orgId);
        const userFontIds = fontAssets.map(({ id }) => id);
        const builtInFontIds = Object.keys(builtInFonts);

        let themeFontIds = [];
        if (theme) {
            themeFontIds = Object.entries(theme)
                .filter(([key, value]) => !!value && key.endsWith("FontId"))
                .map(([key, value]) => value);
        }

        // Combine all font ids
        const fontIds = [...new Set([...userFontIds, ...builtInFontIds, ...themeFontIds])];
        // Load all fonts
        const fonts = await Promise.all(fontIds.map(fontId => fontManager.loadFont(fontId)));

        this._updateState({ fonts });
    }
}

const themeEditorController = new ThemeEditorController(_.cloneDeep(initialState));
export default themeEditorController;
