import React, { Component } from "react";
import styled from "styled-components";
import { Button, DialogActions } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

import { ds } from "js/core/models/dataService";
import { TeamResourceTypes } from "js/react/views/TeamResources/TeamResources";
import {
    BeautifulDialog,
    DialogContent,
    ShowConfirmationDialog,
    ShowErrorDialog,
    ShowDialogAsync
} from "js/react/components/Dialogs/BaseDialog";
import { CreateSharedSlideDialog } from "js/react/views/UserOptions/dialogs/CreateSharedSlideDialog";
import { AddSlideContainer } from "js/react/views/AddSlide";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import PresentationEditor from "js/editor/PresentationEditor/PresentationEditor";
import { Presentation } from "js/core/models/presentation";
import { Slide } from "js/core/models/slide";
import { dialogTheme } from "js/react/materialThemeOverrides";
import Thumbnails from "js/core/models/thumbnails";
import { trackActivity } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import AppController from "js/core/AppController";
import PresentationEditorController, { PanelType } from "js/editor/PresentationEditor/PresentationEditorController";

const EditorContainer = styled.div`
    width: 100%;
    height: 100%;
    background: #4b4e55;
    position: relative;
    border: solid 1px #ccc;
`;

const Header = styled.div`
    font-size: 20px;
    padding: 20px 40px;
    font-weight: bold;
`;

class SharedSlideEditor extends Component {
    get resourceName() {
        switch (this.state.resourceType) {
            case TeamResourceTypes.SHARED_SLIDES:
                return "Shared Slide";
            case TeamResourceTypes.SLIDE_TEMPLATES:
                return "Slide Template";
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            resourceType: props.resourceType ?? TeamResourceTypes.SHARED_SLIDES,
            isAddingSlide: false,
            showEditor: false
        };
    }

    get isEditingExistingSlide() {
        return !!this.props.slideId;
    }

    async componentDidMount() {
        const { slideId } = this.props;

        const defaultTeam = ds.teams.defaultTeamForOrg(AppController.orgId);
        const teamTheme = ds.sharedThemes.findWhere({ id: defaultTeam.get("sharedThemeId") });

        this.dummyPresentation = new Presentation({
            name: "Team Slide",
            userId: app.user.id,
            orgId: AppController.orgId,
            themeId: teamTheme.id,
            sharedThemeId: teamTheme.id,
            isDummy: true
        }, { disconnected: true, userId: app.user.id });
        this.dummyPresentation.permissions.write = true;
        this.dummyPresentation.permissions.read = true;

        if (this.isEditingExistingSlide) {
            const libraryItem = defaultTeam.libraryItems.findWhere({ contentId: slideId });

            if (!libraryItem) {
                return ShowErrorDialog({ title: "Slide Not Found", message: "The slide you are trying to edit could not be found." });
            }

            const resourceType = libraryItem.get("isTemplate") ? TeamResourceTypes.SLIDE_TEMPLATES : TeamResourceTypes.SHARED_SLIDES;
            this.setState({ resourceType });

            const slide = new Slide({ id: slideId }, {
                userId: app.user.id,
                presentation: this.dummyPresentation
            });
            await slide.load();

            // detach adapter so changes are not saved automatcally until we call slide.saveChanges()
            slide.detachAdapter();

            await this.dummyPresentation.batchShareSlides([slide], { insertIndex: 0, skipUndo: true });
        }

        ds.selection.presentation = this.dummyPresentation;

        this.setState({ presentationId: this.dummyPresentation.id });
        await PresentationEditorController.setPresentation(this.dummyPresentation);

        if (!this.isEditingExistingSlide) {
            this.setState({ isAddingSlide: true });
            return;
        }

        this.setState({ isLoading: false, showEditor: true });
    }

    exit() {
        const { onExit } = this.props;

        // Ensure we reset the editor state before exiting
        PresentationEditorController.reset()
            .then(() => onExit());
    }

    handleCancelChanges = () => {
        this.exit();
    }

    handleSaveChanges = async () => {
        const { resourceType } = this.state;

        PresentationEditorController.selectionLayerController.setSelectedElements([]);

        const slide = this.dummyPresentation.slides.models[0];

        // Force-set team template flag
        if (resourceType === TeamResourceTypes.SLIDE_TEMPLATES) {
            slide.set("isTeamSlideTemplate", true);
        }

        if (this.isEditingExistingSlide) {
            ShowConfirmationDialog({
                title: `Are you sure you want to save changes to this ${this.resourceName}?`,
                message: resourceType === TeamResourceTypes.SHARED_SLIDES
                    ? "Changes will be applied to all presentations that use this slide."
                    : undefined,
                acceptCallback: () => {
                    slide.saveChanges()
                        .then(() => slide.finishedEditing())
                        .then(() => {
                            Thumbnails.getSignedUrlAndLoad(slide.id, slide.get("modifiedAt"));

                            const props = {
                                slide_id: slide.id,
                                template_name: slide.get("template_id"),
                            };
                            trackActivity("SharedSlideLibrary", "SlideContentEdited", null, null, props, { audit: true });

                            this.exit();
                        });
                }
            });

            return;
        }

        await ShowDialogAsync(CreateSharedSlideDialog, {
            slide,
            presentation: null,
            resourceType
        });

        this.exit();
    }

    handleAddSlideContainerClose = isSlideAdded => {
        if (!isSlideAdded) {
            this.exit();
            return;
        }

        // detach adapter so changes are not saved automatcally until we call slide.saveChanges()
        this.dummyPresentation.slides.models[0].detachAdapter();

        this.setState({ isAddingSlide: false, isLoading: false, showEditor: true });
    }

    render() {
        const { isLoading, isAddingSlide, showEditor } = this.state;

        return (
            <ThemeProvider theme={dialogTheme}>
                {isAddingSlide && <AddSlideContainer forTeamSlide={true} closeDialog={this.handleAddSlideContainerClose} />}
                {!isAddingSlide &&
                    <BeautifulDialog fullScreen closeDialog={this.handleCancelChanges}>
                        <Header>You are {this.isEditingExistingSlide ? "editing" : "creating"} a {this.resourceName}</Header>
                        <DialogContent style={{ overflow: "hidden", padding: "0px 34px" }}>
                            <EditorContainer>
                                <FetchingClickShield visible={isLoading} backgroundColor="#4b4d55" />
                                {showEditor &&
                                    <PresentationEditor
                                        dummyPresentation={this.dummyPresentation}
                                        presentationId={this.dummyPresentation.id}
                                        isSingleSlideEditor={true}
                                        hidePanels={[PanelType.MORE_ACTIONS]}
                                        allowThemeChange={false}
                                        allowSharedSlideEditing={true}
                                        allowStyleChange={false}
                                    />
                                }
                            </EditorContainer>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCancelChanges}>Cancel</Button>
                            <Button onClick={this.handleSaveChanges} color="primary">Save Changes</Button>
                        </DialogActions>
                    </BeautifulDialog>
                }
            </ThemeProvider>
        );
    }
}

export default AppController.withState(PresentationEditorController.withState(SharedSlideEditor));
