import React, { useEffect, forwardRef } from "react";
import styled from "styled-components";

import { DialogTitle, LinearProgress } from "@material-ui/core";

import DialogContent from "js/react/components/Dialogs/DialogContent";
import BeautifulDialog from "js/react/components/Dialogs/BeautifulDialog";

const DialogMessage = styled.div`
    margin-top: 20px;
`;

function SavingAudioDialog({ uploadFileAndCreateTaskPromise, closeDialog }, forwardedRef) {
    useEffect(() => {
        uploadFileAndCreateTaskPromise
            .finally(() => closeDialog());
    }, [0]);

    return (
        <BeautifulDialog
            closeDialog={() => closeDialog(false)}
            preventClose
            ref={forwardedRef}
        >
            <DialogTitle>
                Saving audio
            </DialogTitle>
            <DialogContent>
                <LinearProgress />
                <DialogMessage>Your audio is being saved and added to the slide...</DialogMessage>
            </DialogContent>
        </BeautifulDialog >
    );
}

export default forwardRef(SavingAudioDialog);
