import React, { Component } from "react";
import { Button, DialogActions, DialogTitle } from "@material-ui/core";

import { FeatureType } from "common/features";
import AppController from "js/core/AppController";
import { trackActivity } from "js/core/utilities/utilities";
import ThemeEditorController from "js/editor/ThemeEditor/ThemeEditorController";
import FontList from "js/editor/ThemeEditor/components/Font/FontList";
import { UploadFontModal } from "js/editor/ThemeEditor/components/Font/UploadFontModal";
import { app } from "js/namespaces";
import {
    BeautifulDialog,
    DialogContent,
    ShowDialog,
    ShowUpgradeDialog
} from "js/react/components/Dialogs/BaseDialog";
import { Gap10, Gap20 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import { SimpleLabel, WarningLabel } from "js/react/components/SimpleLabel";
import { BlueButton } from "js/react/components/UiComponents";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";

class ImportFontsDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false
        };
    }

    componentDidMount() {
        const { fonts, headerFont, bodyFont, headerFontWeight, bodyFontWeight } = this.props;

        let fontHeader = fonts.find(font => headerFont.startsWith(font.label));
        let fontBody = fonts.find(font => bodyFont.startsWith(font.label));

        this.setState({
            isLoaded: true,
            headerFontId: fontHeader?.id ?? "carlito",
            headerFontWeight,
            bodyFontId: fontBody?.id ?? "carlito",
            bodyFontWeight,
            missingHeaderFont: fontHeader == null ? headerFont : null,
            missingBodyFont: fontBody == null ? bodyFont : null,
        });
    }

    handleChangeFont = (style, fontId, weight) => {
        this.setState({
            [style + "FontId"]: fontId,
            [style + "FontWeight"]: weight
        });
    }

    handleUploadFont = style => {
        if (!app.user.features.isFeatureEnabled(FeatureType.CUSTOM_FONTS, AppController.workspaceId)) {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                workspaceId: AppController.workspaceId,
                analytics: { cta: "CustomFont" },
            });
            return;
        }

        trackActivity("CustomFonts", "ShowUploadDialog", null, null, {
            workspaceId: AppController.workspaceId
        });

        ShowDialog(UploadFontModal, {
            onSetCustomFont: async (fontAssetId, baseWeight) => {
                await ThemeEditorController.loadFonts();
                this.handleChangeFont(style, fontAssetId, baseWeight);
            },
        });
    };

    handleSaveChanges = () => {
        const { closeDialog } = this.props;
        const { headerFontId, headerFontWeight, bodyFontId, bodyFontWeight } = this.state;

        closeDialog({
            headerFont: {
                fontId: headerFontId,
                weight: headerFontWeight
            },
            bodyFont: {
                fontId: bodyFontId,
                weight: bodyFontWeight
            }
        });
    }

    render() {
        const { isLoaded, headerFontId, headerFontWeight, bodyFontId, bodyFontWeight, missingHeaderFont, missingBodyFont } = this.state;

        if (!isLoaded) {
            return null;
        }

        return (
            <BeautifulDialog maxWidth="md">
                <DialogTitle>
                    Import Fonts
                </DialogTitle>
                <DialogContent>
                    <p>Some of the fonts used in the imported theme are not installed in your workspace.</p>

                    <Gap20 />
                    <SimpleLabel>Header Font</SimpleLabel>
                    {missingHeaderFont &&
                        <WarningLabel>The font named {missingHeaderFont} is not available. You can either upload
                            this font or choose an alternative font.</WarningLabel>
                    }
                    <FlexBox left middle>
                        <FontList
                            label="Header"
                            style="Header"
                            selectedFontId={headerFontId}
                            selectedFontWeight={headerFontWeight}
                            onSelectFont={(fontId, weight) => this.handleChangeFont("header", fontId, weight)}
                        />
                        <Gap10 />
                        <Button onClick={() => this.handleUploadFont("header")}>Upload Font</Button>
                    </FlexBox>
                    <Gap20 />
                    <SimpleLabel>Body Font</SimpleLabel>
                    {missingBodyFont &&
                        <WarningLabel>The font named {missingBodyFont} is not available. You can either upload
                            this font or choose an alternative font.</WarningLabel>
                    }
                    <FlexBox left middle>
                        <FontList
                            label="Body"
                            style="Body"
                            selectedFontId={bodyFontId}
                            selectedFontWeight={bodyFontWeight}
                            onSelectFont={(fontId, weight) => this.handleChangeFont("body", fontId, weight)}
                        />
                        <Gap10 />
                        <Button onClick={() => this.handleUploadFont("body")}>Upload Font</Button>
                    </FlexBox>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog}>Cancel Import</Button>
                    <BlueButton onClick={this.handleSaveChanges}>Continue</BlueButton>
                </DialogActions>

            </BeautifulDialog>
        );
    }
}

export default ThemeEditorController.withState(ImportFontsDialog);

