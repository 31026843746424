import React, { Component } from "react";
import styled from "styled-components";
import { ColorPicker } from "js/Components/ColorPicker";
import ThemeEditorController from "js/editor/ThemeEditor/ThemeEditorController";

const FourCornerGradientContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
`;

export class FourCornerGradientEditor extends Component {
    handleChange = (index, color) => {
        const newStops = [...this.props.stops];
        newStops[index].color = ThemeEditorController.getColor(color);
        this.props.onChange(newStops);
    }

    render() {
        const { stops } = this.props;
        const gradientPaletteColors = ThemeEditorController.gradientPaletteColors();

        return (<FourCornerGradientContainer>
            <ColorPicker value={stops[0]?.color}
                onChange={color => this.handleChange(0, color)}
                customPaletteColors ={gradientPaletteColors}
                showColorPicker
                disableAlpha
            />
            <ColorPicker value={stops[1]?.color}
                onChange={color => this.handleChange(1, color)}
                customPaletteColors ={gradientPaletteColors}
                showColorPicker
                disableAlpha
            />
            <ColorPicker value={stops[2]?.color}
                onChange={color => this.handleChange(2, color)}
                customPaletteColors ={gradientPaletteColors}
                showColorPicker
                disableAlpha
            />
            <ColorPicker value={stops[3]?.color}
                onChange={color => this.handleChange(3, color)}
                customPaletteColors ={gradientPaletteColors}
                showColorPicker
                disableAlpha
            />
        </FourCornerGradientContainer>);
    }
}
