import React from "react";
import styled, { css } from "styled-components";
import { Icon } from "@material-ui/core";
import { themeColors } from "js/react/sharedStyles";

const BoxButtonContainer = styled.div.attrs(({
    selected,
    disabled,
    backgroundColor,
    selectedBackgroundColor,
    width,
    height
}) => ({
    style: {
        background: selected ? selectedBackgroundColor : backgroundColor,
        border: selected ? `1px solid ${themeColors.smartSlideThemeBaseColor}` : "none",
        paddingTop: selected ? "4px" : "5px",
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.6 : 1,
        width,
        height
    }
}))`
    min-width: 48px;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 5px;

    cursor: pointer;
    color: ${themeColors.darkGray};

    >label {
        margin-top: 5px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
        cursor: pointer;
        white-space: nowrap;
    }
`;

const StyledArt = css`
    width: 20px;
    height: 20px;
`;

const IconContainer = styled.div.attrs(({ selected }) => ({
    style: {
        color: selected
            ? themeColors.smartSlideThemeBaseColor : themeColors.lighterGray
    }
}))`
    ${StyledArt};
    cursor: pointer;
`;

const StyledIcon = styled(Icon).attrs(({ selected }) => ({
    style: {
        color: selected
            ? themeColors.smartSlideThemeBaseColor : themeColors.lighterGray
    }
}))`
    ${StyledArt};
    cursor: pointer;
`;

const StyledImg = styled.img`
    ${StyledArt};
`;

export function BoxButton({
    selected = false,
    disabled = false,
    width = "fit-content",
    height = "48px",
    onClick,
    labelText,
    CustomControl = null,
    IconType = null,
    imageUrl = null,
    icon = null,
    containerStyle = {},
    backgroundColor = "#ffffff",
    selectedBackgroundColor = "#dbe9f0"
}) {
    return (
        <BoxButtonContainer
            selected={selected}
            disabled={disabled}
            onClick={onClick}
            width={width}
            height={height}
            backgroundColor={backgroundColor}
            selectedBackgroundColor={selectedBackgroundColor}
            style={containerStyle}
        >
            {CustomControl && CustomControl}
            {IconType && <IconContainer selected={selected}><IconType style={{ fontSize: 20 }} /></IconContainer>}
            {imageUrl && <StyledImg src={imageUrl}></StyledImg>}
            {icon && <StyledIcon selected={selected}>{icon}</StyledIcon>}
            <label>{labelText}</label>
        </BoxButtonContainer>
    );
}
