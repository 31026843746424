import React, { Component } from "react";
import styled from "styled-components";

import getLogger, { LogGroup } from "js/core/logger";
import { themeColors } from "js/react/sharedStyles";
import { FlexBox } from "js/react/components/LayoutGrid";
import { Box } from "js/Components/Box";
import { Popup, PopupPreview, PopupContent } from "js/Components/Popup";
import { MenuBarIconButton } from "./PresentationMenuBar";
import PresentationEditorController from "../PresentationEditorController";
import AssignSlide from "./AssignSlide";
import AssignSlidePane from "js/react/views/AssignSlide/AssignSlidePane";

const PopupContentContainer = styled(Box)`
    width: 300px;
    background: #F9F9F9;
    font-family: Source Sans Pro;
    .MuiAvatar-root {
        width: 30px;
        height: 30px;
    }
    .MuiOutlinedInput-root {
        width: 100%;
        border-radius: 2px;
        height: 32px;
        background: #FFFFFF;
        .MuiInputBase-input {
            margin-left: 5px;
            height: 32px;
            padding: 0;
        }
        input::placeholder {
            font-weight: 400;
            color: #999999;
            margin-bottom: 5px;
        }
    }
    
    .label-text {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        color: #555555;
        line-height: 13px;
    }
    .remove-assigned-user {
        cursor: pointer;
        
    }
    .slide-status-option {
        cursor: pointer;     
    }    
    .slide-status-icon {
        width: 34px;
        height: 34px;
        padding: 5px;
        background: #FFFFFF;
    }
    .slide-status-icon-selected {
        border: 1px solid ${themeColors.ui_blue};
    }
    .slide-status-label {
        font-size: 9px;
        font-weight: 600;
        text-transform: uppercase;
        text-wrap: nowrap;
        color: #555555;
    }
    .slide-status-label-selected {
        color: ${themeColors.ui_blue};
    }
`;

class CollaborationActions extends Component {
    render() {
        return (
            <FlexBox vertical gap={20}>
                <Popup>
                    <PopupPreview>
                        <MenuBarIconButton onClick={this.handleToggleAssignedUser}>
                            <AssignSlide />
                        </MenuBarIconButton>
                    </PopupPreview>
                    <PopupContent>
                        <PopupContentContainer>
                            <AssignSlidePane />
                        </PopupContentContainer>
                    </PopupContent>
                </Popup>
            </FlexBox>
        );
    }
}

export default PresentationEditorController.withState(CollaborationActions);
