import { ShakingAnimaiton } from "js/react/components/animation";
import { _ } from "js/vendor";
import React, { Component, createRef } from "react";
import styled, { css } from "styled-components";

const StyledIcon = styled.i.attrs(({ hideIcon, disabled }) => ({
    style: {
        opacity: disabled ? 0.5 : (hideIcon ? 0 : 1)
    }
}))``;

const CicleSvg = styled.circle.attrs(({ hideIcon, holdTime = 500 })=>({ style: {
    strokeDashoffset: hideIcon ? 0 : "",
    transition: hideIcon ? `stroke-dashoffset ${holdTime}ms linear` : "none"
} }))``;

class CircleSvg extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { cx, cy, r, className, hideIcon, holdTime } = this.props;

        return (
            <svg>
                <CicleSvg
                    hideIcon={hideIcon}
                    holdTime={holdTime}
                    cx={cx} cy={cy} r={r}
                    className={className}/>
            </svg>
        );
    }
}

const HoldButtonStyle = styled.div`
    position: absolute;
    top: calc(100% - 50px);
    width: 50px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 10px 0px;
    color: #ddd;
    transition: 250ms;
    text-align: center;

    ${props => props.disabled ? css`
        pointer-events: none !important;
    ` : null}


        label {
            font-weight: 500;
            margin: 0 -10px;
            cursor: pointer;
            white-space: initial;
            font-size: 10px;
            text-transform: uppercase;
            display: block;
            color: #ddd;

            ${props => props.disabled ? css`opacity: 0.5 !important;` : null}
        }
        &:hover {
            //color: $selectionColor;
            color: #11a9e2;
        }

        svg {
            position: absolute;
            top: 8px;
            left: calc(50% - 15px);
            width: 30px;
            height: 30px;
             color: $selectionColor;
        }
        .path {
            stroke: #fc4706;
            fill: none;
            stroke-width: 5px;
            stroke-dasharray: 62.83 62.83;
            stroke-dashoffset: 62.83;
            transform: rotate(-90deg);
            transform-origin: center;
        }
`;
export default class HoldButton extends Component {
  state = {
      isWarning: false,
      isHover: false,
      hideIcon: false
  }
  constructor(props) {
      super(props);

      this.$control = createRef();
      this.$label = createRef();
  }

  onMouseOver = () => {
      this.setState({
          isHover: true
      });
  }

  setWarning = isWarning => {
      this.setState({ isWarning });
  }

  showIcon = visible => {
      this.setState({
          hideIcon: !visible
      });
  }

  onMouseOut = () => {
      if (this.state.isWarning) return;

      this.setState({
          isHover: false
      });
  }

  onMouseDown = event => {
      const clickTime = new Date().getTime();
      const { mousedown, holdTime = 500, callback, holdLabel } = this.props;

      event.stopPropagation();

      this.showIcon(false);

      if (holdLabel && this.$label) {
          this.$label.current.textContent = holdLabel;
      }

      if (mousedown) {
          mousedown();
      }

      const timeout = setTimeout(() => {
          window.removeEventListener("mouseup", onMouseUp);
          this.showIcon(true);
          clearTimeout(timeout);
          if (callback) {
              callback();
          }
      }, holdTime);

      const onMouseUp = ()=>{
          window.removeEventListener("mouseup", onMouseUp);

          if (new Date().getTime() - clickTime < 175) {
              this.setWarning(true);
          }

          this.showIcon(true);
          clearTimeout(timeout);
      };

      window.addEventListener("mouseup", onMouseUp);
  }

  render() {
      const { isHover, hideIcon, isWarning } = this.state;
      const { icon, label, holdLabel, holdTime = 500, disabled = false } = this.props;
      const labelText = isHover ? holdLabel : label;

      return (
          <HoldButtonStyle
              disabled={disabled}
              onMouseEnter={this.onMouseOver} onMouseLeave={this.onMouseOut} onMouseDown={this.onMouseDown} ref={this.$control}>
              <CircleSvg hideIcon={hideIcon} holdTime={holdTime} cx={15} cy={15} r={10} className="path"/>
              <StyledIcon disabled={disabled} className="micon" hideIcon={hideIcon}>{icon}</StyledIcon>
              <ShakingAnimaiton shake={hideIcon || isWarning} handleComplete={() => this.setWarning(false)}>
                  {labelText && <label ref={this.$label}>{labelText}</label>}
              </ShakingAnimaiton>
          </HoldButtonStyle>
      );
  }
}
