import React from "react";
import { OutlinedInput, MenuItem, Select } from "@material-ui/core";
import styled from "styled-components";

const TextTransformContainer = styled.div`
    margin-right: 10px;
    display: inline-block;
    min-width: 150px;
    
    label {
        display: block;
        margin-bottom: 6px;
        text-transform: uppercase;
    }
`;

const StyledOutlinedInput = styled(OutlinedInput)`
    width: 100%;
    border: solid 1px rgba(0,0,0,0.2);
`;

const TextTransform = ({
    label,
    values,
    name,
    selected,
    update,
}) => {
    const renderValues = values => {
        return values.map(item => {
            return (
                <MenuItem value={item.value} key={item.value}>
                    {item.label}
                </MenuItem>
            );
        });
    };
    const updateValue = (e, selected) => {
        update({ [name]: selected.props.value });
    };

    return (
        <TextTransformContainer>
            <label>{label}</label>
            <Select
                value={selected}
                onChange={updateValue}
                input={
                    <StyledOutlinedInput
                        labelWidth={0}
                        name={name}
                    />
                }
            >
                {renderValues(values)}
            </Select>
        </TextTransformContainer>
    );
};

export default TextTransform;
