import DecorationStyles from "js/react/components/DecorationStyles";
import { Gap10, Gap20 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import { ColorChit, ColorPalette } from "js/react/views/Editor/Components/ColorPalette";
import { PopupMenu } from "js/react/views/Editor/PopupMenu";
import { Fragment } from "react";
import React, { Component } from "react";
import styled from "styled-components";

const NoColorNotice = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  .MuiIcon-root {
    margin-right: 10px;
  }
`;

export class StylePopupMenu extends Component {
    state = {
        showPresets: true,
        selectedColor: this.props.element.model.color,
        selectedTextColor: this.props.element.model.textColor,
        showShadow: this.props.element.model.showShadow
    }

    handleChangeTextColor = color => {
        this.setState({ selectedTextColor: color });

        this.props.element.model.textColor = color;
        this.props.element.canvas.markStylesAsDirty();
        this.props.element.canvas.updateCanvasModel(true);
    }

    handleChangePaletteColor = color => {
        this.setState({ selectedColor: color });

        this.props.element.model.color = color;
        this.props.element.canvas.markStylesAsDirty();
        this.props.element.canvas.updateCanvasModel(true);
        this.props.callback(color);
    }

    handleChangePresetOption = value => {
        this.props.element.model.decorationStyle = value;
        this.props.element.canvas.markStylesAsDirty();
        this.props.element.canvas.updateCanvasModel(true);
    }

    handleChangeShowShadow = () => {
        this.props.element.model.showShadow = !this.state.showShadow;
        this.setState({ showShadow: !this.state.showShadow });
    }

    render() {
        let { selectedColor, selectedTextColor, selectedAccentColor, showShadow } = this.state;
        let { element, showAuto = true, showBackgroundColors, showTextColor, position } = this.props;

        let presets = (
            <Fragment>
                {showTextColor &&
                <Fragment>
                    <label>Text Color</label>
                    <Gap10/>
                    <ColorPalette
                        selectedColor={selectedTextColor}
                        onSelect={value => this.handleChangeTextColor(value)}
                        showTextColors
                        showAccentColors={false}
                    />
                    <Gap10/>
                    <label>Accent Color</label>
                    <Gap10/>
                </Fragment>
                }

                <ColorPalette
                    selectedColor={selectedColor}
                    onSelect={value => this.handleChangePaletteColor(value)}
                    showAuto={showAuto}
                    showPrimary
                    showSecondary
                    showBackgroundColors={showBackgroundColors}
                    autoColor={element.getSlideColor({ ignoreUserDefinedColor: true })}
                />

                {element.allowDecorationStyles &&
                    <Fragment>
                        <Gap20/>
                        <DecorationStyles
                            selectedColor={selectedColor}
                            handleChangePresetOption={value => this.handleChangePresetOption(value)}/>
                    </Fragment>
                }

                {/*<Button onClick={this.handleChangeShowShadow}>Toggle Shadow</Button>*/}
                {/*<Gap20/>*/}
                {/*<div className="toggle-button" onClick={() => this.setState({ showPresets: false })}>*/}
                {/*    Show Advanced*/}
                {/*</div>*/}
            </Fragment>
        );

        let advanced = (
            <Fragment>
                <FlexBox>
                    <label>Fill</label>
                    <ColorChit color="red" />
                    <Gap10 />
                    <label>Stroke</label>
                    <ColorChit color="blue" />
                    <input type="number" />
                </FlexBox>
                <Gap20 />
                <div className="toggle-button" onClick={() => this.setState({ showPresets: true })}>
                    Show Presets
                </div>
            </Fragment>
        );

        return (
            <PopupMenu {...this.props} position={position} className="style-popup-menu beautiful-ui">
                {this.state.showPresets ? presets : advanced}
            </PopupMenu>
        );
    }
}

