import React, { Component } from "react";
import { app } from "js/namespaces";
import { _, tinycolor } from "js/vendor";
import styled from "styled-components";

export class ColorPalette extends Component {
    render() {
        let colors = [];

        if (this.props.showAuto) {
            colors.push({
                name: "auto",
                color: this.props.autoColor.toRgbString() || app.currentTheme.palette.getColor(app.currentCanvas.getSlideColor()).toRgbString(),
            });
        }

        if (this.props.showNone) {
            colors.push({ name: "none", color: "none" });
        }

        if (this.props.showTextColors) {
            colors.push({
                name: "primary_dark",
                color: app.currentTheme.palette.getForeColor("primary_dark", null, null).toRgbString()
            });
            colors.push({
                name: "secondary_dark",
                color: app.currentTheme.palette.getForeColor("secondary_dark", null, null).toRgbString()
            });
            colors.push({
                name: "primary_light",
                color: app.currentTheme.palette.getForeColor("primary_light", null, null).toRgbString()
            });
            colors.push({
                name: "secondary_light",
                color: app.currentTheme.palette.getForeColor("secondary_light", null, null).toRgbString()
            });
        }

        if (this.props.showBackgroundColors) {
            colors.push({
                name: "background_light",
                color: app.currentTheme.palette.getForeColor("background_light", null, app.currentCanvas.getBackgroundColor()).toRgbString()
            });
            colors.push({
                name: "background_dark",
                color: app.currentTheme.palette.getForeColor("background_dark", null, app.currentCanvas.getBackgroundColor()).toRgbString()
            });
        }

        if (this.props.showLightDark) {
            let shade;

            if (app.currentCanvas.getBackgroundColor().name == "background_light" || app.currentCanvas.getBackgroundColor().name == "background_accent") {
                shade = "dark";
            } else {
                shade = "light";
            }

            colors.push({
                name: `primary_${shade}`,
                color: app.currentTheme.palette.getForeColor(`primary_${shade}`, null, app.currentCanvas.getBackgroundColor()).toRgbString()
            });

            colors.push({
                name: `secondary_${shade}`,
                color: app.currentTheme.palette.getForeColor(`secondary_${shade}`, null, app.currentCanvas.getBackgroundColor()).toRgbString()
            });
        }

        if (this.props.showBackgroundAccent) {
            colors.push({
                name: "background_accent",
                color: app.currentTheme.palette.getForeColor("background_accent", null, app.currentCanvas.getBackgroundColor()).toRgbString()
            });
        }

        if (this.props.showAccentColors !== false) {
            _.each(app.currentTheme.palette.getSlideColors(), (color, key) => {
                colors.push({ name: key, color: color });
            });
        }

        if (this.props.showPositiveNegative) {
            colors.push({ name: "positive", color: app.currentTheme.palette.getColor("positive").toRgbString() });
            colors.push({ name: "negative", color: app.currentTheme.palette.getColor("negative").toRgbString() });
        }

        if (this.props.omitColors) {
            colors = _.filter(colors, color => {
                return _.find(this.props.omitColors, omitColor => omitColor.name == color.name) == null;
            });
        }

        return (
            <div className="color_palette">
                {colors.map(color => (<ColorChit key={color.name} {...color} selected={color.name == this.props.selectedColor}
                    onClick={() => this.props.onSelect(color.name)}/>))}
            </div>
        );
    }
}

const NoneChit = styled.div`
  border-top: 30px solid #333;
  border-right: 30px solid white;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0px;
  left: 0px;
`;

export class ColorChit extends Component {
    render() {
        let className = "color_chit";

        if (this.props.selected) {
            className += " selected";
        }

        if (this.props.value == "auto") {
            className += " auto";
        }

        let style = {
            color: this.props.color,
            overflow: "hidden"
        };

        let chitLabel;

        if (this.props.value == "auto") {
            if (this.props.autoLabel) {
                chitLabel = this.props.autoLabel;
            } else {
                chitLabel = "SLIDE";
            }
        }

        if (this.props.color == "colorful") {
            className += " colorful_chit";
        }

        if (this.props.color == "none") {
            style.color = "transparent";
        }

        let labelColor;
        if (tinycolor(this.props.color).isDark()) {
            labelColor = "white";
        } else {
            labelColor = "black";
        }

        return (
            <div className={className} onClick={this.props.onClick} style={style}>
                {this.props.color == "colorful" &&
                    <div className="container">
                        {app.currentTheme.palette.getColorfulColors().map(color => <div style={{ background: app.currentTheme.palette.getColor(color).toRgbString() }}/>)}
                    </div>
                }
                {this.props.color == "none" &&
                <svg width="100%" height="100%">
                    <line x1="28" y1="0" x2="0" y2="28" stroke="black" strokeWidth={4}/>
                </svg>
                }
                {chitLabel && <div className="auto" style={{ color: labelColor }}>{chitLabel}</div>}
            </div>
        );
    }
}
