import React, { Component } from "react";
import styled from "styled-components";

import ContentEditable from "js/react/components/ContentEditable";
import { Key } from "js/core/utilities/keys";
import { getSelectionState, setSelection } from "js/core/utilities/htmlTextHelpers";

const DurationControlInputContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const EditContentEditable = styled(ContentEditable)`
    width: 60px;
    height: 20px;
    border: solid 1px #ccc;
    background: white;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    padding: 0;
    color: #222;
    cursor: text;
    text-transform: none;
`;

export class DurationControl extends Component {
    constructor(props) {
        super();

        this.state = {
            html: `${props.value}s`
        };

        this.selectionState = null;
        this.textRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if (value !== prevProps.value) {
            this.setHtml(value);
        }
    }

    parseDurationValue = value => {
        const { min = 0, max = 1000 } = this.props;

        if (typeof value === "string") {
            value = parseInt(value.replace(/[^0-9]/g, ""));
        }

        if (isNaN(value) || typeof value !== "number") {
            return 0;
        }

        return Math.clamp(value, min, max);
    }

    setHtml = async duration => {
        const selectionState = getSelectionState(this.textRef.current);
        await new Promise(resolve => this.setState({ html: `${duration}s` }, resolve));
        setSelection(selectionState, this.textRef.current);
    }

    handleChange = value => {
        const { onChange } = this.props;

        const duration = this.parseDurationValue(value);
        this.setHtml(duration)
            .then(() => onChange(duration));
    }

    handleKeyDown = event => {
        const { value } = this.props;
        if (event.which === Key.UP_ARROW || event.which === Key.DOWN_ARROW) {
            event.stopPropagation();
            event.preventDefault();
            this.handleChange(value + (event.which === Key.UP_ARROW ? 1 : -1));
        }
    };

    render() {
        const { style = {} } = this.props;
        const { html } = this.state;

        return (
            <DurationControlInputContainer>
                <EditContentEditable
                    innerRef={this.textRef}
                    html={html}
                    onKeyDown={this.handleKeyDown}
                    onMouseDown={event => event.stopPropagation()}
                    onChange={event => this.handleChange(event.target.value)}
                    style={style}
                />
            </DurationControlInputContainer>
        );
    }
}
