import React, { Component } from "react";
import { PropertySection, PropertyPanelContainer } from "js/EditorComponents/PropertyPanel";
import { PropertyPanelHeader } from "js/editor/ElementEditor/PropertyPanelHeader";
import { WithLabel } from "js/Components/WithLabel";
import { Dropdown } from "js/Components/Dropdown";
import { MenuItem } from "js/Components/Menu";
import { PositionType } from "common/constants";
import { ToggleSwitch } from "js/Components/ToggleSwitch";

export class VideoOverlayPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        if (!element) {
            return null;
        }

        return (
            <PropertyPanelContainer>
                <PropertyPanelHeader>
                    Video Bubble
                </PropertyPanelHeader>
                <PropertySection>
                    <WithLabel label="Position">
                        <Dropdown value={element.getPosition()} invalidValue="Custom"
                            onChange={position => element.setPosition(position, true)}
                        >
                            <MenuItem value={PositionType.BOTTOM_LEFT}>Bottom Left</MenuItem>
                            <MenuItem value={PositionType.BOTTOM_RIGHT}>Bottom Right</MenuItem>
                            <MenuItem value={PositionType.TOP_LEFT}>Top Left</MenuItem>
                            <MenuItem value={PositionType.TOP_RIGHT}>Top Right</MenuItem>
                            <MenuItem value="Custom">Custom</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Size">
                        <Dropdown value={element.model.size}
                            onChange={size => {
                                let position = element.getPosition();
                                element.model.size = size;
                                element.setPosition(position, false);
                            }}
                        >
                            <MenuItem value={150}>Small</MenuItem>
                            <MenuItem value={200}>Medium</MenuItem>
                            <MenuItem value={250}>Large</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Autoplay">
                        <ToggleSwitch value={element.model?.autoplay}
                            onChange={autoplay => element.updateModel({ autoplay })}
                        />
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}
