import React, { Component, Fragment } from "react";
import { Button, Divider, Icon, MenuItem, MenuList, Popover } from "@material-ui/core";
import { _ } from "js/vendor";
import styled from "styled-components";
import { ds } from "js/core/models/dataService";
import { FeatureType } from "js/core/models/features";
import { trackActivity } from "js/core/utilities/utilities";
import { PopupMenu } from "js/react/components/PopupMenu";
import { app } from "js/namespaces";
import {
    ShowConfirmationDialog,
    ShowDialog,
    ShowErrorDialog,
    ShowUpgradeDialog
} from "js/react/components/Dialogs/BaseDialog";
import { Gap10 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import ProBadge from "js/react/components/ProBadge";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";
import { UploadFontModal } from "js/editor/ThemeEditor/components/Font/UploadFontModal";
import shouldShowProBadge from "js/core/utilities/shouldShowProBadge";
import { NestedMenuItem } from "js/react/components/NestedMenuItem";
import { fontManager } from "js/core/services/fonts";
import getLogger, { LogGroup } from "js/core/logger";
import AppController from "js/core/AppController";
import { DarkButton } from "js/react/components/UiComponents";
import Spinner from "js/react/components/Spinner";
import ThemeEditorController from "js/editor/ThemeEditor/ThemeEditorController";

const logger = getLogger(LogGroup.ASSETS);

const CurrentFontImage = styled.img`
    height: 24px;
    margin-top: 5px;
`;

const FallbackFontContainer = styled.span`
    height: 24px;
    margin-top: 5px;
`;

const FontListContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const FontDropdown = styled.div`
    color: #333;
    background: white;
    width: 300px;
    height: 33px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    border: solid 1px rgba(0, 0, 0, 0.2);

    &.missingFont {
        position: relative;
        border-color: #FF4500;

        &::after {
            content: "Upload missing font or select a new one.";
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin-top: 3px;
            color: #FF4500;
            font-weight: bold;
            font-size: 10px;
            line-height: 15px;
            text-transform: initial;
        }
    }
`;

const FontOptions = styled.div`
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;
    text-transform: none;
`;

const DropDownIcon = styled(Icon)`
    margin-left: 5px;
`;

const FontListLabel = styled.label`
    text-transform: uppercase;
    font-weight: 500;
    color: #333;
    padding-left: 14px;
    padding-top: 14px;
    display: block;
`;

const UploadFontListOption = styled(Button)`
    font-size: 16px;
    color: #333;
    justify-content: flex-start;
    padding: 12px 16px;
    border-top: solid 1px rgba(0, 0, 0, 0.2);

    &:hover {
        background: #50bbe6;
    }
`;

class FontList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            fontModalOpen: false,
            selectedFontId: props.selectedFontId,
            selectedFontWeight: props.selectedFontWeight,
            isLoading: true
        };
    }

    async componentDidMount() {
        const { theme } = this.props;
        await ThemeEditorController.loadFonts(theme);
        this.setState({ isLoading: false });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedFontId != this.state.selectedFontId) {
            this.setState({
                selectedFontId: this.props.selectedFontId,
            });
        }
        if (this.props.selectedFontWeight != this.state.selectedFontWeight) {
            this.setState({
                selectedFontWeight: this.props.selectedFontWeight,
            });
        }
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    openUploadModal = () => {
        this.handleClose();

        if (!app.user.features.isFeatureEnabled(FeatureType.CUSTOM_FONTS, AppController.workspaceId)) {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                workspaceId: AppController.workspaceId,
                analytics: { cta: "CustomFont" },
            });
            return;
        }

        trackActivity("CustomFonts", "ShowUploadDialog", null, null, {
            workspaceId: AppController.workspaceId
        });

        //if team theme props should be workspace id and sharedTheme true
        ShowDialog(UploadFontModal, {
            onSetCustomFont: async (fontId, weight) => {
                this.handleSetFontStyle(fontId, weight);
            },
        });
    };

    handleSetFontStyle = (fontId, weight) => {
        ThemeEditorController.loadFonts(this.props.theme).then(() => {
            this.setState({
                selectedFontId: fontId,
                selectedFontWeight: weight
            });

            this.props.onSelectFont(fontId, weight);
            this.handleClose();
        });
    };

    handleDeleteCustomFont = fontId => {
        const { orgId } = this.props;
        const { selectedFontId } = this.state;

        if (fontId === selectedFontId) {
            ShowErrorDialog({
                title: "Can't delete current font",
                message: "You cannot delete currently used font, select another font as current theme font first"
            });
        } else {
            ShowConfirmationDialog({
                title: "Are you sure you want to delete this custom font?",
                acceptCallback: async () => {
                    try {
                        const userAsset = ds.userAssets.find({ id: fontId });
                        if (userAsset) {
                            ds.userAssets.remove([userAsset]);
                        }

                        const asset = await ds.assets.getAssetById(fontId, "font");
                        if (asset) {
                            ds.assets.remove([asset]);
                        }

                        // Clean up the font cache to let it get loaded into ds.assets when
                        // requested
                        delete fontManager.loadFontPromises[fontId];

                        await ThemeEditorController.loadFonts(this.props.theme);
                    } catch (err) {
                        logger.error(err, "FontList handleDeleteCustomFont() failed", { orgId, fontId });
                    }
                }
            });
        }
    }

    render() {
        const {
            fonts,
        } = this.props;

        const { anchorEl, selectedFontWeight, selectedFontId, isLoading } = this.state;

        if (isLoading) {
            return (
                <FlexBox left middle fillWidth style={{ position: "relative", height: 33, border: "solid 1px #ccc" }}>
                    <Spinner small />
                </FlexBox>
            );
        }

        const open = Boolean(anchorEl);

        const hasCustomFontFeature = app.user.features.isFeatureEnabled(FeatureType.CUSTOM_FONTS, AppController.workspaceId);

        const customFonts = _.sortBy(fonts.filter(font => font.isCustomFont), "label");
        const builtInFonts = _.sortBy(fonts.filter(font => font.isBuiltInFont), "label");

        let selectedFont = fonts.findById(selectedFontId);
        let selectedFontStyle = fontManager.getFontStyle(selectedFont.styles, selectedFontWeight, false);

        return (
            <FlexBox left middle>
                <FontListContainer>
                    <FontDropdown onClick={this.handleClick}>
                        <FontOptions>
                            <CurrentFontImage src={selectedFontStyle.imageData} />
                        </FontOptions>
                        <DropDownIcon>
                            arrow_drop_down
                        </DropDownIcon>
                    </FontDropdown>
                    <Popover
                        disableEnforceFocus
                        anchorEl={anchorEl}
                        open={open}
                        onClose={this.handleClose}
                    >
                        <div style={{ maxHeight: 560, overflowY: "auto" }}>
                            {(customFonts.length > 0 && hasCustomFontFeature) && (
                                <div>
                                    <FontListLabel>
                                        Uploaded Fonts
                                    </FontListLabel>
                                    <Gap10 />
                                    {customFonts.map(font => {
                                        // We do not want to present italic fonts in the font list
                                        const nonItalicFonts = font.styles.filter(style => !style.italic);

                                        if (font.isFallbackFont || nonItalicFonts.length === 0) {
                                            return null;
                                        }
                                        return (
                                            <NestedMenuItem
                                                key={font.id}
                                                contents={<img src={font.imageData} height={24} />}
                                            >
                                                {nonItalicFonts
                                                    .map((fontStyle, index) => (
                                                        <FlexBox
                                                            key={index}
                                                            fillWidth
                                                            left
                                                        >
                                                            <div style={{ flexGrow: 2 }}>
                                                                <MenuItem
                                                                    onClick={() => this.handleSetFontStyle(font.id, fontStyle.weight)}>
                                                                    <img height={24} src={fontStyle.imageData} />
                                                                </MenuItem>
                                                            </div>
                                                            <PopupMenu
                                                                icon="more_vert"
                                                                style={{
                                                                    paddingLeft: "9px",
                                                                    paddingRight: 0,
                                                                }}
                                                                iconStyle={{
                                                                    fontSize: 18,
                                                                    color: "#333",
                                                                }}
                                                            >
                                                                <MenuList>
                                                                    <MenuItem onClick={event => {
                                                                        event.stopPropagation();
                                                                        this.handleDeleteCustomFont(font.id);
                                                                    }}>
                                                                        Delete Font
                                                                    </MenuItem>
                                                                </MenuList>
                                                            </PopupMenu>
                                                        </FlexBox>
                                                    ))}
                                            </NestedMenuItem>
                                        );
                                    })}
                                    <Gap10 />
                                    <Divider />
                                </div>
                            )}
                            {builtInFonts.length > 0 && (
                                <Fragment>
                                    <FontListLabel>
                                        Built-in fonts
                                    </FontListLabel>
                                    <Gap10 />
                                </Fragment>
                            )}
                            {
                                builtInFonts.map(font => {
                                    // We do not want to present italic fonts in the font list
                                    const nonItalicFonts = font.styles.filter(style => !style.italic);

                                    if (font.isFallbackFont || nonItalicFonts.length === 0) {
                                        return null;
                                    }
                                    return (
                                        <NestedMenuItem
                                            key={font.id}
                                            contents={<img src={font.imageData} height={24} />}
                                        >
                                            {nonItalicFonts
                                                .map((fontStyle, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        onClick={() => this.handleSetFontStyle(font.id, fontStyle.weight)}
                                                    >
                                                        <img
                                                            height={24}
                                                            src={fontStyle.imageData}
                                                        />
                                                    </MenuItem>
                                                ))}
                                        </NestedMenuItem>
                                    );
                                })
                            }
                        </div>
                        <Gap10 />
                        <DarkButton fullWidth={true} onClick={this.openUploadModal}>
                            <Icon> cloud_upload </Icon>
                            Upload Font
                            <ProBadge
                                beforeShowDialogCallback={this.handleClose}
                                show={shouldShowProBadge(FeatureType.CUSTOM_FONTS, AppController.workspaceId)}
                                analytics={{ cta: "CustomFont" }}
                                workspaceId={AppController.workspaceId}
                            />
                        </DarkButton>
                    </Popover>
                </FontListContainer>
            </FlexBox>
        );
    }
}

export default ThemeEditorController.withState(FontList);
