import React from "react";
import { IconButton } from "@material-ui/core";
import classNames from "classnames";
import styled from "styled-components";

const OptionLabel = styled.label`
& {
    margin: 0 6px;
    cursor: pointer;

    > span {
        padding-left: 16px;
    }
}
`;

const OptionButton = styled(IconButton)`
// Needed for styles to take priority over MUI styles
&& {
    display: inline-block;
    text-align: center;
    position: relative;
    padding: 0;
    text-transform: uppercase;
    border-radius: 0;
    color: #A9A9A9;
    background: #CCC;

    img {
        display: block;
        width: 50px;
    }
    .MuiIcon-root {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
    }
    &.selected {
        color: white;
        background-color: #11a9ef;
        border-radius: 0;
    }
    &:disabled {
        background-color: rgba(0, 0, 0, 0.05);

        img {
            opacity: 0.5;
        }
    }
}
`;

const ThemeOption = ({
    item,
    property,
    disabled = false,
    update,
    selected
}) => {
    return (
        <div>
            <OptionLabel>
                <OptionButton
                    disabled={disabled}
                    className={classNames({
                        OptionIcon: true,
                        selected,
                    })}
                    onClick={() => update({ [property]: item.value })}
                >
                    {item.icon}
                </OptionButton>
                <span>{item.label}</span>
            </OptionLabel>
        </div>
    );
};
export default ThemeOption;
