import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { FlexSpacer } from "js/react/components/Gap";
import AnimationPanel from "js/react/views/AnimationPanel/AnimationPanel";
import { _ } from "js/vendor";
import React, { Component } from "react";
import styled from "styled-components";

import { Button } from "../../Components/Button";
import { Icon } from "../../Components/Icon";
import { RoundIconButton } from "../../Components/IconButton";
import { Key } from "../../core/utilities/keys";
import { stopPropagation } from "../../core/utilities/stopPropagation";
import AddCalloutPanel from "../PresentationEditor/Components/AddCalloutPanel";
import AdvanceNextSlidePanel from "../PresentationEditor/Components/AdvanceNextSlidePanel";
import { RevisionPanel } from "../PresentationEditor/Components/RevisionPanel";
import { StylePanel } from "../PresentationEditor/Components/StylePanel";
import { IdeasPanel } from "./IdeasPanel";
import ManageSlidePanel from "./ManageSlidePanel";
import { RecordPropertyPanel } from "./RecordPropertyPanel";
import LayoutPanel from "./LayoutPanel";
import { StyledScrollBox } from "../../Components/StyledScrollBox";
import { PropertyPanelContainer } from "../../EditorComponents/PropertyPanel";
import RecordPanel from "../../react/views/RecordPanel/RecordPanel";
import DebugPanel from "./DebugPanel";
import { isInternalBAIUser } from "js/config";
import AppController from "js/core/AppController";
import DebugController from "js/core/DebugController";
import { Divider } from "js/Components/Divider";
import { MenuItem } from "@material-ui/core";
import { BugReport as BugReportIcon } from "@material-ui/icons";

const Container = styled.div`
    color: black;
    background: #f1f1f1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const SkippedSlideWarning = styled.div`
    color: white;
    background: #e8950b;
    width: 100%;
    height: 40px;
    font-size: 14px;
    padding: 10px 5px 10px 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    .bai-icon {
        color: white;
        margin-right: 5px;
    }

    .bai-button {
        background: rgba(255, 255, 255, 0.5);
        border: solid 1px rgba(0, 0, 0, .5) !important;
    }
`;

const LockShield = styled.div`
    pointer-events: ${props => (props.isLocked ? "none" : "auto")};
    opacity: ${props => (props.isLocked ? 0.5 : 1)};
    width: 100%;
    height: 100%;
`;

const CollapsePanelButton = styled.div`
    position: absolute;
    right: -12px;
    top: 12px;

    .bai-icon {
        font-size: 36px;
    }

    transition: right 300ms;
`;

const DebugPanelButton = styled.div`
    position: absolute;
    right: -12px;
    top: 48px;
    .bai-icon {
        font-size: 36px;
    }
    transition: right 300ms;
`;

class InnerEditorPropertyPanel extends Component {
    constructor(props) {
        super(props);

        this.isLockedFromPanel = false;
    }

    shouldComponentUpdate(nextProps) {
        const { isCanvasGenerating: willCanvasBeGenerating, isCanvasInErrorState: willCanvasBeInErrorState, isCanvasRendered: willCanvasBeRendered } = nextProps;
        const { isCanvasRendered } = this.props;

        if (willCanvasBeGenerating) {
            // Prevent refresh during canvas generation
            return false;
        }

        if ((!isCanvasRendered || (isCanvasRendered && !willCanvasBeRendered)) && !willCanvasBeInErrorState) {
            // Prevent refresh when canvas isn't rendered
            return false;
        }

        return true;
    }

    componentDidMount() {
        const { isCanvasInErrorState } = this.props;

        if (isCanvasInErrorState) {
            PresentationEditorController.setSelectedPropertyPanelTab("versions");
        } else {
            PresentationEditorController.setSelectedPropertyPanelTab("element");
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedPropertyPanelTab, selectedElements, isCurrentSlideLockedByMe, isCanvasInErrorState } = this.props;

        if (prevProps.isCurrentSlideLockedByMe !== isCurrentSlideLockedByMe) {
            if (!isCurrentSlideLockedByMe || (!this.isLockedFromPanel && isCurrentSlideLockedByMe)) {
                this.isLockedFromPanel = false;
            }
        }

        if (prevProps.isCanvasInErrorState !== isCanvasInErrorState) {
            clearTimeout(this.setVersionsTabTimeout);

            if (isCanvasInErrorState && selectedPropertyPanelTab !== "versions") {
                this.setVersionsTabTimeout = setTimeout(() => {
                    if (this.props.isCanvasInErrorState) {
                        // Still in error state, show versions tab
                        PresentationEditorController.setSelectedPropertyPanelTab("versions");
                    }
                }, 2000);
                return;
            }
        }

        if (
            selectedPropertyPanelTab !== "element" &&
            !isCanvasInErrorState &&
            !_.isEqual(prevProps.selectedElements.map(el => el.uniquePath), selectedElements.map(el => el.uniquePath)) &&
            !selectedElements[0]?.isInstanceOf("VideoOverlay")
        ) {
            PresentationEditorController.setSelectedPropertyPanelTab("element");
        }
    }

    handleKeyDown = event => {
        if (event.which === Key.KEY_Z && (event.metaKey || event.ctrlKey)) {
            return;
        }

        stopPropagation()(event);
    }

    handleLockShieldMouseDown = () => {
        const { isCurrentSlideLockedByMe, currentCanvasController } = this.props;

        if (!isCurrentSlideLockedByMe) {
            this.isLockedFromPanel = true;
            currentCanvasController.lockSlideForCollaborators(10);
        }
    }

    handleLockShieldMouseLeave = () => {
        const { isCurrentSlideLockedByMe, currentCanvasController } = this.props;

        if (isCurrentSlideLockedByMe && this.isLockedFromPanel) {
            currentCanvasController.unlockSlideForCollaborators();
        }
    }

    handleTabChange = async tab => {
        const { currentCanvasController } = this.props;

        await currentCanvasController.selectionLayerController.setSelectedElements([]);
        await PresentationEditorController.setSelectedPropertyPanelTab(tab);
    }

    render() {
        const {
            currentCanvasController,
            currentSelectionLayerController,
            presentation,
            currentSlide,
            isCanvasRendered,
            isCanvasInErrorState,
            selectedPropertyPanelTab,
            showPropertyPanel,
            allowSharedSlideEditing,
            isCurrentSlideLockedForMe
        } = this.props;

        const isLocked = (currentSlide.isLibrarySlide() && !allowSharedSlideEditing) || isCurrentSlideLockedForMe;

        if (selectedPropertyPanelTab === "versions") {
            return (
                <Container onMouseDown={stopPropagation()} onKeyDown={this.handleKeyDown}>
                    <LockShield
                        isLocked={isLocked}
                        onMouseDown={this.handleLockShieldMouseDown}
                        onMouseLeave={this.handleLockShieldMouseLeave}
                    >
                        <RevisionPanel
                            currentCanvasController={currentCanvasController}
                            currentSlide={currentCanvasController.slide}
                            currentSelectionLayerController={currentSelectionLayerController}
                            onClose={() => PresentationEditorController.setSelectedPropertyPanelTab("element")}
                            canClose={!isCanvasInErrorState}
                        />
                    </LockShield>
                </Container>
            );
        }

        if (!isCanvasRendered) {
            return null;
        }

        if (!currentCanvasController.canvasBundle) {
            return null;
        }

        const getPanel = () => {
            const Panel = (() => {
                switch (selectedPropertyPanelTab) {
                    case "add":
                        return <LayoutPanel {...this.props} />;
                    case "styles":
                        return <StylePanel {...this.props} />;
                    case "record":
                        return <RecordPropertyPanel {...this.props} />;
                    case "advance":
                        return <AdvanceNextSlidePanel {...this.props} />;
                    case "ideas":
                        return <IdeasPanel {...this.props} />;
                    case "animate":
                        return (
                            <PropertyPanelContainer>
                                <AdvanceNextSlidePanel {...this.props} />
                                <RecordPanel {...this.props} />
                                <AnimationPanel {...this.props} />
                            </PropertyPanelContainer>
                        );
                    case "callouts":
                        return <AddCalloutPanel {...this.props} onClose={() => PresentationEditorController.setSelectedPropertyPanelTab("layout")} />;
                    case "manage-slide":
                        return <ManageSlidePanel {...this.props} />;
                    case "debug":
                        return <DebugPanel {...this.props} />;
                    case "element":
                    default:
                        const { SelectedElementPropertyPanel } = currentCanvasController.canvasBundle;
                        return <SelectedElementPropertyPanel {...this.props} />;
                }
            })();

            return (
                <LockShield
                    isLocked={isLocked}
                    onMouseDown={this.handleLockShieldMouseDown}
                    onMouseLeave={this.handleLockShieldMouseLeave}
                >
                    <StyledScrollBox>{Panel}</StyledScrollBox>
                </LockShield>
            );
        };

        const { SlideMenuBar } = currentCanvasController.canvasBundle;

        return (
            <Container onMouseDown={stopPropagation()} onKeyDown={this.handleKeyDown}>
                {presentation.isSlideSkipped(currentSlide) && (
                    <SkippedSlideWarning>
                        <Icon>visibility_off</Icon>
                        Skip slide during playback
                        <FlexSpacer />
                        <Button outlined small height={29} onClick={() => PresentationEditorController.setSkipSlide(currentSlide, false)}>Unskip</Button>
                    </SkippedSlideWarning>
                )}
                <SlideMenuBar
                    {...this.props}
                    onChange={this.handleTabChange}
                    selectedTab={selectedPropertyPanelTab}
                >
                    {getPanel()}
                </SlideMenuBar>
                <CollapsePanelButton style={{ right: showPropertyPanel ? -35 : -35 }}>
                    <RoundIconButton size={24} fill icon={showPropertyPanel ? "keyboard_arrow_left" : "keyboard_arrow_right"} onClick={() => PresentationEditorController.togglePropertyPanel()} />
                </CollapsePanelButton>
                {isInternalBAIUser(AppController.user.getEmail()) &&
                    DebugController.debugButtonVisible &&
                    <DebugPanelButton style={{ right: showPropertyPanel ? -35 : -35 }}>
                        <RoundIconButton size={24} fill icon="bug_report" onClick={() => this.handleTabChange("debug")} />
                    </DebugPanelButton>}
            </Container>
        );
    }
}

export const EditorPropertyPanel = PresentationEditorController.withFullState(
    InnerEditorPropertyPanel,
    // Skip refresh when freezePropertiesPanel is true
    (props, nextProps) => nextProps.freezePropertiesPanel !== true && nextProps.freezeSelectionLayer !== true
);
