import React, { Component } from "react";
import styled from "styled-components";
import Spinner from "js/react/components/Spinner";

const SpinnerContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;

export const ThemeSpinner = () => (
    <SpinnerContainer><Spinner /></SpinnerContainer>
);
