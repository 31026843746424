import { $ } from "js/vendor";
import React, { Component } from "reactn";
import {
    AccountPane,
    AdvancedSettingPane,
    ApiPane,
    BillingPane,
    ManageTeamPane,
    PreferencesPane,
    ReferralsPane,
    NotificationsPane,
    IntegrationsPane
} from "./panes";

import { AnalyticsRolesAndLicensesMap, TEAM_USER_LICENSE_STATUS, TEAM_USER_ROLES } from "common/constants";
import { isDevelopment, isPPTAddin } from "js/config";
import Api from "js/core/api";
import getLogger from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { FeatureType } from "js/core/models/features";
import { Key } from "js/core/utilities/keys";
import { getQueryVariables, trackActivity } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import {
    ShowConfirmationDialog,
    ShowDialog,
    ShowErrorDialog
} from "js/react/components/Dialogs/BaseDialog";
import { Gap20 } from "js/react/components/Gap";
import LogOutButton from "js/react/components/LogOutButton";
import Spinner from "js/react/components/Spinner";
import {
    UINavigation,
    UINavigationItem,
    UINavigationItemHeader
} from "js/react/components/UiComponents";
import { AnimatedFullScreenContainer } from "js/editor/components/AnimatedFullScreenContainer";

import "css/user-options.scss";
import AppController from "js/core/AppController";
import RemoveMemberFromOrgDialog from "./dialogs/RemoveMemberFromOrgDialog";

const logger = getLogger();

export function getUsedSeatCount(teamMembers) {
    let count = 0;
    teamMembers.forEach(member => {
        if (member.hasSeat) {
            count++;
        } else if (member.pending && member.license === TEAM_USER_LICENSE_STATUS.TEAM_PRO) {
            count++;
        }
    });
    return count;
}

class UserOptionsContainer extends Component {
    constructor(props) {
        super(props);

        let workspaceId = AppController.workspaceId;
        let orgId;
        let workspace;
        if (workspaceId == "personal") {
            orgId = null;
            workspace = { name: "Personal", icon: "person", id: "personal" };
        } else {
            orgId = workspaceId;
            const defaultTeam = ds.teams.defaultTeamForOrg(orgId);
            workspace = { name: defaultTeam.get("name"), icon: "business", id: orgId };
        }

        this.state = {
            selectedPaneId: props.pane || "profile",
            isOpen: true,
            organizationId: orgId,
            loading: true,
            org: {},
            paneClicked: 0,
            workspaceId,
            workspace,
            ...UserOptionsContainer.getFeatures(workspaceId),
            returnId: getQueryVariables("returnId"),
            slideIndex: getQueryVariables("slideIndex")
        };

        $(window).on("keydown", event => {
            if (event.which == Key.ESCAPE) {
                this.handleBackClicked();
            }
        });
    }

    async componentDidMount() {
        if (this.state.canManageTeam) {
            await this.getOrg(this.state.organizationId);
        } else {
            this.setState({ loading: false });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.pane != prevProps.pane) {
            this.setState({ selectedPaneId: this.props.pane });
        }
    }

    static getFeatures(workspaceId) {
        return {
            hasTeamsSettings: app.user.has("orgs"),
            hasTeams: app.user.features.isFeatureEnabled(FeatureType.TEAMS, workspaceId),
            canEditLibrary: app.user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, workspaceId),
            canManageTeam: app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM, workspaceId),
            canManageTeamBilling: app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM_BILLING, workspaceId),
            canManageOrg: app.user.features.isFeatureEnabled(FeatureType.MANAGE_ORG, workspaceId),
            hasApiFeature: isDevelopment && app.user.features.isFeatureEnabled(FeatureType.API, workspaceId),
            hasAdvancedSettingsFeature: app.user.features.isFeatureEnabled(FeatureType.ADVANCED_SETTINGS, workspaceId),
        };
    }

    handleBackClicked = () => {
        if (this.state.selectedPaneId === "manage-group") {
            this.setState({ selectedPaneId: "groups" });
        } else {
            if (this.state.returnId) {
                AppController.showEditor({ presentationId: this.state.returnId, slideIndex: this.state.slideIndex ?? 0 });
            } else {
                AppController.showLibrary();
            }
        }
    };

    selectPane = (pane, props) => {
        AppController.showAccountPane({ pane });
    };

    handleManageGroupClicked = id => {
        this.setState({
            selectedPaneId: "manage-group",
            selectedPaneProps: {
                teamId: id
            }
        });
    };

    getOrg = async orgId => {
        const orgResponse = await Api.organizations.get({ id: orgId });

        this.setState({
            organizationId: orgId,
            org: Object.assign({}, orgResponse),
            ...UserOptionsContainer.getFeatures(orgId),
            loading: false
        });
    };

    removeMember = async (adminMembers, team, user, childPane, onRemove, isDefaultTeam, allMembers, isRemovingSelf, newOwnerId, numPresentationsTransferred, isTransferringOwner, shouldRemovePresentations) => {
        const teamId = team.id;
        const { organizationId } = this.state;
        if (isDefaultTeam && adminMembers.length === 1 && user.role.toLowerCase() === TEAM_USER_ROLES.OWNER) {
            ShowErrorDialog({
                error: "Unable to remove yourself from the team",
                message: "There needs to be one more admin in the team."
            });
            return;
        }
        try {
            const backboneTeam = ds.teams.get(teamId);
            if (backboneTeam && isRemovingSelf) {
                backboneTeam.disconnect();
            }
            await Api.teams.put({
                orgId: organizationId,
                teamId: teamId,
                type: "remove_users",
                shouldRemovePresentations,
                userIds: [user.id]
            });

            if (isDefaultTeam) {
                const props = {
                    workspace_id: organizationId,
                    affected_userid: user.id,
                    affected_user_role: AnalyticsRolesAndLicensesMap[user.role],
                    affected_user_license: AnalyticsRolesAndLicensesMap[user.license],
                    affected_user_presentations_owned: numPresentationsTransferred,
                    presentations_transferred_to_personal: isTransferringOwner ? 0 : numPresentationsTransferred,
                    presentations_transferred_to_team_member: isTransferringOwner ? numPresentationsTransferred : 0,
                    presentation_recipient_user_id: newOwnerId
                };
                trackActivity("Organization", "UserRemoved", null, null, props, { audit: true });
            } else {
                const props = {
                    workspace_id: organizationId,
                    team_id: team.id,
                    team_name: team.name,
                    userid_added: null,
                    userid_removed: user.id,
                    num_team_members: allMembers.length - 1,
                    num_changed: -1,
                    change_mechanism: isRemovingSelf ? "user initiated" : "admin initiated"
                };
                trackActivity("OrgTeam", "MembershipChanged", null, null, props, { audit: true });
            }
            if (user.id === app.user.id && user.role.toLowerCase() !== TEAM_USER_ROLES.OWNER) {
                if (childPane === "manage") {
                    this.selectPane("groups");
                    return;
                }
            }
            onRemove && onRemove();
        } catch (err) {
            logger.error(err, "UserOptionsContainer removeMember() failed", { teamId, organizationId, userId: user.id });
            ShowErrorDialog({
                error: "Failed to remove team invite",
                message: "Something went wrong. Please try again and reach out to support@beautiful.ai if the issue persists."
            });
        }
    };

    handleRemoveMember = async (
        team,
        user,
        allMembers,
        childPane,
        onRemove,
        isDefaultTeam
    ) => {
        const adminMembers = allMembers.filter(
            member => member.role === "owner"
        );
        const teamPlaceHolder = isDefaultTeam ? "organization" : "team";
        const isRemovingSelf = user.id === app.user.id;
        const title = isRemovingSelf
            ? `Are you sure you want to leave ${team.name} ?`
            : `Are you sure you want to remove ${user.displayName || user.email}`;
        const message = isRemovingSelf
            ? `You will no longer have access to any presentations or data shared with this ${teamPlaceHolder}. You will not be able rejoin the ${teamPlaceHolder} unless an owner member invites you.`
            : `They will no longer have access to any presentations or data shared with this ${teamPlaceHolder}.`;

        if (isDefaultTeam) {
            ShowDialog(RemoveMemberFromOrgDialog, {
                member: user,
                allMembers,
                onAccept: async (fromUser, toUser, transferOption) => {
                    const orgId = this.state.organizationId;
                    const isTransferringOwner = transferOption === "other" && toUser.id;
                    const shouldRemovePresentations = transferOption === "revoke";

                    let numPresentationsTransferred;
                    if (isTransferringOwner) {
                        const response = await Api.transferTeamResourcesOwnership
                            .post({
                                fromUid: fromUser.id,
                                toUid: toUser.id,
                                orgId
                            });
                        numPresentationsTransferred = response.numPresentationsTransferred;
                    } else {
                        const response = await Api.transferResourceCount
                            .get({
                                transferFromUserId: fromUser.id,
                                orgId
                            });
                        numPresentationsTransferred = response.numPresentationsTransferred;
                    }

                    this.removeMember(adminMembers, team, user, childPane, onRemove, isDefaultTeam, allMembers, isRemovingSelf, toUser.id, numPresentationsTransferred, isTransferringOwner, shouldRemovePresentations);
                }
            });
        } else {
            ShowConfirmationDialog({
                title,
                message
            }).then(async accept => {
                if (!accept) {
                    return;
                }
                this.removeMember(adminMembers, team, user, childPane, onRemove, isDefaultTeam, allMembers, isRemovingSelf);
            });
        }
    };

    handleTeamNameChange = teamName => {
        this.setState(prevState => {
            return {
                ...prevState,
                workspace: {
                    ...prevState.workspace,
                    name: teamName
                }
            };
        });
    }

    renderSelectedPane = () => {
        const { selectedPaneId, selectedPaneProps, organizationId, paneClicked, workspaceId, canManageTeam, hasAdvancedSettingsFeature, org } = this.state;

        switch (selectedPaneId) {
            case "billing":
                if (canManageTeam) {
                    return <BillingPane organizationId={organizationId} {...selectedPaneProps} />;
                } else {
                    return <BillingPane {...selectedPaneProps} />;
                }
            case "team-billing":
                return (
                    <BillingPane
                        organizationId={organizationId}
                        {...selectedPaneProps}
                    />
                );
            case "manage-default-team":
                return (
                    <ManageTeamPane
                        handleTeamNameChange={this.handleTeamNameChange}
                        handleRemoveMember={this.handleRemoveMember}
                        isDefaultTeam={true}
                        organizationId={organizationId}
                        teamId={ds.teams.defaultTeamForOrg(organizationId).id}
                        paneClicked={paneClicked}
                        {...selectedPaneProps}
                    />
                );
            case "preferences":
                return <PreferencesPane {...selectedPaneProps} />;
            case "integrations":
                return <IntegrationsPane organizationId={organizationId} />;
            case "advanced-settings":
                if (org.isEnterprise || hasAdvancedSettingsFeature) {
                    return <AdvancedSettingPane organizationId={organizationId} />;
                } else {
                    // if the user is not an enterprise user and does not have the advanced settings feature,
                    // redirect to the manage team pane
                    this.selectPane("manage-default-team");
                }
            case "api":
                return <ApiPane />;
            case "notifications":
                return <NotificationsPane workspaceId={workspaceId} />;
            case "referrals":
                return <ReferralsPane />;
            case "profile":
            default:
                return (
                    <AccountPane
                        onClose={app.isConstrained && this.handleBackClicked}
                        userMenu={this.props.userMenu}
                        {...selectedPaneProps}
                    />
                );
        }
    };

    handleLibrarySlideSelected = libraryItem => {
        this.setState({
            selectedPaneId: "library-slide",
            selectedPaneProps: {
                libraryItemId: libraryItem.id
            }
        });
    };

    handleLibrarySlideClose = () => {
        this.setState({
            selectedPaneId: "teams-libraries"
        });
    }

    render() {
        const { workspace, selectedPaneId, hasTeams, canManageOrg, hasApiFeature, canManageTeamBilling, hasAdvancedSettingsFeature, org, loading } = this.state;

        return (
            <>
                {loading && <Spinner />}
                {!loading && <AnimatedFullScreenContainer
                    isOpen={true}
                    className="user-options"
                >

                    {
                        !app.isConstrained &&
                        <UINavigation
                            selected={selectedPaneId}
                            onSelect={id => {
                                this.selectPane(id);
                            }}
                            onBack={this.handleBackClicked}
                            fullHeight
                        >
                            <UINavigationItem
                                id="me"
                                title="My Account"
                                icon="person"
                            />
                            <UINavigationItem
                                id="preferences"
                                title="Preferences"
                                icon="settings"
                            />
                            {!isPPTAddin && (
                                <UINavigationItem
                                    id="notifications"
                                    title="Notifications"
                                    icon="notifications"
                                />
                            )}
                            {!isPPTAddin && hasApiFeature && (
                                <UINavigationItem
                                    id="api"
                                    title="API"
                                    icon="api"
                                />
                            )}
                            {!isPPTAddin && workspace.id == "personal" && (
                                <UINavigationItem
                                    id="billing"
                                    title="Billing"
                                    icon="credit_card"
                                />
                            )}
                            {!isPPTAddin && workspace.id == "personal" && (
                                <UINavigationItem
                                    id="referrals"
                                    title="Referrals"
                                    icon="grade"
                                />
                            )}
                            <Gap20 />
                            {hasTeams && (
                                <div className="workspace">
                                    <div className="title">TEAM</div>
                                    <div className="label">{workspace.name}</div>
                                </div>
                            )}
                            {!isPPTAddin && (
                                <UINavigationItemHeader title="" fullHeight>
                                    {hasTeams && (
                                        <UINavigationItem
                                            id="manage-default-team"
                                            title={canManageOrg ? "Manage Team" : "View Team"}
                                            icon="how_to_reg"
                                        />
                                    )}
                                    {canManageTeamBilling && (
                                        <UINavigationItem
                                            id="team-billing"
                                            title="Billing"
                                            icon="credit_card"
                                        />
                                    )}
                                    {canManageOrg && (
                                        <UINavigationItem
                                            id="integrations"
                                            title="Integrations"
                                            icon="extension"
                                        />
                                    )}
                                    {(org.isEnterprise || hasAdvancedSettingsFeature) && canManageOrg && (
                                        <UINavigationItem
                                            id="advanced-settings"
                                            title="Advanced"
                                            icon="tune"
                                        />
                                    )}
                                </UINavigationItemHeader>
                            )}
                            {!isPPTAddin && <LogOutButton />}
                        </UINavigation>
                    }
                    {this.renderSelectedPane()}
                </AnimatedFullScreenContainer>}
            </>
        );
    }
}

export default UserOptionsContainer;
