import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { ChromePicker } from "react-color";
import { Popover, Divider } from "@material-ui/core";

import { app } from "js/namespaces.js";
import { Gap10, Gap20 } from "js/react/components/Gap";
import { themeColors } from "js/react/sharedStyles";
import { _, $, tinycolor } from "js/vendor";
import { StylePresetOption } from "js/react/views/Editor/ColorPalettePopupMenu";
import { FlexBox } from "js/react/components/LayoutGrid";
import { PaletteColorType } from "common/constants";

const ColorPickerContainer = styled.div`
   display: grid;
   grid-auto-flow: column;
   grid-gap: 10px;
   align-items: center;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
   //padding: 0px 10px 0px 10px;
`;

export const ColorChitPopup = styled.button`
    height: 20px;
    width: 20px;
    border: solid 1px white;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    
    &.none {
        background: white;
        overflow: hidden;
        position: relative;
        border: none;
        &:after{
            content: "";
            position: absolute;
            top: calc(50% - 3px);
            left: -8px;
            width: calc(100% + 20px);
            height: 3px;
            background: ${themeColors.ui_blue};
            transform: rotate(-45deg);
            transform-origin: center center;
        }
    }
    
    &.auto {
      &:after {
        content: "A";
        width: 100%;
        height: 100%;
        color: black;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.slide {
        &:after {
          content: "S";
          width: 100%;
          height: 100%;
          color: black;
          font-size: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
    }

  &.dark {
    &:after {
      color: white;
    }
  }
`;

export class ColorPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            selectedColor: props.value,
            color: null
        };

        this.onChangeDebounced = _.debounce(props.onChange ?? (() => { }), 500);
    }

    componentDidMount() {
        window.addEventListener("keydown", this.enterPressed);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.enterPressed);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value && this.props.value !== this.state.selectedColor) {
            this.setState({ selectedColor: this.props.value });
        }
    }

    handleSelectChit = selectedColor => {
        const { onChange, showDecorationStyles } = this.props;

        this.setState({ selectedColor });

        if (onChange) {
            onChange(selectedColor);
        }

        if (!showDecorationStyles) {
            this.closePicker();
        }
    }

    handleSelectDecorationStyle = style => {
        const { onChangeDecorationStyle } = this.props;
        if (onChangeDecorationStyle) {
            onChangeDecorationStyle(style);
        }
    }

    handleSelectRawColor = (color, event) => {
        const { saveOnClose = false } = this.props;

        const eventTargetId = event.target.id;
        // In react-color, there is a bug, where it does not deselect the input (if it's selected),
        // and if in a case a user would like to see the color reflected in the input we need to blur it.
        if (!eventTargetId.contains("rc-editable-input")) {
            $(".chrome-picker [id*=rc-editable-input]").trigger("blur");
        }

        event.preventDefault();
        event.stopPropagation();

        if (event.nativeEvent) {
            event.nativeEvent.preventDefault();
            event.nativeEvent.stopImmediatePropagation();
        }

        const selectedColor = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;

        // In react-color there is a bug where the alpha value is not set correctly when the color is selected from the color picker.
        // Some explanation about it: https://github.com/casesandberg/react-color/issues/432
        // Basically when we open the picker, it is already set to be an rgba color, however when we select the text input of the picker,
        // it passes it as a rgba string which is not correct (This field accepts HEX values).
        // Here is the workaround for this issue:
        // If the alpha value is not 1, we assume it's an HEX.
        // If the alpha value is 1, we assume it's an RGBA.

        if (color.rgb.a !== 1) color = _.cloneDeep(color.rgb);
        else color = color.hex;

        this.setState({
            selectedColor,
            color
        });

        if (!saveOnClose) {
            this.onChangeDebounced(selectedColor);
        }
    };

    openPicker = event => {
        const { onOpenPicker, disabled } = this.props;
        if (disabled) return;
        onOpenPicker && this.props.onOpenPicker();
        this.setState({ anchorEl: event.currentTarget });
    };

    closePicker = (event, eventName) => {
        const { onChange, saveOnClose = false, onClosePicker } = this.props;
        const { color, selectedColor } = this.state;

        // We need to check if the color is not null,
        // because if the user doesn't select any color, we don't want to change the selected color.
        if (eventName === "backdropClick" && saveOnClose && onChange && color) {
            onChange(selectedColor);
        }
        this.setState({
            anchorEl: null,
            color: null
        });
        if (onClosePicker) onClosePicker();
    };

    enterPressed = event => {
        const { onChange, saveOnClose = false } = this.props;

        if (event.key === "Enter" && saveOnClose && onChange) {
            event.preventDefault();
            event.stopPropagation();
            this.closePicker(null, "backdropClick");
        }
    }

    render() {
        let { children, label, showNone, showAuto, accentColor, showSlideColor, slideColor, showTextColors, showChartColors, autoColor, position, showDecorationStyles, styles, showColorPicker, showChitOutline, customPaletteColors } = this.props;
        const { anchorEl, selectedColor, color } = this.state;

        const disableAlpha = "disableAlpha" in this.props && this.props.disableAlpha !== false;
        const disablePalette = "disablePalette" in this.props && this.props.disablePalette !== false;

        if (!(autoColor instanceof tinycolor)) {
            autoColor = tinycolor(autoColor);
        }
        if (!(slideColor instanceof tinycolor)) {
            slideColor = tinycolor(slideColor);
        }

        let paletteColors = {};
        if (!disablePalette) {
            if (customPaletteColors) {
                paletteColors = customPaletteColors;
            } else if (app.currentTheme?.palette) {
                if (showChartColors) {
                    paletteColors = app.currentTheme.palette.getChartColors();
                } else if (showTextColors) {
                    paletteColors = app.currentTheme.palette.getColors();
                } else {
                    paletteColors = app.currentTheme.palette.getBackgroundColors(true);
                }
            }
        }

        let previewClassName;
        let previewColor = selectedColor;
        if (selectedColor === "mixed") {
            previewColor = "transparent";
            previewClassName = "mixed";
        } else if (selectedColor == "slide") {
            previewColor = slideColor;
            previewClassName = "slide " + (slideColor?.isDark() ? "dark" : "");
        } else if (selectedColor === "auto" || selectedColor == null) {
            previewColor = autoColor;
            previewClassName = "auto " + (autoColor?.isDark() ? "dark" : "");
        } else if (selectedColor === "none" || selectedColor === "rgba(0, 0, 0, 0)") {
            previewClassName = "none";
        } else if (paletteColors[selectedColor]) {
            previewColor = paletteColors[selectedColor];
        }

        let popupPosition = {};
        if (position == "above") {
            popupPosition = {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                },
                transformOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                }
            };
        } else {
            popupPosition = {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            };
        }

        let chitStyle = {
            backgroundColor: previewColor
        };

        if (showChitOutline) {
            chitStyle.border = "solid 1px #ccc";
        }

        let chitCount = Object.keys(paletteColors).length;
        if (showSlideColor) chitCount++;
        if (showAuto) chitCount++;
        if (showNone) chitCount++;

        let selectedPreviewColor = tinycolor(previewColor).toHex();
        if (tinycolor(previewColor).getAlpha() < 1) {
            selectedPreviewColor = tinycolor(previewColor).toRgb();
        }

        return (
            <ColorPickerContainer style={styles}>
                {label && <label>{label}</label>}
                <ColorChitPopup
                    onClick={this.openPicker}
                    style={chitStyle}
                    className={previewClassName}
                />

                <Popover
                    className="ui-popover"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    disableEnforceFocus
                    {...popupPosition}
                    onClose={this.closePicker}
                >
                    <PopoverContainer>
                        {children}
                        {children && <Divider />}
                        {children && <Gap20 />}
                        <ColorChitContainer style={{ gridTemplateColumns: `repeat(${Math.min(12, chitCount)}, 30px)` }}>
                            {showSlideColor &&
                                <CircleColorChitSlide
                                    onClick={() => this.handleSelectChit("slide")}
                                    color={slideColor}
                                    className={slideColor?.isDark() ? "dark" : "light"}
                                />
                            }
                            {showAuto &&
                                <CircleColorChitAuto
                                    onClick={() => this.handleSelectChit("auto")} color={autoColor}
                                    className={autoColor?.isDark() ? "dark" : "light"}
                                />
                            }
                            {accentColor &&
                                <CircleColorChitAuto
                                    onClick={() => this.handleSelectChit("auto")} color={accentColor}
                                    className={accentColor?.isDark() ? "dark" : "light"}
                                />
                            }
                            {showNone &&
                                <CircleColorChitNone
                                    onClick={() => this.handleSelectChit("none")}
                                />
                            }
                            {Object.entries(paletteColors).map(([colorId, color]) => (
                                <CircleColorChit
                                    key={colorId}
                                    color={color}
                                    onClick={() => this.handleSelectChit(colorId)}
                                />)
                            )}
                        </ColorChitContainer>
                        {showDecorationStyles &&
                            <>
                                <Gap20 />
                                <FlexBox left style={{ padding: "15px 0px" }}>
                                    <StylePresetOption type="outlined" color={selectedColor} label="Outlined"
                                        onClick={() => this.handleSelectDecorationStyle("outlined")} />
                                    <StylePresetOption type="muted" color={selectedColor} label="Muted"
                                        onClick={() => this.handleSelectDecorationStyle("muted")} />
                                    <StylePresetOption type="filled" color={selectedColor} label="Filled"
                                        onClick={() => this.handleSelectDecorationStyle("filled")} />
                                    <StylePresetOption type="fillAndStroke" color={selectedColor} label="Fill & Stroke"
                                        onClick={() => this.handleSelectDecorationStyle("fillAndStroke")} />
                                </FlexBox>
                            </>
                        }

                        {showColorPicker &&
                            <div onKeyDown={event => {
                                event.stopPropagation();
                            }}>
                                <Gap20 />
                                <ChromePicker
                                    disableAlpha={disableAlpha}
                                    color={color ?? selectedPreviewColor}
                                    style={{ boxShadow: "none" }}
                                    onChange={this.handleSelectRawColor}
                                />
                            </div>
                        }

                    </PopoverContainer>
                </Popover>
            </ColorPickerContainer>
        );
    }
}

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  .chrome-picker {
    box-shadow: none !important;
    width: 275px !important;
    height: 250px;
  } 
  label {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
  }
`;

const ColorChitContainer = styled.div`
  display: grid;                   
  gap: 5px;
  grid-template-columns: repeat(8, 30px);
`;

const CircleColorChit = styled.button.attrs(({ color }) => ({
    style: {
        backgroundColor: color
    }
}))`
    height: 30px;
    width: 30px;
    border: solid 1px #bbb;
    border-radius: 50%;
`;

const CircleColorChitNone = styled.button`
    height: 30px;
    width: 30px;
    border: solid 1px #bbb;
    border-radius: 50%;
    background: white;
    overflow: hidden;
    position: relative;
    &:after{
       content: "";
       position: absolute;
       top: calc(50% - 3px);
       left: -10px;
       width: calc(100% + 20px);
       height: 4px;
       background: black;
       transform: rotate(-45deg);
       transform-origin: center center;
    }
`;

const CircleColorChitAuto = styled.button.attrs(({ color }) => ({
    style: {
        backgroundColor: color
    }
}))`
    height: 30px;
    width: 30px;
    border: solid 1px #bbb;
    border-radius: 50%;
    background: white;
    overflow: hidden;
    position: relative;
    &:after{
       content: "AUTO";
       position: absolute;
       top: 0px;
       left: 0px;
       width: 100%;
       height: 100%;
       display: flex;
       align-items: center;
       justify-content: center;
       font-size: 7px;
    }
`;

const CircleColorChitSlide = styled.button.attrs(({ color }) => ({
    style: {
        backgroundColor: color
    }
}))`
    height: 30px;
    width: 30px;
    border: solid 1px #bbb;
    border-radius: 50%;
    background: white;
    overflow: hidden;
    position: relative;
    &:after{
       content: "SLIDE";
       position: absolute;
       top: 0px;
       left: 0px;
       width: 100%;
       height: 100%;
       display: flex;
       align-items: center;
       justify-content: center;
       font-size: 7px;
        color: black;
    }
  
    &.dark {
      &:after {
        color: white;
      }
    }
`;
