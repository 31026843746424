import React, { Component } from "react";
import { _ } from "../vendor";

export class Box extends Component {
    ref = React.createRef();

    get defaultStyles() {
        return {};
    }

    getStyles() {
        const styles = { ...this.props.style };

        if (this.props.fillWidth || this.props.fill) {
            styles.width = "100%";
        }

        if (this.props.fillHeight || this.props.fill) {
            styles.height = "100%";
        }

        if (this.props.width) {
            styles.width = this.props.width;
        }

        if (this.props.height) {
            styles.height = this.props.height;
        }

        if (this.props.center) {
            styles.alignItems = "center";
            styles.justifyContent = "center";
        }

        if (this.props.padding) {
            styles.padding = this.props.padding;
        }

        if (this.props.scale) {
            styles.transform = `scale(${this.props.scale})`;
        }

        return styles;
    }

    render() {
        const {
            id,
            style,
            children,
        } = this.props;

        const styles = this.getStyles();

        const componentProps = _.omit(this.props, [
            "fill",
            "fillWidth",
            "fillHeight",
            "width",
            "height",
            "column",
            "vertical",
            "center",
            "wrap",
            "horizontalAlign",
            "verticalAlign",
            "left",
            "right",
            "top",
            "bottom",
            "center",
            "middle",
            "rows",
            "columns",
            "template",
            "between",
        ]);

        return (
            <div id={id}
                key={id}
                {...componentProps}
                style={{
                    ...styles,
                    ...style,
                }}
                ref={this.ref}
            >
                {children}
            </div>
        );
    }
}

export class BorderBox extends Box {
    getStyles() {
        let styles = super.getStyles();

        styles.border = "1px solid #ccc";
        styles.borderRight = "none";
        styles.borderBottomColor = "#666";
        styles.display = "flex";
        styles.alignItems = "center";
        styles.justifyContent = "center";

        return styles;
    }
}
