import React, { Component } from "react";
import styled from "styled-components";
import { FlexSpacer, Gap20, Gap30 } from "js/react/components/Gap";
import { UserMenu } from "js/editor/components/UserMenu";
import { Icon, MenuItem } from "@material-ui/core";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { ShareDialog } from "js/react/views/PresentationSettings/dialogs/ShareDialog";
import { ds } from "js/core/models/dataService";
import { PopupMenu } from "js/react/components/PopupMenu";
import AppController from "js/core/AppController";

const AppMenuBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  width: 100%;
  height: 50px;
  padding: 0px 10px;
  color: white;
`;

const ShareButton = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  span {
    margin-right: 10px;
  }
`;

export class DocumentMenuBar extends Component {
    handleShare = () => {
        ShowDialog(ShareDialog, { presentation: ds.selection.presentation });
    }

    render() {
        return (
            <AppMenuBarContainer>
                <DocumentMenu/>
                <FlexSpacer />

                <ShareButton onClick={this.handleShare}>
                    <Icon>share</Icon>
                    Share
                </ShareButton>
                <Gap30 />
                <UserMenu />
            </AppMenuBarContainer>
        );
    }
}

export function DocumentMenu() {
    return (
        <PopupMenu icon="menu">
            <MenuItem onClick={AppController.showLibrary}><Icon>list</Icon>Return to Library</MenuItem>
        </PopupMenu>
    );
}

