import styled from "styled-components";

import { ScrollBox } from "../react/components/LayoutGrid";

export const StyledScrollBox = styled(ScrollBox).attrs(({ verticalPadding = 0 }) => ({
    style: {
        marginBottom: `${verticalPadding}px`
    }
}))`
    width: 100%;
    height: calc(100vh - 105px);
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background: #11a9e2;
    }
`;
