import React, { Component } from "reactn";
import { app } from "js/namespaces";
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    DialogTitle
} from "@material-ui/core";
import {
    ShowDialog,
    ShowErrorDialog
} from "js/react/components/Dialogs/BaseDialog";
import { ReauthPasswordDialog } from "js/react/views/UserOptions/dialogs/ReauthPasswordDialog";

export default class VerifyEmailDialog extends Component {
    constructor(props) {
        super(props);
        const authUser = app.user.getAuthUser();
        this.state = {
            email: authUser.email
        };
    }

    handleEmailChange = event => {
        this.setState({ email: event.target.value });
    };

    showDialog = (dialogData, props) => {
        const dialogProps = {
            maxWidth: "md",
            fullWidth: true
        };
        this.setState({
            dialog: {
                title: dialogData.title,
                body: dialogData.body,
                props: dialogProps
            }
        });
    };

    closeDialog = complete => {
        this.props.closeDialog();
        if (this.props.callback) {
            this.props.callback(complete);
        }
    };

    updateEmail = async () => {
        const { email } = this.state;

        app.user
            .updateEmailAddress(email)
            .then(() => {
                this.closeDialog(true);
            })
            .catch(err => {
                switch (err.code) {
                    case "auth/requires-recent-login":
                        ShowDialog(ReauthPasswordDialog, {
                            callback: success => {
                                this.updateEmail();
                            }
                        });
                        break;
                    case "auth/invalid-email":
                    case "auth/email-already-in-use":
                    default:
                        ShowErrorDialog({
                            error: "Error sending verification email.",
                            message: err.message
                        });
                        break;
                }
            });
    };

    render() {
        const { email } = this.state;

        return (
            <Dialog open={true} onClose={() => this.closeDialog()}>
                <DialogTitle>
                    For security purposes, we need to make sure that you
                    signed up with a valid email.
                </DialogTitle>
                <DialogContent className="dialog-content-text">
                    <h3>Your current email is</h3>
                    <TextField
                        value={email}
                        onChange={this.handleEmailChange}
                        helperText="If this email is incorrect, you can update it now."
                        variant="outlined"
                        fullWidth={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.updateEmail} color="primary">
                        Send Verification Email
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
