import _ from "lodash";
import pixelmatch from "pixelmatch";
import React, { Component } from "react";
import styled from "styled-components";
import { themes as builtInThemes, THEME_DEFAULTS } from "/common/themes";
import { app } from "js/namespaces";
import { Dropdown } from "js/Components/Dropdown";
import { IconButton } from "js/Components/IconButton";
import { MenuItem } from "js/Components/Menu";
import { Slider } from "js/Components/Slider";
import { WithLabel } from "js/Components/WithLabel";
import { FlexSpacer } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import Spinner from "js/react/components/Spinner";
import { PropertyPanelContainer, PropertySection, SectionDescription, SectionTitle } from "../../EditorComponents/PropertyPanel";
import PresentationEditorController from "../PresentationEditor/PresentationEditorController";
import { PropertyPanelHeader } from "./PropertyPanelHeader";
import { stopPropagation } from "js/core/utilities/stopPropagation";
import { downloadFromBlob } from "js/core/utilities/utilities";
import { debug as debugApi } from "apis/callables";
import DebugController from "js/core/DebugController";

class CanvasImage extends Component {
    constructor() {
        super();
        this.canvasRef = React.createRef();
    }

    componentDidMount() {
        const { src, handleImageData = ()=>{} } = this.props;

        const canvas = this.canvasRef.current;
        const context = canvas.getContext("2d");

        const image = new Image();
        image.crossOrigin = "Anonymous";
        image.src = src;

        image.onload = function() {
            const scale = Math.min(canvas.width / image.width, canvas.height / image.height);
            context.drawImage(image, 0, 0, image.width * scale, image.height * scale);

            const imageData = context.getImageData(0, 0, image.width, image.height);
            handleImageData(imageData);
        };
    }

    render() {
        const { width, height } = this.props;
        return (
            <canvas ref={this.canvasRef} width={width} height={height}/>
        );
    }
}

const ThumbnailImg = styled.img`
    width: 250px;
    max-width: 250px;
    height: 140px;
    max-height: 140px;
`;

class DebugScreenshotsSection extends Component {
    constructor() {
        super();
        this.themes = Object.values(builtInThemes).map(theme => ({ ...THEME_DEFAULTS, ...theme }));
        this.state = {
            slideId: PresentationEditorController.currentSlide.id,
            template: PresentationEditorController.currentSlide.attributes.template_id,
            theme: null,                  // Actual theme loaded from the current slide
            previewTheme: this.themes[0], // Initial dropdown value used to preview slides
            referenceTheme: null,          // Chosen theme after viewing slides
            referenceScreenshot: null,
            slideScreenshot: null,
            slideImageData: null,
            referenceImageData: null,
            diffImageData: null,
            threshold: 0.1
        };
    }

    async load() {
        const referenceScreenshots = app.debugController.referenceScreenshots;
        let { slideId, theme, template, slideScreenshot } = this.state;

        if (slideId) {
            if (!theme) {
                theme = await app.themeManager.loadTheme(PresentationEditorController.presentation);
                this.setState({ theme });
            }

            if (!app.debugController.referenceScreenshotsFetching() &&
                (_.isEmpty(referenceScreenshots.filter(s=> s.template == template)) ||
                    app.debugController.referenceScreenshotsExpired())) {
                await app.debugController.fetchReferenceScreenshots(slideId);
            }

            if (!slideScreenshot) {
                const screenshot = await debugApi.createScreenshot({ id: slideId, theme: theme });
                this.setState({ slideScreenshot: screenshot });
            }
        }
    }

    componentDidMount() {
        this.setState({ slideId: PresentationEditorController.currentSlide.id });
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { slideId, slideImageData, referenceImageData, diffImageData, threshold } = this.state;
        if (slideId !== PresentationEditorController.currentSlide.id) {
            this.setState({ slideId: PresentationEditorController.currentSlide.id });
            this.load();
        }

        if ((slideImageData && referenceImageData)) {
            if (slideImageData != prevState.slideImageData ||
                referenceImageData != prevState.referenceImageData ||
                threshold != prevState.threshold) {
                this.compareSlides();
            }
        }

        if (diffImageData && diffImageData != prevState.diffImageData) {
            this.combineSlideImages();
        }

        if (app.debugController.referenceScreenshotsExpired()) {
            this.load();
        }
    }

    combineSlideImages() {
        const { slideScreenshot, referenceScreenshot, slideImageData, referenceImageData, diffImageData } = this.state;
        const { width, height } = slideImageData;

        const ctx = document.getElementById("combined-diff").getContext("2d");
        const verticalGap = 100;
        const textGap = 70;

        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, width, 3 * height);

        ctx.putImageData(slideImageData, 0, 2 * verticalGap);
        ctx.putImageData(referenceImageData, 0, height + 3 * verticalGap);
        ctx.putImageData(diffImageData, 0, 2 * height + 4 * verticalGap);

        ctx.font = "24pt Source Sans Pro";
        ctx.fillStyle = "white";

        ctx.fillText(`Presentation: ${PresentationEditorController.presentation.id}`, 10, textGap);
        ctx.fillText(`Current Slide: ${slideScreenshot.exportFileId}`, 10, verticalGap + textGap);
        ctx.fillText(`Reference Slide: ${referenceScreenshot.fileName}`, 10, height + 2 * verticalGap + textGap);
        ctx.fillText(`Difference`, 10, (2 * height) + (3 * verticalGap) + textGap);
    }

    compareSlides() {
        const { slideImageData, referenceImageData, threshold } = this.state;
        const { width, height } = slideImageData;

        const diffEl = document.getElementById("screenshot-diff");
        const diffContext = diffEl.getContext("2d");
        const diff = diffContext.createImageData(width, height);

        pixelmatch(slideImageData.data, referenceImageData.data, diff.data, width, height, { threshold });
        diffContext.putImageData(diff, 0, 0);
        const diffImageData = diffContext.getImageData(0, 0, width, height);
        this.setState({ diffImageData: diffImageData });
    }

    selectSlide(s) {
        const { referenceScreenshot, previewTheme } = this.state;
        if (!referenceScreenshot && previewTheme == this.themes[0]) {
            this.setState({ referenceScreenshot: s, diffImageData: null });
        } else {
            this.setState({ referenceScreenshot: s, referenceTheme: s.theme, diffImageData: null });
        }
    }

    render() {
        const { slideScreenshot, previewTheme, referenceScreenshot, referenceTheme, theme, template, diffImageData, threshold } = this.state;
        const referenceScreenshots = app.debugController?.referenceScreenshots.filter(s=> s.template == template);

        const slidesToDisplay = referenceScreenshot
            ? referenceScreenshots.filter(s=> referenceTheme
                ? (s.slide == referenceScreenshot.slide && s.theme == referenceTheme)
                : s.slide == referenceScreenshot.slide)
            : previewTheme
                ? referenceScreenshots.filter(s=>s.theme == previewTheme.id)
                : referenceScreenshots.filter(s=>s.theme == theme.id);

        return (
            <div style={{ width: "100%" }}>
                <PropertySection>
                    <SectionTitle>Current Slide Image</SectionTitle>
                    {slideScreenshot &&
                        <SectionDescription>
                            {`MigrationVersion: ${slideScreenshot?.migrationVersion} `}
                            {slideScreenshot?.isLegacyRenderer ? `(Legacy Renderer)` : `(Current Renderer)`}

                        </SectionDescription>}
                    {!slideScreenshot &&
                        <div style={{ width: "100%", height: "140px" }}><Spinner /></div>
                    }
                    {slideScreenshot &&
                        <CanvasImage src={slideScreenshot.url}
                            handleImageData={imageData=> this.setState({ slideImageData: imageData })}
                            width="1920"
                            height="1080"/>}
                </PropertySection>
                <PropertySection>
                    <SectionTitle>Reference Images</SectionTitle>
                    {!referenceScreenshot &&
                        <WithLabel label="Theme">
                            <Dropdown value={previewTheme?.id}
                                onChange={value => {
                                    const newTheme = this.themes.find(theme => {
                                        return (theme.id == value);
                                    });
                                    return this.setState({ previewTheme: newTheme });
                                }}>
                                {this.themes.map(t => <MenuItem value={t.id} key={t.id}>{t.name}</MenuItem>)}
                            </Dropdown>
                        </WithLabel>}
                    {referenceTheme &&
                        <WithLabel label="Selected Theme">
                            <FlexBox className="controls" right gap={5}>
                                {referenceTheme}
                                <IconButton small color="#333" icon="clear"
                                    onClick={() => this.setState({ referenceTheme: null, previewTheme: this.themes[0], diffImageData: null })}/>
                            </FlexBox>

                        </WithLabel>}
                    {referenceScreenshot &&
                        <WithLabel label="Selected Slide">
                            <FlexBox className="controls" right gap={5}>
                                {referenceScreenshot.slide}
                                <IconButton small color="#333" icon="clear"
                                    onClick={() => this.setState({ referenceScreenshot: null, diffImageData: null })}/>
                            </FlexBox>

                        </WithLabel>}
                    {referenceScreenshot && referenceTheme &&
                        <CanvasImage src={referenceScreenshot.url}
                            handleImageData={imageData => this.setState({ referenceImageData: imageData })}
                            width="1920"
                            height="1080"/>
                    }
                    {referenceScreenshot && referenceTheme &&
                        <>
                            <SectionTitle>Diff</SectionTitle>
                            <WithLabel label="Threshold">
                                <Slider min={0} max={1} step={0.1} value={threshold}
                                    onChange={value => this.setState({ threshold: value })}
                                    showInput
                                />
                            </WithLabel>
                            <canvas id="screenshot-diff"
                                width="1920"
                                height="1080"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    document.getElementById("combined-diff").toBlob(blob=>
                                        downloadFromBlob(blob, `screenshot-diff.jpeg`, "image/jpeg"),
                                    "image/jpeg",
                                    0.95);
                                }}/>

                        </>
                    }
                    {referenceScreenshot && referenceTheme &&
                        <canvas id="combined-diff"
                            width="1920"
                            height="3640"
                            style={{ cursor: "pointer", display: "none" }}
                        />

                    }
                    {referenceScreenshots && !diffImageData &&
                        <div>
                            {slidesToDisplay?.map(s => {
                                return (
                                    <div key={s.fileName}>
                                        {referenceScreenshot && !referenceTheme && s.theme}
                                        <ThumbnailImg
                                            id={`${s.fileName}-screenshot`}
                                            src={s.url}
                                            onClick={() => this.selectSlide(s)}
                                        />
                                    </div>);
                            })}
                        </div>}
                </PropertySection>
            </div>
        );
    }
}

class DebugPanel extends Component {
    render() {
        return (
            <PropertyPanelContainer onMouseDown={stopPropagation()}>
                <PropertyPanelHeader>Debug Slide</PropertyPanelHeader>
                <DebugScreenshotsSection {...this.props}/>
                <FlexSpacer/>
            </PropertyPanelContainer>
        );
    }
}

export default PresentationEditorController.withState(
    DebugController.withState(
        DebugPanel
    ));
