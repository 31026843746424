import styled from "styled-components";
import { themeColors } from "js/react/sharedStyles";

export const IconButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ddd;
  cursor: pointer;
  transition: 250ms;
  position: relative;
  opacity: ${props => props.disabled ? 0.25 : 1};
  pointer-events: ${props => props.disabled ? "none" : "auto"};
  label {                                                   
    color: #ddd;
    opacity: 0;
    text-transform: uppercase;
    text-align: center;
    white-space: normal;
    font-size: 9px;
    transition: 250ms;
    margin-top: 2px;
    letter-spacing: .75px;
  }

  .material-icons {
    font-size: 24px;
    filter: drop-shadow(0px 7px 10px rgba(0,0,0,.4));
  }

  &:hover {
    color: ${themeColors.ui_blue};
    label {
      opacity: 1;
    }
  }
`;

export const SideBar = styled.div`
  background: #333;
  width: 70px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${props => `${props.gap ?? 20}px`};
  align-items: center;
  padding-top: ${props => `${props.top ?? 30}px`};
  padding-bottom: ${props => `${props.botton ?? 10}px`};

  @media (max-height: 650px) {
    gap: 10px;
    padding-top: 15px;
  }
  
  @media (max-height: 540px) {
    gap: 5px;
  }
`;

