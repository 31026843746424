import React, { Component } from "react";
import styled from "styled-components";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { tinycolor } from "js/vendor";
import { ColorPicker } from "js/Components/ColorPicker";
import { Divider } from "js/Components/Divider";
import { Gap20, Gap30 } from "js/react/components/Gap";
import { WarningButton } from "js/react/components/UiComponents";
import { GradientEditorRow } from "./GradientEditorRow";
import { GradientStop } from "./GradientStop";
import ThemeEditorController from "js/editor/ThemeEditor/ThemeEditorController";

const GradientSliderContainer = styled.div`
    width: 100%;
    min-width: 300px;
    position: relative;
`;

const GradientSliderBar = styled.div`
    width: 100%;
    height: 12px;
    background: linear-gradient(90deg, ${props => props.stops.map(stop => `${stop.color} ${stop.position}%`).join(", ")});
    cursor: crosshair;
`;

const GradientSliderStop = styled.div.attrs(({ position }) => ({
    style: { left: `${position}%` }
}))`
    position: absolute;
    transform: translateX(-50%);
    top: -4px;
`;

const GradientEditorRows = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export class GradientEditorSlider extends Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();

        this.state = {
            isDragging: false,
        };
    }

    handleMouseDown = (event, stop) => {
        this.dragStop = stop;
        this.mouseX = event.screenX;
        this.dragStartPosition = stop.position;
        this.stepSize = this.ref.current.getBoundingClientRect().width / 100;

        window.addEventListener("mousemove", this.handleMouseMove);
        window.addEventListener("mouseup", this.handleMouseUp);
    }

    handleMouseMove = event => {
        const positionDiff = Math.round((event.screenX - this.mouseX) / this.stepSize);

        // don't start dragging until mouse has moved more than 1 stepSize
        if (Math.abs(positionDiff) < 1) {
            return;
        }

        this.setState({ isDragging: true });

        const newStops = [...this.props.stops];
        this.dragStop.position = Math.clamp(this.dragStartPosition + positionDiff, 0, 100);

        //sort stops by position
        newStops.sort((a, b) => a.position - b.position);

        this.props.onChange(newStops);
    }

    handleMouseUp = event => {
        event.preventDefault();

        window.removeEventListener("mousemove", this.handleMouseMove);
        window.removeEventListener("mouseup", this.handleMouseUp);

        setTimeout(() => {
            this.setState({ isDragging: false });
        }, 0);
    }

    handleAddStop = event => {
        if (this.props.stops.length >= 10) {
            ShowErrorDialog({ title: "Sorry, you can only have up to 10 stops in a gradient." });
            return;
        }

        let bounds = this.ref.current.getBoundingClientRect();

        let position = Math.clamp((event.clientX - bounds.left) / bounds.width, 0, 1);

        const newStops = [...this.props.stops];

        //interpolate color from previous and next stops
        let prevStop = newStops.toReversed().find(stop => stop.position < (position * 100));
        let nextStop = newStops.find(stop => stop.position > (position * 100));

        let color = tinycolor("#000000");

        if (prevStop && nextStop) {
            let prevColor = prevStop.color.toRgb();
            let nextColor = nextStop.color.toRgb();
            let prevPosition = prevStop.position / 100;
            let nextPosition = nextStop.position / 100;
            let t = (position - prevPosition) / (nextPosition - prevPosition);
            color = tinycolor({
                r: Math.round(prevColor.r + (nextColor.r - prevColor.r) * t),
                g: Math.round(prevColor.g + (nextColor.g - prevColor.g) * t),
                b: Math.round(prevColor.b + (nextColor.b - prevColor.b) * t),
            });
        }

        newStops.push(new GradientStop(Math.round(position * 100), color));
        newStops.sort((a, b) => a.position - b.position);

        this.props.onChange(newStops);
    }

    handleChangeStop = (newStop, index)=>{
        const newStops = [...this.props.stops];
        newStops[index] = newStop;
        newStops.sort((a, b) => a.position - b.position);
        // const newIndex = newStops.indexOf(newStop);
        // if (index !== newIndex) {
        //     newStops[index].oldIndex = newIndex;
        //     newStops[newIndex].oldIndex = index;
        // }
        this.props.onChange(newStops);
    }

    render() {
        const { stops, onChange } = this.props;
        const { isDragging } = this.state;

        const customPaletteColors = ThemeEditorController.gradientPaletteColors();

        return (
            <GradientSliderContainer
                ref={this.ref}>
                <GradientSliderBar
                    stops={stops}
                    onClick={this.handleAddStop} />
                {stops.map((stop, index) => (
                    <GradientSliderStop
                        key={index}
                        position={stop.position}
                        onMouseDown={event => this.handleMouseDown(event, stop)}
                    >
                        <ColorPicker
                            value={stop.color}
                            onChange={color => {
                                const newStop = new GradientStop(stop.position, color);
                                this.handleChangeStop(newStop, index);
                            }}
                            showColorPicker
                            disableAlpha
                            customPaletteColors={customPaletteColors}
                            disabled={isDragging}
                            bottomControls={
                                closePopup => {
                                    return stops.length > 2 ? (
                                        <div>
                                            <Divider />
                                            <Gap20/>
                                            <WarningButton
                                                style={{ width: "100%" }}
                                                size="small"
                                                onClick={() => {
                                                    const newStops = [...stops];
                                                    newStops.splice(index, 1);
                                                    onChange(newStops);
                                                    closePopup();
                                                }} >
                                                Delete Stop
                                            </WarningButton>
                                        </div>
                                    ) : null;
                                } }
                        />
                    </GradientSliderStop>
                ))}
                <Gap30 />

                <GradientEditorRows>
                    {stops.map(stop => (
                        <GradientEditorRow
                            key={stop.id}
                            stops={stops}
                            stop={stop}
                            onChange={stop =>{
                                const newStops = [...stops];
                                const index = newStops.indexOf(stop);
                                newStops[index] = stop;
                                newStops.sort((a, b) => a.position - b.position);
                                onChange(newStops);
                            }}
                            onDelete={stop => {
                                const newStops = [...stops];
                                const index = newStops.indexOf(stop);
                                newStops.splice(index, 1);
                                onChange(newStops);
                            }}
                            disabled={isDragging}
                        />
                    ))}
                </GradientEditorRows>

            </GradientSliderContainer>
        );
    }
}
