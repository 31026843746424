import React, { Component } from "reactn";
import { app } from "js/namespaces";
import styled from "styled-components";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";

import { getStaticUrl } from "js/config";
import DialogContent from "./DialogContent";
import BeautifulDialog from "./BeautifulDialog";

import { ShowUpgradeDialog } from "js/react/components/Dialogs/BaseDialog";

import ProBadge from "js/react/components/ProBadge";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";

import { handleEditSharedSlide } from "js/editor/editShareSlide";
import { FeatureType } from "common/features";
import AppController from "../../../core/AppController";
import PresentationEditorController from "../../../editor/PresentationEditor/PresentationEditorController";

const SadRobotIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
    width: 100%;

    img {
        height: 55px;
    }
`;

const Title = styled(DialogTitle)`
    &&& {
        padding: 20px 34px 20px;
        text-align: center;
    }
`;

const Content = styled(DialogContent)`
    &&& {
        text-align: center;

        p {
            margin-bottom: 20px;

            &.bold {
                font-weight: 700;
            }

            &:last-child{
                margin-bottom: 0;
            }
        }
    }
`;

const Actions = styled(DialogActions)`
    &&& {
        display: flex;
        justify-content: space-between;
        margin: 0 12px 12px;
        padding-top: 0;
    }
`;

const OkButton = styled(Button)`
    &&& {
        color: #11A9E2;
    }
`;

export default class BadFitDialog extends Component {
    render() {
        const {
            error,
            title,
            message = "Try adjusting your text size or layout — or break the design AI and edit freely by converting your Smart Slide to Classic.",
            acceptCallback,
            acceptOnClose = true,
            closeDialog,
        } = this.props;

        const workspaceId = AppController.workspaceId;
        const presentation = PresentationEditorController.presentation;
        const canConvert = (
            app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_CONVERT_TO_CLASSIC, workspaceId) &&
            app.currentCanvas.slideTemplate.canConvertToAuthoring
        );
        const showProBadge = !app.user.features.isFeatureEnabled(FeatureType.CONVERT_TO_CLASSIC, workspaceId);

        return (
            <BeautifulDialog
                classes={{ paper: "error-dialog" }}
                closeDialog={() => {
                    if (acceptOnClose) {
                        acceptCallback && acceptCallback();
                    }
                    closeDialog();
                }}
            >
                <SadRobotIconContainer>
                    <img src={getStaticUrl("/images/bai-bot/bai-bot-sad.png")}></img>
                </SadRobotIconContainer>
                <Title>{error || title}</Title>
                <Content>
                    {message instanceof Array && message.map(message => <p>{message}</p>)}
                    {message instanceof Error && message.message}
                    {message && message.type === React.Fragment && message}
                    {typeof message === "string" && message}
                </Content>
                <Actions>
                    <Button
                        disabled={!canConvert}
                        onClick={() => {
                            if (showProBadge) {
                                ShowUpgradeDialog({
                                    type: UpgradePlanDialogType.UPGRADE_PLAN,
                                    analytics: { cta: "ConvertToAuthoring", ...presentation.getAnalytics() },
                                    workspaceId,
                                });

                                closeDialog();
                                return;
                            }

                            const closeAll = () => {
                                if (app.mainView.editorView.sideBar?.type === "add-content-panel") {
                                    app.mainView.editorView.hideSideBar(true);
                                }

                                closeDialog();
                            };

                            const isReadOnly = (app.currentCanvas.slide.isLibrarySlide() && app.mainView.editorView.$lockedSlideShield);
                            if (isReadOnly) {
                                handleEditSharedSlide()
                                    .then(canEdit => {
                                        if (canEdit) {
                                            app.currentCanvas.convertToClassic();

                                            closeAll();
                                        }
                                    });
                            } else {
                                app.currentCanvas.convertToClassic();

                                closeAll();
                            }
                        }}
                    >
                        <ProBadge
                            upgradeType={UpgradePlanDialogType.UPGRADE_PLAN}
                            show={showProBadge}
                            analytics={{ cta: "convertToAuthoring", ...presentation.getAnalytics() }}
                            workspaceId={workspaceId}
                        />
                        <span>Convert to Classic</span>
                    </Button>
                    <OkButton
                        onClick={() => {
                            acceptCallback && acceptCallback();
                            closeDialog();
                        }}
                    >
                        Got It
                    </OkButton>
                </Actions>
            </BeautifulDialog>
        );
    }
}
