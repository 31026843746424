import React, { useEffect } from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { useDrop } from "react-dnd";

import { AuthoringElementType, CalloutType } from "common/constants";
import { Convert } from "js/core/utilities/geom";
import { trackActivity } from "js/core/utilities/utilities";
import { _ } from "js/vendor";

export const addCalloutToContainer = (container, value, pt) => {
    let currentCanvas = container.canvas;
    const selectionLayerController = currentCanvas.selectionLayerController;

    const props = {
        "slide_id": currentCanvas.dataModel.id,
        "template_id": currentCanvas.slideTemplate.constructor.id,
        "template_title": currentCanvas.slideTemplate.constructor.title,
        "callout_type": value
    };
    trackActivity("Slide", "CalloutAdded", null, null, props);

    if (value === CalloutType.LINE || value === CalloutType.DASHED_LINE || value === CalloutType.LINE_ARROW) {
        const connectorTypes = connectorType => {
            const connectorTypes = {
                connectorType: "straight"
            };
            switch (connectorType) {
                case CalloutType.DASHED_LINE:
                    return {
                        ...connectorTypes,
                        lineStyle: "dashed"
                    };
                case CalloutType.LINE_ARROW:
                    return {
                        ...connectorTypes,
                        endDecoration: "arrow"
                    };
                case CalloutType.LINE:
                default:
                    return {
                        ...connectorTypes
                    };
            }
        };

        const connector = container.connectors.addItem({
            sourcePoint: pt,
            targetPoint: pt.offset(300, 0),
            ...connectorTypes(value)
        });
        container.authoringLayer.handleMouseUp();  /// ????
        currentCanvas.updateCanvasModel().then(() => {
            selectionLayerController.setSelectedElements([container.connectors.getItemElementById(connector.id)]);
        });
    } else {
        const calloutDefaultModel = container.getDefaultModel(AuthoringElementType.CALLOUT, { calloutType: value });

        const callout = container.addItem({
            ...calloutDefaultModel,
            x: Math.round(pt.x), y: Math.round(pt.y),
            name: `Callout #${container.itemElements.length + 1}`
        });

        container.authoringLayer.handleMouseUp();
        currentCanvas.updateCanvasModel().then(() => {
            // We want to avoid the mouse up event from being triggered on the canvas
            const element = container.getItemElementById(callout.id);
            selectionLayerController.setSelectedElements([element]);
        });
    }
};

const DropZoneContainer = styled.div`
    width: 100%;
    height: 100%;
`;

let dropZoneContainerElement = null;

export const AddElementDropZone = ({ currentCanvasController }) => {
    const currentCanvas = currentCanvasController.canvas;

    let container;
    if (currentCanvas.dataModel.get("template_id") === "flow_chart") {
        // special case for flow chart because we need to drop the annotations into the flow chart primary element
        container = currentCanvas.getPrimaryElement();
    } else {
        container = currentCanvas.layouter.elements.callouts;
    }

    const [, drop] = useDrop({
        accept: "elements",
        drop: ({ value, onClose }, monitor) => {
            const didDrop = monitor.didDrop();
            if (didDrop) {
                return;
            }

            const clientOffset = monitor.getClientOffset();

            const pt = Convert.ScreenToElementCoordinates(currentCanvas, container, clientOffset.x, clientOffset.y);

            if (pt.x < 0 || pt.x > container.bounds.width || pt.y < 0 || pt.y > container.bounds.height) {
                return;
            }

            addCalloutToContainer(container, value, pt);
            onClose && onClose();
        },

        collect: monitor => {
            return {
                isOver: monitor.isOver(),
            };
        },
    });

    useEffect(() => {
        return () => {
            if (dropZoneContainerElement) {
                dropZoneContainerElement.remove();
                dropZoneContainerElement = null;
            }
        };
    }, []);

    if (!dropZoneContainerElement) {
        dropZoneContainerElement = document.createElement("div");
        dropZoneContainerElement.style.setProperty("position", "absolute");
        dropZoneContainerElement.style.setProperty("z-index", "2000");
        document.body.appendChild(dropZoneContainerElement);
    }

    const canvasScreenBounds = currentCanvasController.canvasScreenBounds;
    dropZoneContainerElement.style.setProperty("top", `${canvasScreenBounds.top}px`);
    dropZoneContainerElement.style.setProperty("left", `${canvasScreenBounds.left}px`);
    dropZoneContainerElement.style.setProperty("width", `${canvasScreenBounds.width}px`);
    dropZoneContainerElement.style.setProperty("height", `${canvasScreenBounds.height}px`);

    return (ReactDOM.createPortal(<DropZoneContainer ref={drop}></DropZoneContainer>, dropZoneContainerElement));
};
