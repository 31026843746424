import React, { Component } from "react";
import { Suggestion } from "js/react/components/Suggestion";
import Autosuggest from "react-autosuggest";
import { Key } from "js/core/utilities/keys";
import { TAG_DELIMITERS } from "js/react/constants";

class AutoSuggestRenderInput extends Component {
    constructor() {
        super();
        this.state = { highlightedSuggestion: "" };
    }

    render() {
        const {
            suggestionList,
            addTag,
            tags,
            taggedInputOnChange,
            inputProps = {},
            ...props
        } = this.props;

        const handleOnChange = (event, { method }) => {
            if (method === "enter") {
                event.preventDefault();
            } else {
                props.onChange(event);
            }
        };

        const handleOnKeyDown = event => {
            if (TAG_DELIMITERS.includes(event.which)) {
                event.preventDefault();
                addTag(this.state.highlightedSuggestion || inputValue);
            } else if (
                event.which === Key.BACKSPACE &&
                inputValue.length === 0
            ) {
                removeLastTag();
            }
        };

        const removeLastTag = () => {
            const updatedTags = tags.filter(
                (tag, index) => index < tags.length - 1
            );
            taggedInputOnChange(updatedTags);
        };

        const inputValue =
            (props.value && props.value.trim().toLowerCase()) || "";
        const inputLength = inputValue.length;

        const numberOfSuggestions = 5;
        const suggestions = suggestionList
            .filter(suggestion => {
                if (suggestion.name) {
                    return (
                        suggestion.name.toLowerCase().slice(0, inputLength) ===
                            inputValue ||
                        suggestion.email.toLowerCase().slice(0, inputLength) ===
                            inputValue
                    );
                } else {
                    return (
                        suggestion.email.toLowerCase().slice(0, inputLength) ===
                        inputValue
                    );
                }
            })
            .slice(0, numberOfSuggestions);

        return (
            <Autosuggest
                ref={props.ref}
                id="autoSuggest"
                suggestions={suggestions}
                shouldRenderSuggestions={value =>
                    value && value.trim().length > 0
                }
                getSuggestionValue={suggestion =>
                    suggestion.name || suggestion.email
                }
                renderSuggestion={suggestion => (
                    <Suggestion suggestion={suggestion} />
                )}
                inputProps={{
                    ...props,
                    onKeyDown: handleOnKeyDown,
                    onChange: handleOnChange,
                    onClick: inputProps.onClick,
                    readOnly: inputProps.readOnly
                }}
                onSuggestionSelected={(event, { suggestion }) =>
                    addTag(suggestion)
                }
                onSuggestionHighlighted={({ suggestion }) => {
                    this.setState({ highlightedSuggestion: suggestion });
                }}
                theme={autoSuggestStyle}
                onSuggestionsClearRequested={() => {}}
                onSuggestionsFetchRequested={() => {}}
                highlightFirstSuggestion={true}
            />
        );
    }
}

const autoSuggestStyle = {
    container: {
        flexGrow: 2
    },
    input: {
        background: "transparent",
        border: 0,
        color: "#222",
        fontFamily: "Source Sans Pro",
        fontWeight: 500,
        // margin: 0,
        outline: "none",
        padding: 5,
        fontSize: 14,
        width: "100%"
    },
    suggestionsContainer: {
        position: "absolute",
        background: "white",
        zIndex: 1
    },
    suggestionsList: {
        listStyle: "none",
        padding: 0,
        margin: 0
    },
    suggestion: {
        listStyleType: "none",
        margin: 0,
        padding: "16px 12px",
        borderBottom: "1px solid #f1f1f1",
        color: "black",
        "&:hover": {
            color: "#fff"
        },
        "&:lastChild": {
            borderBottom: "none"
        }
    },
    suggestionHighlighted: {
        backgroundColor: "#50bbe6", //change once you get react theme vars merged in
        color: "#fff"
    }
};

export { AutoSuggestRenderInput };
