import React, { Component } from "react";
import styled from "styled-components";
import { DialogTitle } from "@material-ui/core";

import Api from "js/core/api";
import getLogger from "js/core/logger";
import Loadable from "js/react/components/Loadable";
import { BlueButton, TextButton } from "js/react/components/UiComponents";
import InfoToolTip from "js/react/components/InfoToolTip";
import { TextField } from "js/Components/TextField";
import { BeautifulDialog, DialogContent, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { SalesforceUrls } from "common/constants";
import { Divider } from "js/Components/Divider";
import { trackActivity } from "js/core/utilities/utilities";

const logger = getLogger();

const StepSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: stretch;

    .step-title {
        font-size: 14px;
        font-weight: 600;
        color: #333;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .step-description {
        font-size: 14px;
        color: #666;
    }
        
    .input-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .buttons-container {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
`;

class SalesforceIntegrationPane extends Component {
    state = {
        domainName: "", prn: "", isConnectingSalesforce: false
    };

    handleConnectSalesforce = async () => {
        try {
            const { organizationId, closeDialog, onConnect } = this.props;
            const { domainName, prn } = this.state;

            const { tokens, error } = await Api.salesforceToken.post({ orgId: organizationId, domainName, prn });
            if (error) {
                throw new Error(error);
            }

            trackActivity("SalesforceIntegration", "Connect", null, null, { orgId: organizationId });

            logger.info("Salesforce auth flow completed, saving tokens", { tokens });
            onConnect && onConnect();
            closeDialog();
        } catch (err) {
            logger.error(err, "handleConnectSalesforce() failed");

            ShowErrorDialog({
                title: "Oops, failed to connect Salesforce.",
                message: <>Please check that your domain name and admin username are correct. For more support, please contact support at <a href="mailto:support@beautiful.ai">support@beautiful.ai</a>.</>,
            });
        }
    }

    handleLinkToSalesforceApp = () => {
        trackActivity("SalesforceIntegration", "Launch", null, null, { orgId: this.props.organizationId });

        window.open(SalesforceUrls.APP_INSTALL_URL, "_blank");
    }

    render() {
        const { domainName, prn, isConnectingSalesforce } = this.state;

        return (
            <Loadable isLoading={isConnectingSalesforce}>
                <StepSection>
                    <span className="step-title">Step 1: Add Beautiful.AI to Salesforce</span>
                    <span className="step-description">Click the button below to launch the Beautiful.ai installation flow for Salesforce.</span>
                    <BlueButton style={{ width: "fit-content" }} onClick={this.handleLinkToSalesforceApp}>
                        Add Beautiful.AI to Salesforce
                    </BlueButton>
                </StepSection>
                <Divider margin={20} />
                <StepSection>
                    <span className="step-title">Step 2: Connect Salesforce to Beautiful.AI</span>
                    <span className="step-description">Enter your domain name and admin username to connect Salesforce to Beautiful.ai.</span>

                    <div className="input-container">
                        <InfoToolTip
                            label="Domain Name"
                            labelStyle={{ fontSize: "14px", color: "#666" }}
                            title="Input the part of your salesforce workspace URL that comes before '.lightning.force.com'"
                            left
                        />
                        <TextField
                            value={domainName}
                            onChange={domainName => this.setState({ domainName })}
                            inputProps={{ style: { padding: "10px" } }}
                        />
                    </div>
                    <div className="input-container">
                        <InfoToolTip
                            label="Admin Username"
                            labelStyle={{ fontSize: "14px", color: "#666" }}
                            title="Input the username of one of your Salesforce admin users. User names are usually email addresses."
                            left
                        />
                        <TextField
                            value={prn}
                            onChange={prn => this.setState({ prn })}
                            inputProps={{ style: { padding: "10px" } }}
                        />
                    </div>
                    <div className="buttons-container">
                        <div style={{ flexGrow: 1 }}></div>
                        <TextButton
                            onClick={this.handleConnectSalesforce}
                            disabled={!domainName || !prn}
                            color="primary"
                        >
                            Connect Salesforce
                        </TextButton>
                    </div>
                </StepSection>
            </Loadable>
        );
    }
}

export class SalesforceIntegrationDialog extends Component {
    componentDidMount() {
        trackActivity("SalesforceIntegration", "Open", null, null, { orgId: this.props.organizationId });
    }

    handleCloseDialog = () => {
        trackActivity("SalesforceIntegration", "Cancel", null, null, { orgId: this.props.organizationId });

        this.props.closeDialog();
    }

    render() {
        return (
            <BeautifulDialog closeDialog={this.handleCloseDialog} closeButton>
                <DialogTitle>Connect Salesforce</DialogTitle>
                <DialogContent>
                    <SalesforceIntegrationPane {...this.props} />
                </DialogContent>
            </BeautifulDialog>
        );
    }
}
