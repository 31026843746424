import React, { Component, Fragment } from "reactn";
import { Button, Icon } from "@material-ui/core";
import { BlueSwitch } from "js/react/components/Switch";
import {
    Section,
    UIPane,
    UIPaneContents,
    UIPaneHeader,
} from "js/react/components/UiComponents";
import {
    ShowErrorDialog,
    ShowMessageDialog
} from "js/react/components/Dialogs/BaseDialog";
import { app } from "js/namespaces";
import { trackActivity } from "js/core/utilities/utilities";
import * as slack from "js/core/utilities/slack";
import styled from "styled-components";
import { FlexSpacer } from "js/react/components/Gap";
import { Notification } from "js/react/components/Notification";
import getLogger, { LogGroup } from "js/core/logger";
import AppController from "js/core/AppController";

const logger = getLogger(LogGroup.NOTIFICATION);

const NotificationsHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
  label {
    margin-left: 10px;
    font-weight: 600;
    text-transform: uppercase;
   }
`;

const NoNotifications = styled.div`
   font-size: 15px;
   padding: 10px 20px;
   background: white;
   width: 100%;
`;

class NotificationsPane extends Component {
    constructor(props) {
        super(props);

        this.state = {
            read: AppController.notificationsService.read,
            unread: AppController.notificationsService.unread,
            // Ensuring backwards compatibility, assuming notifyByEmail and notifyWithPopup == true unless they're explicitly set to false
            notifyByEmail: app.user.get("notifyByEmail") === false ? false : true,
            notifyWithPopup: app.user.get("notifyWithPopup") === false ? false : true,
            isSlackEnabled: !!app.user.get("isSlackEnabled"),
            setingsFetching: false,
            isOpen: true
        };
    }

    componentDidMount() {
        AppController.notificationsService.onReadChanged(this.onReadChanged);
        AppController.notificationsService.onUnreadChanged(this.onUnreadChanged);
    }

    componentWillUnmount() {
        AppController.notificationsService.offReadChanged(this.onReadChanged);
        AppController.notificationsService.offUnreadChanged(this.onUnreadChanged);
    }

    onReadChanged = () => {
        this.setState({ read: AppController.notificationsService.read });
    }

    onUnreadChanged = () => {
        this.setState({ unread: AppController.notificationsService.unread });
    }

    toggleNotifyByEmail = async () => {
        const { workspaceId } = this.props;
        const { notifyByEmail } = this.state;

        this.setState({ notifyByEmail: !notifyByEmail, setingsFetching: true });

        try {
            app.user.update({ notifyByEmail: !notifyByEmail });
            await app.user.updatePromise;
        } catch (err) {
            logger.error(err, "[NotificationsPane] app.user.update() failed");
            this.setState({ notifyByEmail });
        } finally {
            this.setState({ setingsFetching: false });
            const props = {
                setting_changed: "notify by email",
                old_value: notifyByEmail,
                new_value: !notifyByEmail,
                scope: "account",
                presentation_id: null,
                workspace_id: workspaceId
            };
            trackActivity("Notification", "SettingChanged", null, null, props, { audit: true });
        }
    }

    toggleNotifyWithPopup = async () => {
        const { workspaceId } = this.props;
        const { notifyWithPopup } = this.state;

        this.setState({ notifyWithPopup: !notifyWithPopup, setingsFetching: true });

        try {
            app.user.update({ notifyWithPopup: !notifyWithPopup });
            await app.user.updatePromise;
        } catch (err) {
            logger.error(err, "[NotificationsPane] app.user.update() failed");
            this.setState({ notifyWithPopup });
        } finally {
            this.setState({ setingsFetching: false });
            const props = {
                setting_changed: "in-app popups",
                old_value: notifyWithPopup,
                new_value: !notifyWithPopup,
                scope: "account",
                presentation_id: null,
                workspace_id: workspaceId
            };
            trackActivity("Notification", "SettingChanged", null, null, props, { audit: true });
        }
    }

    toggleSlackIntegration = async () => {
        const { workspaceId } = this.props;
        const { isSlackEnabled } = this.state;

        this.setState({ isSlackEnabled: !isSlackEnabled, setingsFetching: true });

        try {
            if (isSlackEnabled) {
                await slack.disable();
            } else {
                await slack.enable();

                ShowMessageDialog({
                    title: "Slack notifications enabled",
                    message: "You're all set! You should start receiving updates about your presentations in your DM with Beautiful.ai bot right away."
                });
            }
        } catch (err) {
            this.setState({ isSlackEnabled });

            if (err.message !== "popup_closed_by_user" && err.message !== "access_denied") {
                logger.error(err, "[NotificationsPane] slack.disable()/disable() failed", { isSlackEnabled });

                ShowErrorDialog({
                    error: `Error ${isSlackEnabled ? "disabling" : "enabling"} Slack notifications`,
                    message: <p>Sorry, we hit an unexpected error, try again later or contact support at <a
                        href="mailto:support@beautiful.ai">support@beautiful.ai</a></p>
                });
            }
        } finally {
            this.setState({ setingsFetching: false });
            const props = {
                setting_changed: "slack integration",
                old_value: isSlackEnabled,
                new_value: !isSlackEnabled,
                scope: "account",
                presentation_id: null,
                workspace_id: workspaceId
            };
            trackActivity("Notification", "SettingChanged", null, null, props, { audit: true });
        }
    }

    markAllAsRead = () => {
        AppController.notificationsService.markAllAsRead()
            .catch(err => logger.error(err, "[NotificationsPane] AppController.notificationsService.markAllAsRead() failed"));
    }

    handleBackClicked = () => {
        this.setState({ isOpen: false });
    };

    closeContainer = () => {
        this.props.closeDialog();
    };

    render() {
        const { workspaceId } = this.props;
        const { read, unread, notifyByEmail, notifyWithPopup, isSlackEnabled, setingsFetching } = this.state;

        const readForCurrentWorkspace = read.filter(notification => notification.event.workspaceId === workspaceId);
        const unreadForCurrentWorkspace = unread.filter(notification => notification.event.workspaceId === workspaceId);

        return (
            <UIPane>
                <UIPaneHeader>
                    Notifications
                </UIPaneHeader>
                <UIPaneContents>
                    <Section title="Settings">
                        <BlueSwitch
                            checked={notifyByEmail}
                            onChange={this.toggleNotifyByEmail}
                            disabled={setingsFetching}
                            label="Notify me by email if I'm offline"
                        />
                        <BlueSwitch
                            checked={notifyWithPopup}
                            onChange={this.toggleNotifyWithPopup}
                            disabled={setingsFetching}
                            label="Enable in-app popup notifications"
                        />
                        <BlueSwitch
                            checked={isSlackEnabled}
                            onChange={this.toggleSlackIntegration}
                            disabled={setingsFetching}
                            label="Enable Slack notifications"
                        />
                    </Section>

                    {unreadForCurrentWorkspace.length == 0 && read.length == 0 &&
                        <NoNotifications>You have no notifications yet</NoNotifications>
                    }
                    {unreadForCurrentWorkspace.length > 0 &&
                        <Fragment>
                            <NotificationsHeader>
                                <label>Unread Notifications</label>
                                <FlexSpacer />
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={this.markAllAsRead}
                                >
                                    <Icon>check</Icon>
                                    Mark all as read
                                </Button>
                            </NotificationsHeader>
                            {unreadForCurrentWorkspace.map(notification => (
                                <Notification
                                    key={notification.id} {...notification}
                                />))
                            }
                        </Fragment>
                    }

                    {readForCurrentWorkspace.length > 0 &&
                        <Fragment>
                            <NotificationsHeader>
                                <label>Read Notifications</label>
                            </NotificationsHeader>
                            {readForCurrentWorkspace.map(notification => (<Notification
                                key={notification.id} {...notification} />))}
                        </Fragment>
                    }
                </UIPaneContents>
            </UIPane>
        );
    }
}

export default NotificationsPane;
