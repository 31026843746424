import ThemeEditorController from "js/editor/ThemeEditor/ThemeEditorController";
const { v4: uuid } = require("uuid");

export class GradientStop {
    constructor(position, color) {
        this.position = position;
        this._color = ThemeEditorController.getColor(color);
        this.id = uuid();
    }

    set color(color) {
        this._color = ThemeEditorController.getColor(color);
    }

    get color() {
        return this._color;
    }

    toHexString() {
        return this._color.toHexString().toUpperCase();
    }

    // Converts to an object with hex colors to save in database
    toJSON() {
        return {
            position: this.position,
            color: this.toHexString(),
        };
    }
}
