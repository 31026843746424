import React, { Component, Fragment } from "react";
import styled from "styled-components";

import { DragIndicator as DragIndicatorIcon } from "@material-ui/icons";

import { app } from "js/namespaces";
import { DragElementType, ResizeDirection } from "common/constants";
import * as geom from "js/core/utilities/geom";
import { $, _ } from "js/vendor";
import { themeColors } from "js/react/sharedStyles";
import { getStaticUrl } from "js/config";
import WidgetButton, { WidgetButtonPosition } from "js/Components/WidgetButton";
import getLogger, { LogGroup } from "js/core/logger";
import { Icon } from "../../Components/Icon";

const logger = getLogger(LogGroup.DRAG_ELEMENT_MANAGER);

const Container = styled.div.attrs(({ isDragging }) => ({
    style: {
        cursor: isDragging ? "grab" : "unset",
        pointerEvents: isDragging ? "all" : "unset"
    }
}))`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
`;

const DragElementContainer = styled.div.attrs(({ bounds, scale = 1 }) => ({
    style: {
        ...bounds.toObject(),
        transform: `scale(${scale})`
    }
}))`
    position: absolute;
    transform-origin: top left;
    pointer-events: none;
`;

const CustomDropTarget = styled.div.attrs(({ bounds, active }) => ({
    style: {
        ...bounds.toObject(),
        background: active ? "rgba(80, 187, 230, 0.9)" : "rgba(80, 187, 230, 0.4)",
        border: active ? "solid 1px #50bbe6" : "dotted 1px #50bbe6",
    }
}))`
    position: absolute;
    transform-origin: top left;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #50bbe6;
    z-index: -1;
    font-size: 14px;
    font-weight: 600;
`;

const DragHandleContainer = styled.div`
    position: absolute;

    left: 0;
    top: 0;
    height: 100%;
    transform: translateX(-100%);

    display: flex;
    justify-content: center;
    align-items: center;

    color: ${themeColors.ui_blue};
    background: ${themeColors.contextBlue};

    cursor: grab;

    pointer-events: all;

    .MuiSvgIcon-root {
        font-size: 18px;
    }
`;

const SimpleDragHandleContainer = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    height: 60px;
    width: 60px;
`;

export const DragHandleStyle = {
    SIMPLE: "simple",
    GRABBER: "grabber",
    DIVIDER: "divider"
};

export function DragHandle(props) {
    if (props.simple) {
        let left, top;
        switch (props.position) {
            case WidgetButtonPosition.CORNER:
                left = 0;
                top = 0;
                break;
            case WidgetButtonPosition.INNER:
                left = 14;
                top = 14;
                break;
            case WidgetButtonPosition.OUTER:
            default:
                left = -14;
                top = 14;
                break;
        }

        return (
            <SimpleDragHandleContainer {...props} className="drag-handle">
                <WidgetButton icon="drag_indicator" outlined cursor="grab" mousePadding={10} left={left} top={top} />
            </SimpleDragHandleContainer>
        );
    } else {
        return (
            <DragHandleContainer {...props} className="drag-handle">
                <DragIndicatorIcon />
            </DragHandleContainer>
        );
    }
}

function getResizeHandleStyle(position) {
    let cursor = "unset";
    if ([ResizeDirection.TOP_LEFT, ResizeDirection.BOTTOM_RIGHT].includes(position)) {
        cursor = "nwse-resize";
    } else if ([ResizeDirection.TOP_RIGHT, ResizeDirection.BOTTOM_LEFT].includes(position)) {
        cursor = "nesw-resize";
    } else if ([ResizeDirection.LEFT, ResizeDirection.RIGHT].includes(position)) {
        cursor = "ew-resize";
    } else if ([ResizeDirection.TOP, ResizeDirection.BOTTOM].includes(position)) {
        cursor = "ns-resize";
    }

    let left = 0;
    if ([ResizeDirection.TOP, ResizeDirection.BOTTOM].includes(position)) {
        left = "50%";
    } else if ([ResizeDirection.TOP_RIGHT, ResizeDirection.BOTTOM_RIGHT, ResizeDirection.RIGHT].includes(position)) {
        left = "100%";
    }

    let top = 0;
    if ([ResizeDirection.LEFT, ResizeDirection.RIGHT].includes(position)) {
        top = "50%";
    } else if ([ResizeDirection.BOTTOM_LEFT, ResizeDirection.BOTTOM_RIGHT, ResizeDirection.BOTTOM].includes(position)) {
        top = "100%";
    }

    return {
        cursor,
        left,
        top
    };
}

const SimpleResizeHandle = styled.div.attrs(({ position, offset }) => ({
    style: {
        ...getResizeHandleStyle(position),
        transform: `translate(-50%, -50%) translateX(${offset?.x ?? 0}px) translateY(${offset?.y ?? 0}px)`
    }
}))`
    position: absolute;

    border: solid 1px #6c6c6c;
    border-radius: 2px;

    background: white;

    width: 8px;
    height: 8px;

    pointer-events: auto;
`;

const FancyResizeHandle = styled.div.attrs(({ position, offset }) => ({
    style: {
        ...getResizeHandleStyle(position),
        transform: `translate(-50%, -50%) translateX(${offset?.x ?? 0}px) translateY(${offset?.y ?? 0}px)`
    }
}))`
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 21px;
    height: 21px;
    background-color: #50bbe6;
    color: white;
    border-radius: 50%;

    pointer-events: auto;
`;

export const CustomResizeHandle = styled.div.attrs(({ position, offset }) => ({
    style: {
        ...getResizeHandleStyle(position),
        transform: `translate(-50%, -50%) translateX(${offset?.x ?? 0}px) translateY(${offset?.y ?? 0}px)`
    }
}))`
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    //width: 21px;
    //height: 21px;
    //background-color: #50bbe6;
    //color: white;
    //border-radius: 50%;

    pointer-events: auto;
`;

const FancyResizeHandleIcon = styled.div`
    width: 100%;
    height: 100%;
    margin-left: -0.5px;
    margin-top: -1.5px;
    background: url(${getStaticUrl("/images/ui/drag_handle_small.png")}) no-repeat 6px 6px;
`;

const AltFancyResizeHandleIcon = styled.div`
    margin-top: ${({ vertical }) => vertical ? "0" : "3px"};
    margin-left: ${({ vertical }) => vertical ? "-4px" : "0"};
    transform: ${({ vertical }) => vertical ? "rotate(90deg)" : "none"};

    :before {
        font-family: "Material Icons";
        content: "\\E25D";
        font-size: 16px;
    }
`;

const ResizeHandleDividerVertical = styled.div`
    position: absolute;
    width: 1px;
    height: 200px;
    border-left: dotted 1px ${themeColors.ui_blue};
`;

const ResizeHandleDividerHorizontal = styled.div`
    position: absolute;
    height: 1px;
    width: 200px;
    border-top: dotted 1px ${themeColors.ui_blue};
`;

export const ResizeHandle = React.forwardRef(function ResizeHandle({ resizeDirection, onMouseDown, style = DragHandleStyle.SIMPLE, offset = null }, ref) {
    let handleStyle = style;
    if (typeof style == "function") {
        handleStyle = style(resizeDirection);
    }

    switch (handleStyle) {
        case DragHandleStyle.SIMPLE:
            return <SimpleResizeHandle ref={ref} position={resizeDirection} onMouseDown={onMouseDown} offset={offset} />;
        case DragHandleStyle.GRABBER:
            return (
                <FancyResizeHandle ref={ref} position={resizeDirection} onMouseDown={onMouseDown} offset={offset}>
                    <AltFancyResizeHandleIcon vertical={[ResizeDirection.RIGHT, ResizeDirection.LEFT].includes(resizeDirection)} />
                </FancyResizeHandle>
            );
        case DragHandleStyle.DIVIDER:
            let isVertical = [ResizeDirection.LEFT, ResizeDirection.RIGHT].includes(resizeDirection);
            return (
                <FancyResizeHandle ref={ref} position={resizeDirection} onMouseDown={onMouseDown} offset={offset}>
                    {isVertical && <ResizeHandleDividerVertical />}
                    {!isVertical && <ResizeHandleDividerHorizontal />}
                    <AltFancyResizeHandleIcon vertical={isVertical} />
                </FancyResizeHandle>
            );
        default:
            return (
                <CustomResizeHandle ref={ref} position={resizeDirection} onMouseDown={onMouseDown} offset={offset}>
                    {handleStyle}
                </CustomResizeHandle>
            );
    }
});

// Use as schema for dragProps
export const defaultDragPositionProps = {
    dragType: DragElementType.POSITION,
    dragOpacity: 0.5,
    dragAxes: ["x", "y"],
    hideFauxElement: false,
    onDragStart: async () => {
    },
    dropTargets: [],
    customDropTargets: [],
    drop: async ({ targetElement, customTarget }) => {
    },
    onDrag: async ({ dragOffset }) => {
    },
    onDragEnd: async ({ dragOffset, activeCustomTargets }) => {
    },
    getNewProps: element => null,
};

export const defaultDragResizeProps = {
    ...defaultDragPositionProps,
    dragType: DragElementType.SIZE,
    resizeDirections: [
        ResizeDirection.TOP_LEFT,
        ResizeDirection.TOP_RIGHT,
        ResizeDirection.BOTTOM_RIGHT,
        ResizeDirection.BOTTOM_LEFT
    ],
    resizeDirection: null,
    handleProps: {
        style: DragHandleStyle.SIMPLE,
        offset: null
    }
};

export class DragElementManagerRollover extends Component {
    static defaulDragState = {
        isDragging: false,
        containerBounds: null,
        dragStartPoint: null,
        draggingElements: [],
        dropTargets: [],
        customDropTargets: [],
        initialSize: null
    }

    constructor(props) {
        super(props);

        this.containerRef = React.createRef();

        this.state = { ...DragElementManagerRollover.defaulDragState };

        this.dragHandlingStartedAt = 0;
        this.dragHandlingEndedAt = 0;
    }

    componentDidMount() {
        const { dragState } = this.props;
        if (dragState) {
            this.startDrag();
        }
    }

    componentDidUpdate(prevProps) {
        const { dragState } = this.props;

        if (!prevProps.dragState && dragState) {
            this.startDrag();
        } else if (prevProps.dragState && dragState && prevProps.dragState.sourceElements !== dragState.sourceElements) {
            document.removeEventListener("mousemove", this.handleDrag);
            document.removeEventListener("mouseup", this.handleDragEnd);
            this.startDrag();
        }
    }

    setStateAsync = state => new Promise(resolve => this.setState(state, resolve));

    _getElementBounds(element, containerBounds) {
        return geom.Rect.FromBoundingClientRect(element.dragNode.getBoundingClientRect()).offset(-containerBounds.left, -containerBounds.top);
    }

    _getElementSelectionBounds(element, containerBounds) {
        return geom.Rect.FromBoundingClientRect(element.uiRefs.containerRef.current.getBoundingClientRect()).offset(-containerBounds.left, -containerBounds.top);
    }

    _getCustomTargetBounds(customTarget, containerBounds) {
        const { canvasController } = this.props;
        return customTarget.bounds.spaceScale(canvasController.canvas.getScale());
    }

    startDrag = async () => {
        const {
            dragState: {
                dragProps: {
                    dragType
                },
                startDragEvent
            }
        } = this.props;

        app.isDragging = true;
        app.isDraggingItem = true;

        const event = startDragEvent ?? window.event;
        if (!event) {
            throw new Error("startDrag() cannot be initialized without an event");
        }

        if (dragType === DragElementType.POSITION) {
            this.startDragPosition(event);
        } else if (dragType === DragElementType.SIZE) {
            this.startDragResize(event);
        }
    }

    startDragPosition = async event => {
        const {
            dragState: {
                sourceElements,
                dragProps
            }
        } = this.props;

        const { dragStartPoint: prevDragStartPoint, draggingElements: prevDraggingElements } = this.state;

        await this.setStateAsync({ ...DragElementManagerRollover.defaulDragState });

        const containerBounds = geom.Rect.FromBoundingClientRect(this.containerRef.current.getBoundingClientRect());

        const dragStartPoint = prevDragStartPoint ?? new geom.Point(event.pageX, event.pageY);

        const draggingElements = sourceElements
            .map(element => {
                let initialBounds = this._getElementBounds(element, containerBounds);
                let initialSelectionBounds = this._getElementSelectionBounds(element, containerBounds);
                let dragBounds = initialBounds.clone();
                let dragSelectionBounds = initialSelectionBounds.clone();

                const prevDraggingElement = prevDraggingElements.find(({ element: prevElement }) => prevElement.id === element.id);
                if (prevDraggingElement) {
                    initialBounds = initialBounds.setPosition(prevDraggingElement.initialBounds.position);
                    initialSelectionBounds = initialSelectionBounds.setPosition(prevDraggingElement.initialSelectionBounds.position);

                    dragBounds = dragBounds.setPosition(prevDraggingElement.dragBounds.position);
                    dragSelectionBounds = dragSelectionBounds.setPosition(prevDraggingElement.dragSelectionBounds.position);
                }

                return {
                    element,
                    initialBounds,
                    dragBounds,
                    initialSelectionBounds,
                    dragSelectionBounds,
                    html: element.dragNode.innerHTML,
                    selectionHTML: element.uiRefs.containerRef.current.innerHTML
                };
            });

        const dropTargets = dragProps.dropTargets
            .map(element => {
                let elementBounds = this._getElementBounds(element, containerBounds);
                const parentElementBounds = this._getElementBounds(element.parentElement, containerBounds);

                if (element.isLastItem && element.extendLastItemDropBounds) {
                    elementBounds = elementBounds.inflate({
                        right: parentElementBounds.right - elementBounds.right,
                        bottom: parentElementBounds.bottom - elementBounds.bottom
                    });
                }

                return {
                    element,
                    bounds: elementBounds,
                    intersectionArea: 0
                };
            });

        const customDropTargets = dragProps.customDropTargets
            .map(customTarget => ({
                ...customTarget,
                bounds: this._getCustomTargetBounds(customTarget, containerBounds),
                intersectionArea: 0
            }));

        await dragProps.onDragStart({});

        await this.setStateAsync({
            isDragging: true,
            draggingElements,
            dropTargets,
            customDropTargets,
            containerBounds,
            dragStartPoint
        });

        document.addEventListener("mousemove", this.handleDrag);
        document.addEventListener("mouseup", this.handleDragEnd);
    }

    startDragResize = async event => {
        const {
            dragState: {
                dragProps: { resizeDirection, onDragStart }
            }
        } = this.props;

        await this.setStateAsync({ ...DragElementManagerRollover.defaulDragState });

        const dragStartPoint = new geom.Point(event.pageX, event.pageY);

        await onDragStart({ resizeDirection });

        await this.setStateAsync({
            isDragging: true,
            dragStartPoint
        });

        document.addEventListener("mousemove", this.handleDrag);
        document.addEventListener("mouseup", this.handleDragEnd);
    }

    handleDrag = async event => {
        window.requestAnimationFrame(async ts => {
            if (this.dragFrameHandledAt === ts) {
                return;
            }
            this.dragFrameHandledAt = ts;

            const { dragState: { dragProps: { dragType } }, canvasController } = this.props;

            if (canvasController.isCanvasGenerating) {
                return;
            }

            if (this.isHandlingDrag) {
                return;
            }

            this.isHandlingDrag = true;
            try {
                if (dragType === DragElementType.POSITION) {
                    await this.handleDragPosition(event);
                } else if (dragType === DragElementType.SIZE) {
                    await this.handleDragResize(event);
                }
            } catch (err) {
                logger.error(err, "handleDrag() failed");
            }
            this.isHandlingDrag = false;
        });
    }

    getDropTargets = draggingBounds => {
        const {
            dropTargets,
            customDropTargets
        } = this.state;

        [...dropTargets, ...customDropTargets].forEach(target => {
            const doesIntersect = target.bounds.intersects(draggingBounds);
            if (!doesIntersect) {
                target.intersectionArea = 0;
                return;
            }

            target.intersectionArea = new geom.Rect({
                left: draggingBounds.left,
                top: draggingBounds.top,
                width: Math.min(draggingBounds.width, target.bounds.width),
                height: Math.min(draggingBounds.height, target.bounds.height)
            }).intersection(target.bounds).area();
        });

        const dropTarget = _.maxBy(dropTargets.filter(({ intersectionArea, bounds }) => intersectionArea > Math.min(bounds.area(), draggingBounds.area()) * 0.5), "intersectionArea");
        const customDropTarget = _.maxBy(customDropTargets.filter(({ intersectionArea, bounds, overlapThreshold }) => intersectionArea > Math.min(bounds.area(), draggingBounds.area()) * (overlapThreshold ?? 0.2)), "intersectionArea");

        return {
            dropTarget,
            customDropTarget
        };
    }

    handleDragPosition = async event => {
        const {
            dragState: { dragProps },
            selectionLayerController,
            canvasController
        } = this.props;
        const {
            draggingElements,
            dragStartPoint,
            customDropTargets
        } = this.state;

        const dragOffset = new geom.Point(event.pageX - dragStartPoint.x, event.pageY - dragStartPoint.y);
        if (!dragProps.dragAxes.includes("x")) {
            dragOffset.x = 0;
        }
        if (!dragProps.dragAxes.includes("y")) {
            dragOffset.y = 0;
        }

        draggingElements.forEach(element => {
            element.dragBounds = element.initialBounds.offset(dragOffset);
            element.dragSelectionBounds = element.initialSelectionBounds.offset(dragOffset);
        });

        const draggingBounds = draggingElements.reduce((bounds, { dragBounds }) => bounds ? bounds.union(dragBounds) : dragBounds, null);

        await this.setStateAsync({ draggingElements });

        const { dropTarget, customDropTarget } = this.getDropTargets(draggingBounds);

        if (dropTarget && !dropTarget.dropOnEnd) {
            const newElementPath = await dragProps.drop(({ targetElement: dropTarget.element }));

            canvasController.canvas.markStylesAsDirty();
            await canvasController.canvas.refreshCanvas(false);

            if (newElementPath) {
                const newElements = [canvasController.canvas.getElementByUniquePath(newElementPath)];
                await selectionLayerController.setSelectedElements(newElements);
                await selectionLayerController.registerElementDrag(newElements, dragProps.getNewProps(newElements[0]) ?? dragProps, event);
            }
            return;
        }

        if (customDropTarget && !customDropTarget.dropOnEnd) {
            const newElementPath = await dragProps.drop(({ customDropTargetId: customDropTarget.id, customDropTargetData: customDropTarget.data }));

            canvasController.canvas.markStylesAsDirty();
            await canvasController.canvas.refreshCanvas(false);

            if (newElementPath) {
                const newElements = [canvasController.canvas.getElementByUniquePath(newElementPath)];
                await selectionLayerController.setSelectedElements(newElements);
                await selectionLayerController.registerElementDrag(newElements, dragProps.getNewProps(newElements[0]) ?? dragProps, event);
            }
            return;
        }

        if (customDropTargets.length > 0) {
            customDropTargets.forEach(target => {
                target.isActive = target.id === customDropTarget?.id;
            });
            await this.setStateAsync({ customDropTargets });
        }

        await dragProps.onDrag({ dragOffset: dragOffset.scale(1 / canvasController.canvas.getScale()) });
    }

    handleDragResize = async event => {
        const {
            canvasController,
            dragState: { dragProps: { onDrag, resizeDirection } }
        } = this.props;
        const {
            dragStartPoint
        } = this.state;

        const dragOffset = new geom.Point(event.pageX - dragStartPoint.x, event.pageY - dragStartPoint.y);
        const canvasScale = canvasController.canvas.getScale();

        await onDrag({ dragOffset: dragOffset.scale(1 / canvasScale), resizeDirection });
    }

    handleDragEnd = async event => {
        const {
            dragState: { dragProps },
            selectionLayerController,
            canvasController
        } = this.props;
        const {
            dragStartPoint,
            draggingElements
        } = this.state;

        const dragOffset = new geom.Point(event.pageX - dragStartPoint.x, event.pageY - dragStartPoint.y);
        if (!dragProps.dragAxes.includes("x")) {
            dragOffset.x = 0;
        }
        if (!dragProps.dragAxes.includes("y")) {
            dragOffset.y = 0;
        }

        const draggingBounds = draggingElements.reduce((bounds, { dragBounds }) => bounds ? bounds.union(dragBounds) : dragBounds, null);

        const { dropTarget, customDropTarget } = this.getDropTargets(draggingBounds);

        const newElementPath = await dragProps.onDragEnd({
            dragOffset: dragOffset.scale(1 / canvasController.canvas.getScale()),
            resizeDirection: dragProps.resizeDirection,
            ...(dropTarget ? { targetElement: dropTarget.element } : {}),
            ...(customDropTarget ? { customDropTargetId: customDropTarget.id, customDropTargetData: customDropTarget.data } : {})
        });

        app.isDragging = false;
        app.isDraggingItem = false;

        document.removeEventListener("mousemove", this.handleDrag);
        document.removeEventListener("mouseup", this.handleDragEnd);

        await this.setStateAsync(() => ({ ...DragElementManagerRollover.defaulDragState }));
        await selectionLayerController.unregisterElementDrag();

        await canvasController.canvas.updateCanvasModel();

        if (newElementPath) {
            const newElements = [canvasController.canvas.getElementByUniquePath(newElementPath)];
            await selectionLayerController.setSelectedElements(newElements);
        }
    }

    render() {
        const {
            dragState,
            canvasController
        } = this.props;
        const {
            isDragging,
            draggingElements,
            customDropTargets
        } = this.state;

        return (<Container ref={this.containerRef} isDragging={isDragging}>
            {isDragging && <>
                {draggingElements.map(element => (<Fragment key={element.element.uniquePath}>
                    {!dragState?.dragProps.hideFauxElement && element.dragBounds && <DragElementContainer bounds={element.dragBounds} scale={canvasController.canvas.getScale()} dangerouslySetInnerHTML={{ __html: element.html }} />}
                    {!dragState?.dragProps.hideFauxElement && element.dragSelectionBounds && <DragElementContainer bounds={element.dragSelectionBounds} dangerouslySetInnerHTML={{ __html: element.selectionHTML }} />}
                </Fragment>))}

                {customDropTargets
                    .filter(({ isVisible, isActive }) => isVisible || isActive)
                    .map(({ bounds, label, isActive }, index) => (<CustomDropTarget key={index} bounds={bounds} active={isActive}>{label}</CustomDropTarget>))}
            </>}
        </Container>);
    }
}
