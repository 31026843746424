import { DialogContent } from "@material-ui/core";
import React, { Component } from "react";
import { BeautifulDialog, ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import styled from "styled-components";

const TypeformContainer = styled.div`
    height: 80vh;
`;

interface Props {
    typeformId: string;
    hiddenFields?: { [field: string]: string }
    closeDialog?: () => void;
}

export class TypeformDialog extends Component<Props> {
    refEmbed = React.createRef<HTMLDivElement>();

    componentDidMount() {
        setTimeout(() => {
            const {
                closeDialog,
                typeformId,
                hiddenFields,
            } = this.props;

            // @ts-ignore
            window.typeformOnEnd = closeDialog;
    
            // NOTE: `data-tf-live` is used to designate the typeformId because all other forms (`data-tf-widget`, `data-tf-popup`, etc) 
            //   result in only loading the typeform homepage, not the desired survey. This also why we are not using the embed API, as 
            //   it doesn't provide a way to set the typeformId in a `live` manner. What seems to be blocking these other forms of embed 
            //   is a Content Security Policy "frame-ancestors" set to "self" even tho the forums indicate this should only be an issue 
            //   on `http://` environments that aren't localhost.
            const embed = document.createElement("div");
            embed.setAttribute("data-tf-live", typeformId);
            if (hiddenFields) {
                const encodedHiddenFields = Object.entries(hiddenFields)
                    .map(([field, value]) => `${field}=${value}`)
                    .join(",");
                embed.setAttribute("data-tf-hidden", encodedHiddenFields);
            }
            embed.setAttribute("data-tf-on-ending-button-click", "typeformOnEnd");
            const script = document.createElement("script");
            script.src = "//embed.typeform.com/next/embed.js";
            this.refEmbed.current.appendChild(script);
            this.refEmbed.current.appendChild(embed);
        })
    }

    componentWillUnmount() {
        // @ts-ignore
        delete window.typeformOnEnd;
    }

    render() {
        const {
            closeDialog,
        } = this.props;

        return (
            <BeautifulDialog
                closeDialog={closeDialog}
                maxWidth="sm"
            >
                <DialogContent>
                    <TypeformContainer
                        ref={this.refEmbed}
                        className="typeform-embed"
                    />
                </DialogContent>
            </BeautifulDialog>
        );
    }
}

export const ShowTypeformDialog = (props: Props) => ShowDialog(TypeformDialog, props);
