import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import {
    DialogTitle as MuiDialogTitle,
    Typography
} from "@material-ui/core";

const DialogTitleWithClose = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    closeButton: {
        color: "#333",
        cursor: "pointer"
    }
}))(props => {
    const { children, classes, showCloseButton, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose && showCloseButton && (
                <div onClick={onClose}>
                    <i className={classNames("micon", classes.closeButton)}>
                        close
                    </i>
                </div>
            )}
        </MuiDialogTitle>
    );
});

export default DialogTitleWithClose;
