import React from "react";
import ReactDOM from "react-dom";
import WaveSurfer from "wavesurfer.js";

import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.AUDIO);

export default class Waveform extends React.Component {
    constructor(props) {
        super(props);

        this.readyPromise = Promise.resolve();
    }

    componentDidMount() {
        const {
            waveColor = "#bbbbbb",
            progressColor = "#11a9e2",
            cursorWidth = 1,
            cursorColor = "#ffffff",
            height = 100,
            interact = true,
            backend = "WebAudio",
            fillParent = true,
            minPxPerSec = 50,
            onLoaded = () => { },
            onFinishedPlaying = () => { },
            onLoadFailed = () => { },
            src
        } = this.props;

        this.$el = ReactDOM.findDOMNode(this);
        this.$waveform = this.$el.querySelector(".wave");
        this.wavesurfer = WaveSurfer.create({
            container: this.$waveform,
            waveColor,
            progressColor,
            cursorWidth,
            cursorColor,
            interact,
            backend,
            fillParent,
            minPxPerSec,
            height
        });

        this.readyPromise = new Promise((resolve, reject) => {
            this.wavesurfer.load(src);

            this.wavesurfer.on("ready", () => {
                resolve();

                onLoaded({
                    durationSeconds: this.wavesurfer.getDuration()
                });
            });
            this.wavesurfer.on("error", err => reject(err));
            this.wavesurfer.on("finish", () => onFinishedPlaying());
        })
            .catch(err => {
                onLoadFailed();
                logger.error(err, `[Waveform] waveform.js couldn't load audio file from ${src}`, { src });
            });
    }

    componentDidUpdate(prevProps) {
        this.readyPromise
            .then(() => {
                const { isPlaying, minPxPerSec } = this.props;

                if (isPlaying != prevProps.isPlaying) {
                    if (isPlaying) {
                        this.wavesurfer.play();
                    } else {
                        this.wavesurfer.stop();
                    }
                }

                if (minPxPerSec != prevProps.minPxPerSec) {
                    this.wavesurfer.zoom(minPxPerSec);
                }
            });
    }

    componentWillUnmount() {
        const { isPlaying } = this.props;

        if (isPlaying) {
            this.readyPromise
                .then(() => this.wavesurfer.stop());
        }
    }

    render() {
        return (
            <div className="waveform">
                <div className="wave"></div>
            </div>
        );
    }
}
