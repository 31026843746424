import theme from "js/react/materialThemeOverrides";
const { primary } = theme.palette;
import { withStyles } from "@material-ui/core/styles";
import UploadImage from "js/react/components/UploadImage";
import { convertDataURItoBlob, trimWhiteEdges } from "js/core/utilities/imageUtilities";
import getLogger, { LogGroup } from "js/core/logger";
import Api from "js/core/api";
import { uploadBlobToSignedUrl } from "js/core/utilities/uploadBlobToSignedUrl";

const logger = getLogger(LogGroup.USER);

class UploadAvatar extends UploadImage {
    async componentDidMount() {
        const { src } = this.props;
        if (!src) return;
        this.setState({ src });
    }

    async componentDidUpdate(prevProps) {
        const { src } = this.props;
        if (src === prevProps.src) return;
        this.setState({ src });
    }

    setFile = file => {
        if (!file) return;

        this.setState({ loading: true });
        this.hidePopup();

        const fileReader = new FileReader();
        fileReader.onload = () => {
            const img = new Image();
            img.onload = async () => {
                const imageData = trimWhiteEdges(img);

                try {
                    const { writeUrl, readUrl } = await Api.avatars.post();
                    const blob = await convertDataURItoBlob(imageData);
                    await uploadBlobToSignedUrl(blob, writeUrl);

                    this.props.update({ [this.props.attribute]: readUrl });
                    this.setState({ loading: false });
                } catch (err) {
                    logger.error(err, "UploadAvatar setFile() failed");
                }
            };
            img.src = fileReader.result;
        };
        fileReader.readAsDataURL(file);
    };
}

const uploadAvatarStyle = {
    logoParent: {
        width: 130,
        height: 130,
        border: "none",
        background: "none",
        position: "relative",
        "& .uploadIcon, & img": {
            display: "none",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto"
        },
        "& img": {
            width: 130,
            height: 130,
            objectFit: "contain",
            display: "block"
        },
        "&:hover .uploadIcon": {
            display: "block"
        }
    },
    label: {
        visibility: "hidden"
    },
    paper: {
        backgroundColor: "#fff",
        borderRadius: 0
    },
    circleIconParent: {
        backgroundColor: primary[500],
        padding: "8%",
        borderRadius: "50%",
        cursor: "pointer",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#0e90c1"
        }
    },
    spinner: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto"
    }
};

export default withStyles(uploadAvatarStyle)(UploadAvatar);
