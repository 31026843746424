import React, { Component } from "react";
import styled, { css } from "styled-components";

import { getStaticUrl } from "js/config";
import { WithLabel } from "js/Components/WithLabel";
import { _ } from "js/vendor";
import { themeColors } from "js/react/sharedStyles";

import { ToolTip } from "./ToolTip";
import { Icon } from "./Icon";

const ImageOptionListContainer = styled.div`
    display: grid;
    flex-wrap: wrap;
    width: 100%;
`;

export class ImageOptionList extends Component {
    handleMouseEnter = value => {
        if (this.props.onHover) {
            this.props.onHover(value);
        }
    }

    handleMouseLeave = () => {
        if (this.props.onHover) {
            this.props.onHover(null);
        }
    }

    render() {
        const { children, onChange = () => {}, value, size, cols, gap = 15 } = this.props;

        let styles = {};
        if (cols) {
            styles = {
                display: "grid",
                gridTemplateColumns: `repeat(${cols}, ${size ? size + "px" : "1fr"})`,
                gap: `${gap}px`,
            };
        } else {
            styles = {
                display: "flex",
                gap: `${gap}px`,
            };
        }

        return (
            <ImageOptionListContainer
                style={styles}
            >
                {React.Children.map(children, child => {
                    if (child) {
                        let props = {
                            onClick: child.props.onClick ? child.props.onClick : () => {
                                onChange(child.props.value);
                            },
                            onMouseEnter: () => {
                                this.handleMouseEnter(child.props.value);
                            },
                            onMouseLeave: () => {
                                this.handleMouseLeave();
                            },
                            size: !cols ? size : null,
                        };

                        if (child.props.selected === undefined) {
                            props.selected = value !== undefined && child.props.value === (typeof value == "function" ? value() : value);
                        }

                        return React.cloneElement(child, props);
                    }
                })}
            </ImageOptionListContainer>
        );
    }
}

export const ImageOptionMenu = styled.div`
    display: flex;
    gap: 20px;
    padding: 20px;
`;

const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ background }) => background ?? "white"};
    outline: ${props => props.selected ? `solid 1px ${themeColors.ui_blue}` : "none"};
    cursor: ${props => props.disabled ? "default" : "pointer"};
    border-radius: ${props => props.round ? "50%" : "0"};

    img {
        width: 100%;
        object-fit: contain;
        vertical-align: middle;
        opacity: ${props => props.disabled ? 0.5 : 1};
        filter: ${props => props.disabled ? "grayscale(100%)" : "none"};
    }
    
    .icon {
        min-height: 40px;
    }
`;

const ImageOptionContainer = styled.div`
    ${props => !props.disabled && css`
        &:hover {
            .withlabel {
                label {
                    color: ${themeColors.ui_blue};
                }
            }
        }     
    `};
`;

export class ImageOption extends Component {
    render() {
        const { size, square, selected = false, url, icon, label, labelGap = 7, onClick, onMouseEnter, onMouseLeave, disabled, toolTip, disabledTooltip, children, background = null, round } = this.props;

        let width = size;
        let height = square ? size : null;

        let Content;
        if (icon || (children && React.Children.toArray(children)[0].type.name === "Icon")) {
            Content = icon ? <Icon>{icon}</Icon> : children;
            height = width;
        } else if (url) {
            Content = <img src={getStaticUrl(url)} />;
        } else {
            Content = children;
        }

        return (
            <ToolTip
                title={disabled ? disabledTooltip : toolTip}
                placement="bottom"
            >
                <ImageOptionContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} disabled={disabled}>
                    <WithLabel below center gap={labelGap} label={label} small fillWidth onClick={disabled ? null : onClick} selected={selected}>
                        <ImageContainer disabled={disabled} selected={selected} background={background} round={round} style={{ width, height }}>
                            {Content}
                        </ImageContainer>
                    </WithLabel>
                </ImageOptionContainer>
            </ToolTip>
        );
    }
}

