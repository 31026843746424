import React, { Component } from "react";
import styled from "styled-components";

import { DialogActions, DialogTitle, Button } from "@material-ui/core";

import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";

import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.TEAMS);

const MessageText = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.5px;
    color: #333333;

    margin-bottom: ${({ withMargin }) => withMargin ? "20px" : "0"};

    >a {
        cursor: pointer;
        color: #11a9e2;
    }
`;

export default class ManageAvailableTeamSeatsDialog extends Component {
    constructor() {
        super();

        this.state = {
            fetching: false
        };
    }

    handleInviteMember = () => {
        const { closeDialog, showAddMemberDialog } = this.props;
        showAddMemberDialog();
        closeDialog();
    }

    handleRemoveSeats = async () => {
        const { closeDialog, removeAvailableSeats } = this.props;

        this.setState({ fetching: true });

        removeAvailableSeats()
            .catch(err => logger.error(err, "[ManageAvailableTeamSeatsDialog] removeAvailableSeats() failed"))
            .then(() => closeDialog());
    }

    render() {
        const { closeDialog, unusedSeatCount } = this.props;
        const { fetching } = this.state;

        return (
            <BeautifulDialog PaperProps={{ style: { maxWidth: 550 } }}>
                <FetchingClickShield visible={fetching} backgroundColor="white" />
                <DialogTitle>Remove unused seats?</DialogTitle>
                <DialogContent>
                    <MessageText withMargin>This will remove {unusedSeatCount} unused {"seat".pluralize(unusedSeatCount > 1)} from you plan. To add a new seat, <a onClick={this.handleInviteMember}>invite a member</a>.</MessageText>
                    <MessageText>This change will be reflected in your next bill.</MessageText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={closeDialog} style={{ marginRight: 30 }}>
                        Nevermind
                    </Button>
                    <Button color="primary" onClick={this.handleRemoveSeats}>
                        Remove seats
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
