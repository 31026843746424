import React, { forwardRef } from "react";
import styled from "styled-components";
import { Icon } from "./Icon";

export const WidgetButtonPosition = {
    CORNER: "corner",
    INNER: "inner",
    OUTER: "outer"
};

const Container = styled.div.attrs(({ left, top }) => ({
    style: {
        left: typeof left === "number" ? `${left}px` : left,
        top: typeof top === "number" ? `${top}px` : top,
    }
}))`
    position: absolute;

    transform: translate(-50%, -50%);
    z-index: 1;

    width: 16px;
    height: 16px;

    cursor: ${({ cursor }) => cursor};

    display: flex;
    justify-content: center;
    align-items: center;

    background: ${({ outlined }) => outlined ? "#50bbe6" : "transparent"};;
    border-radius: 50%;

    pointer-events: all;
    
    .bai-icon {
        color: white !important;
        font-size: 12px;
    }

    > span {
        color: ${({ outlined }) => outlined ? "#ffffff" : "#50bbe6"};
        font-size: 15px;
    }

    &:hover {
        > span {
            color: #ffffff;
        }
        background: ${({ outlined, hoverColor }) => outlined ? hoverColor : "#50bbe6"};;
    }


    &:before {
        position: absolute;
        top: ${({ mousePadding }) => -mousePadding / 2}px;
        left: ${({ mousePadding }) => -mousePadding / 2}px;
        content: "";
        border-radius: 50%;
        background: rgba(255,0,0,0);
        width: ${({ mousePadding }) => mousePadding + 18}px;
        height: ${({ mousePadding }) => mousePadding + 18}px;
    }
`;

const WidgetButton = forwardRef(function RoundIconButton({ className, cursor = "pointer", hoverColor = "#50bbe6", onMouseDown, mousePadding = 5, onClick, left, top, icon, outlined = false }, ref) {
    return (
        <Container className={className} onClick={onClick} onMouseDown={onMouseDown} hoverColor={hoverColor} left={left} top={top} outlined={outlined} ref={ref} cursor={cursor} mousePadding={mousePadding}>
            <Icon>{icon}</Icon>
        </Container>
    );
});

export default WidgetButton;
