import React, { Component } from "react";
import styled from "styled-components";
import {
    Button,
    DialogActions
} from "@material-ui/core";

import { tinycolor } from "js/vendor";
import { BeautifulDialog, DialogContent, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import DialogTitleWithClose from "js/react/components/DialogTitleWithClose";
import { Gap20 } from "js/react/components/Gap";
import { BlueButton } from "js/react/components/UiComponents";
import { Divider } from "js/Components/Divider";
import { Dropdown } from "js/Components/Dropdown";
import { MenuItem } from "js/Components/Menu";
import { Slider } from "js/Components/Slider";
import { WithLabel } from "js/Components/WithLabel";
import { Gradient } from "../components/Gradient";
import { FourCornerGradientEditor } from "./FourCornerGradientEditor";
import { GradientStop } from "./GradientStop";
import { GradientEditorSlider } from "./GradientEditorSlider";

const Container = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 30px;
`;

const PropertyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 300px;
    height: 100%;

    .with-label {
        width: 100%;
    }

    .bai-slider {
        width: 200px;
    }
`;

const GradientPreview = styled.div`
    position: relative;
    width: 100%;
    height: 495px;
    border: 1px solid #ccc;
    flex-shrink: 0;
`;

const defaultGradientColors = [
    tinycolor("#FF0000"),
    tinycolor("#8B00FF"),
    tinycolor("#FFAA00"),
    tinycolor("#0B9DE8")
];

const defaultGradient = {
    id: "gradient",
    type: "linear",
    angle: 0,
    stops: [
        new GradientStop(0, defaultGradientColors[0]),
        new GradientStop(100, defaultGradientColors[1])
    ],
    noise: 0
};

export class GradientBuilder extends Component {
    constructor(props) {
        super(props);

        // Convert incoming gradient stops to use GradientStop class
        let gradient = { ...props.gradient };
        if (gradient?.stops?.length > 0) {
            gradient.stops = gradient?.stops?.map(stop=>new GradientStop(stop.position, stop.color));
            this.state = {
                ...gradient
            };
        } else {
            this.state = {
                ...defaultGradient,
            };
        }
    }

    handleChangeType = type => {
        this.setState({ type });

        switch (type) {
            case "linear":
                this.setState({ angle: 180 });
                break;
            case "radial":
                break;
            case "four-corner":
                this.setState({
                    stops: [
                        new GradientStop(0, (this.state.stops.length > 0) ? this.state.stops[0].color : defaultGradientColors[0]),
                        new GradientStop(25, (this.state.stops.length > 1) ? this.state.stops[1].color : defaultGradientColors[1]),
                        new GradientStop(75, (this.state.stops.length > 2) ? this.state.stops[2].color : defaultGradientColors[2]),
                        new GradientStop(100, (this.state.stops.length > 3) ? this.state.stops[3].color : defaultGradientColors[3]),
                    ]
                });
                break;
        }
    }

    handleSaveChange = () => {
        const { type, angle, stops, noise } = this.state;

        // Convert stops from class to save in database
        const newStops = stops.map(stop=>stop.toJSON());

        // create gradient object
        let gradient = {
            type,
            angle,
            stops: newStops,
            noise,
        };

        this.props.closeDialog(gradient);
    }

    handleCancel = () => {
        this.props.closeDialog();
    }

    render() {
        const { stops, type, angle, noise } = this.state;

        return (
            <BeautifulDialog closeDialog={this.handleCancel} maxWidth="lg">
                <DialogTitleWithClose onClose={this.handleCancel}>Custom Gradient Background</DialogTitleWithClose>
                <DialogContent>
                    <Gap20 />
                    <Container>
                        <GradientPreview>
                            <Gradient gradient={this.state} />
                        </GradientPreview>
                        <PropertyContainer>
                            <WithLabel label="Type">
                                <Dropdown value={type} onChange={this.handleChangeType}>
                                    <MenuItem value="linear">Linear</MenuItem>
                                    <MenuItem value="radial">Radial</MenuItem>
                                    <MenuItem value="four-corner">Four Corner</MenuItem>
                                </Dropdown>
                            </WithLabel>
                            {type == "linear" && (
                                <>
                                    <WithLabel label="Angle">
                                        <Slider value={angle}
                                            showInput
                                            onChange={angle => this.setState({ angle })}
                                            min={0} max={360} step={1}
                                        />
                                    </WithLabel>
                                    <Divider />
                                    <WithLabel above left label="Stops">
                                        <GradientEditorSlider
                                            stops={stops}
                                            onChange={stops => this.setState({ stops })} />
                                    </WithLabel>
                                </>
                            )}

                            {type == "radial" && (
                                <>
                                    <Divider />
                                    <WithLabel above left label="Stops">
                                        <GradientEditorSlider
                                            stops={stops}
                                            onChange={stops => this.setState({ stops })} />
                                    </WithLabel>
                                </>
                            )}

                            {type == "four-corner" && (
                                <>
                                    <Divider />
                                    <WithLabel label="Colors" left>
                                        <FourCornerGradientEditor
                                            stops={stops}
                                            onChange={stops => this.setState({ stops })}
                                        />
                                    </WithLabel>
                                </>
                            )}

                            <Divider />

                            <WithLabel label="Noise">
                                <Slider value={noise}
                                    onChange={noise => this.setState({ noise })}
                                    showInput percent
                                    min={0} max={1} step={0.01} />
                            </WithLabel>
                        </PropertyContainer>

                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel}>
                        Cancel
                    </Button>
                    <BlueButton onClick={this.handleSaveChange}>
                        Save
                    </BlueButton>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
