import React, { Component } from "reactn";
import { BlueButton } from "js/react/components/UiComponents";
import { app } from "js/namespaces";
import { trackActivity } from "js/core/utilities/utilities";

class LogOutButton extends Component {
    handleLogOutClicked = () => {
        trackActivity("Auth", "logout", null, null, { workspace_id: "all" }, { audit: true });
        window.location = "/logout";
    };

    render() {
        return (
            <BlueButton onClick={this.handleLogOutClicked}>
                Log out
            </BlueButton>
        );
    }
}

export default LogOutButton;
