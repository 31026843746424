import React, { Component } from "react";

import Api from "js/core/api";
import { app } from "js/namespaces";
import {
    UIPane,
    UIPaneContents,
    UIPaneHeader,
    Section,
} from "js/react/components/UiComponents";
import { BlueSwitch } from "js/react/components/Switch";
import AppController from "js/core/AppController";
import { FeatureType } from "common/features";
import { getUserOrgPermissions } from "js/core/models/planPermissions";
import { FlexBox } from "../../../components/LayoutGrid";
import { Gap10 } from "../../../components/Gap";
import { ShowInputDialog, ShowWarningDialog } from "../../../components/Dialogs/BaseDialog";
import IconButton from "../../Editor/Components/IconButton";
import LogsViewer from "./LogsViewer";

export default class AdvancedSettingPane extends Component {
    initialState = {
        organization: {},
    };

    state = { ...this.initialState };

    async componentDidMount() {
        const { organizationId } = this.props;
        const isEnterprise = await Api.orgIsEnterprise.get({ orgId: organizationId });

        // Get default permissions for the user's role
        const permissions = await getUserOrgPermissions(organizationId);
        this.setState({
            permissions,
            isEnterprise,
            customURL: AppController.currentTeam.get("customPlayerURLTemplate")
        });
    }

    handleWorkspaceSettingsChange = stateDelta => {
        AppController.currentTeam.update({
            workspaceSettings: {
                ...(AppController.currentTeam.get("workspaceSettings") ?? {}),
                ...stateDelta
            }
        });
        this.forceUpdate();
    }

    handleChangeCustomPlayerUrl = async () => {
        const value = await ShowInputDialog({
            title: "Set Custom Player URL",
            message: "Enter the custom player URL template",
            value: AppController.currentTeam.get("customPlayerURLTemplate")
        });

        if (!value.contains("linkId")) {
            ShowWarningDialog({
                title: "Invalid Custom Player URL",
                message: <><p>The URL must contain a 'linkId' placeholder.</p>For more information, <a href="https://support.beautiful.ai/hc/en-us/articles/26598025191565">click here.</a></>
            });
            return;
        }

        this.setState({ customURL: value });
        AppController.currentTeam.update({ customPlayerURLTemplate: value });
    }

    handleRemoveCustomPlayerUrl = () => {
        this.setState({ customURL: null });
        AppController.currentTeam.update({ customPlayerURLTemplate: null });
    }

    render() {
        const { organizationId } = this.props;
        const { permissions, isEnterprise, customURL } = this.state;

        const hasAdvancedSettings = app.user.features.isFeatureEnabled(FeatureType.ADVANCED_SETTINGS, organizationId);

        // We don't call app.user.features.isFeatureEnabled() here because there's a
        //   delay due to the update needing to be loaded and formatted before the
        //   changes are reflected. So we access the appState directly instead.
        const workspaceSettings = AppController.currentTeam.get("workspaceSettings") || {};
        const isOwner = app.user.getIsTeamOwner(organizationId);

        let {
            prohibitExternalWorkspaceMovement,
            // NOTE: Hidden until google account linking no longer has loopholes across workspaces
            // prohibitGoogleDriveAccountLinking,
            prohibitExternalWorkspaceCollaboration,
            prohibitGenerativeAI,
        } = workspaceSettings;

        // if advanced settings are disabled, we should reset the values to their defaults
        if (
            !isEnterprise &&
            !hasAdvancedSettings &&
            permissions
        ) {
            prohibitExternalWorkspaceMovement = !!permissions.prohibitExternalWorkspaceMovement;
            prohibitExternalWorkspaceCollaboration = !!permissions.prohibitExternalWorkspaceCollaboration;
            prohibitGenerativeAI = !!permissions.prohibitGenerativeAI;
        }

        // if a user doesn't have the feature advanced settings, they shouldn't be able to change the settings
        return (
            <UIPane>
                <UIPaneHeader>Advanced Settings</UIPaneHeader>
                <UIPaneContents>
                    <Section title="Content Distribution Settings">
                        <BlueSwitch
                            id="prohibitExternalWorkspaceMovement"
                            checked={prohibitExternalWorkspaceMovement}
                            onChange={() => this.handleWorkspaceSettingsChange({ prohibitExternalWorkspaceMovement: !prohibitExternalWorkspaceMovement })}
                            label="Prohibit moving presentations from this workspace"
                            tooltipTitle="Your team's presentations cannot be transferred to other workspaces, including Personal."
                        />
                        {/*
                            Hiding this setting until we determine a more robust way to lock down google accout linking.
                              Currently if a user links their account on their personal workspace, it also be linked in other
                              workspaces, regardless of this setting. This loophole largely makes this setting pointless.

                            <BlueSwitch
                                id="prohibitGoogleDriveAccountLinking"
                                checked={prohibitGoogleDriveAccountLinking}
                                onChange={() => this.handleContentDistributionChange({ prohibitGoogleDriveAccountLinking: !prohibitGoogleDriveAccountLinking })}
                                label="Prohibit account linking to Google Drive"
                                tooltipTitle="Disable this integration for users in this workspace and keep your team from syncing their presentations with Google Drive accounts."
                            />
                        */}
                        <BlueSwitch
                            id="prohibitExternalWorkspaceCollaboration"
                            checked={prohibitExternalWorkspaceCollaboration}
                            onChange={() => this.handleWorkspaceSettingsChange({ prohibitExternalWorkspaceCollaboration: !prohibitExternalWorkspaceCollaboration })}
                            label="Prohibit collaboration with users outside this workspace"
                            tooltipTitle="Team Members can only invite team members to collaborate on presentations within this workspace."
                        />
                        {
                            isOwner &&
                            <BlueSwitch
                                id="prohibitGenerativeAI"
                                checked={!prohibitGenerativeAI}
                                onChange={() => this.handleWorkspaceSettingsChange({ prohibitGenerativeAI: !prohibitGenerativeAI })}
                                label="Enable Generative AI"
                                tooltipTitle="Allow your team access to generative AI features."
                            />
                        }
                    </Section>
                    <Section title="Custom Player URL" description={
                        <>
                            <p><strong>WARNING: </strong>This feature requires additional configuration on your website and incorrect use can prevent player links from working. For more information, <a href="https://support.beautiful.ai/hc/en-us/articles/26598025191565">click here.</a></p>
                        </>
                    }>
                        <FlexBox left>
                            <label><strong>Custom Player URL Template: </strong></label>
                            <Gap10 />
                            <label>{customURL ?? "[use default]"}</label>
                            <Gap10 />
                            <IconButton icon="edit" onClick={this.handleChangeCustomPlayerUrl} />
                            {customURL && <IconButton icon="delete" onClick={this.handleRemoveCustomPlayerUrl} />}
                        </FlexBox>
                    </Section>
                    <LogsViewer organizationId={organizationId} />
                </UIPaneContents>
            </UIPane>
        );
    }
}
