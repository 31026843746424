export const selectFile = async inputOptions => {
    inputOptions = {
        accept: "*",
        hidden: true,
        multiple: false,
        ...inputOptions,
    };
    return new Promise((resolve, reject) => {
        let isUnresolved = true;

        const inputElemenet = document.createElement("input");
        inputElemenet.style.display = "none";
        inputElemenet.type = "file";
        inputElemenet.accept = inputOptions.accept;
        inputElemenet.hidden = inputOptions.hidden;
        inputElemenet.multiple = inputOptions.multiple;

        inputElemenet.addEventListener("change", () => {
            if (inputElemenet.files) {
                let result = inputOptions.multiple
                    ? inputElemenet.files
                    : inputElemenet.files[0];
                isUnresolved && resolve(result);
                isUnresolved = false;
            }
        });

        const teardown = () => {
            document.body.removeEventListener("focus", teardown, true);
            setTimeout(() => {
                document.body.removeChild(inputElemenet);
                isUnresolved && resolve(null);
                isUnresolved = false;
            }, 1000); // Wait a second for any files parsing to process before removing the input
        };
        document.body.addEventListener("focus", teardown, true);

        document.body.appendChild(inputElemenet);
        inputElemenet.click();
    });
};
