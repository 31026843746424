import React from "react";
import Icon from "../../../components/Icon";

const SubscriptionStatusIcon = ({ status, tooltip }) => {
    let statusType = "warning";
    let icon = <Icon iconName="cancel" />;
    switch (status) {
        case "active":
        case "succeeded":
            statusType = "success";
            icon = <Icon iconName="done" />;
            break;
        case "incomplete":
        case "unpaid":
            statusType = "warning";
            icon = <Icon iconName="report_problem" />;
            break;
        case "incomplete_expired":
        case "failed":
            statusType = "error";
            icon = <Icon iconName="close" />;
            break;
    }

    return (
        <div
            className={`subscription_status_icon ${statusType} ${status}`}
            title={tooltip}
        >
            {icon}
        </div>
    );
};

export default SubscriptionStatusIcon;
