import { GlobalState } from "bai-react-global-state";
import { ds } from "js/core/models/dataService";
import { getPresentation } from "js/core/models/presentation";
import { app } from "js/namespaces";
export const docEditorState = new GlobalState({});

export class DocumentEditorController {
    static async loadDocument(documentId) {
        let document = await getPresentation(documentId, { permission: "write", autoSync: true });

        ds.selection.presentation = document;

        await document.prepareSlides(true);

        let theme = await app.themeManager.loadTheme(document);

        docEditorState.updateState({
            currentDocument: document,
            currentTheme: theme,
            currentPage: document.slides.at(0)
        });

        return document;
    }

    static setCurrentPage(page) {
        docEditorState.updateState({
            currentPage: page
        });
    }
}
