import Api from "js/core/api";

/**
 * Opens a popup and waits for it to emit a message
 * If the message contains error field or if the popup was
 * closed before the message was received, then the promise is rejected
 */
function openPopupAndWaitForResponse(url, title) {
    return new Promise((resolve, reject) => {
        const width = Math.min(700, screen.width * 0.9);
        const height = Math.min(850, screen.height * 0.9);
        const left = (screen.width / 2) - (width / 2);
        const top = (screen.height / 2) - (height / 2);
        const popupWindow = window.open(
            url,
            title,
            [
                "toolbar=no",
                "location=no",
                "directories=no",
                "status=no",
                "menubar=no",
                "scrollbars=no",
                "resizable=no",
                "copyhistory=no",
                `width=${width}`,
                `height=${height}`,
                `top=${top}`,
                `left=${left}`
            ].join(", ")
        );

        let removeEventListener = () => { };

        // We have to use an interval because Slack reloads the page and window.onunload
        // (and onbeforeunload) get wiped so we can't use them
        const interval = setInterval(() => {
            if (popupWindow.closed) {
                reject(new Error("popup_closed_by_user"));

                clearInterval(interval);
                removeEventListener();
            }
        }, 500);

        const onResponseMessage = messageEvent => {
            const { errorMessage, isFromRedirectHandler } = messageEvent.data;
            // This can happen if the message was sent by a 3rd party page that is
            // opened in the popup, we mark our messages with isFromRedirectHandler parameter,
            // so if it's missing then we just ignore the message
            if (!isFromRedirectHandler) {
                return;
            }

            clearInterval(interval);
            removeEventListener();

            if (errorMessage) {
                reject(new Error(errorMessage));
            } else {
                resolve();
            }
        };

        window.addEventListener("message", onResponseMessage, false);
        removeEventListener = () => window.removeEventListener("message", onResponseMessage, false);
    });
}

/**
 * Enables Slack integration for current user
 * Opens a popup with Slack oauth auth request
 */
export async function enable() {
    const { url } = await Api.slackOauthAuthorizeUrl.get({ state: "isPopup" });
    await openPopupAndWaitForResponse(url, "Slack");
}

/**
 * Disables Slack integration for current user
 */
export async function disable() {
    await Api.slackDisable.post();
}
