import React, { Component } from "react";
import styled, { css } from "styled-components";

import { ElementTextBlockPositionType, HeaderPositionType, ShowFooterType, TrayType } from "../../../common/constants";
import { ToggleSwitch } from "../../Components/ToggleSwitch";
import { ToolTip } from "../../Components/ToolTip";
import { WithLabel } from "../../Components/WithLabel";
import { getStaticUrl } from "../../config";
import { deepClone } from "../../core/utilities/extensions";
import { PropertyPanelContainer, PropertySection } from "../../EditorComponents/PropertyPanel";
import BadFitDialog from "../../react/components/Dialogs/BadFitDialog";
import { ShowDialogAsync } from "../../react/components/Dialogs/BaseDialog";
import { FlexSpacer } from "../../react/components/Gap";
import { FlexBox } from "../../react/components/LayoutGrid";
import { _ } from "../../vendor";
import PresentationEditorController from "../PresentationEditor/PresentationEditorController";
import { CalloutsPane } from "./CalloutsPane";
import { PropertyPanelHeader } from "./PropertyPanelHeader";

const ShowMoreButton = styled.div`
    font-size: 11px;
    font-weight: 600;
    color: #444;
    cursor: pointer;
`;
const Selected = css`
    outline: solid 2px #50bbe6;
`;

const Disabled = css`
    opacity: .4;
    pointer-events: none;
`;

const ThumbnailItem = styled.div`
    position: relative;
    //width: 95px;
    margin-right: 5px;

    ${props => props.selected ? Selected : ""}
    ${props => props.disabled ? Disabled : ""}
    > div {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border: solid 1px #888;
    }
`;

const Label = styled.label`
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .9px;
    text-transform: uppercase;
    color: #333;
`;

const Options = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 0px 40px 0px 0px;
`;

const Image = styled.img`
    width: 100%;
    display: inline;
    vertical-align: middle;
`;

const Panel = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 5px;
`;

class RenderThumbnail extends Component {
    render() {
        const { icon, enabled, selected, callback } = this.props;
        return (
            <ThumbnailItem disabled={enabled == false} selected={selected == true} className={`${icon}`}
                onClick={() => callback(!selected)}>
                <Image src={`${getStaticUrl(`/images/ui/trays/${icon}.png`)}`} />
                <div></div>
            </ThumbnailItem>
        );
    }
}

class LayoutPanel extends Component {
    state = {
        showMoreCallouts: false
    };

    renderHeaderPanel = (canvas, template, layout) => {
        const renderHeaderOption = (position, enabled) => (<RenderThumbnail
            icon={`header_${position}`}
            enabled={!!layout.trayLayout &&
            layout.trayLayout !== TrayType.NONE &&
            (position == HeaderPositionType.LEFT || position == HeaderPositionType.RIGHT) ? false : enabled}
            selected={layout.headerPosition === position}
            callback={value => {
                if (template.elementType == "AuthoringCanvas") {
                    let existingHeader = canvas.model.elements.primary.elements.find(e => e.type == "header");
                    if (existingHeader && existingHeader.headerPosition == position) {
                        canvas.model.elements.primary.elements.remove(existingHeader);
                    } else if (existingHeader) {
                        existingHeader.headerPosition = position;
                        existingHeader.x = 0;
                        existingHeader.y = 0;
                        existingHeader.width = position == HeaderPositionType.TOP ? 1280 : 300;
                        existingHeader.height = position == HeaderPositionType.TOP ? 200 : 720;
                    } else {
                        canvas.model.elements.primary.elements.push({
                            type: "header",
                            headerPosition: position,
                            isLocked: false,
                            x: 0,
                            y: 0,
                            width: position == HeaderPositionType.TOP ? 1280 : 300,
                            height: position == HeaderPositionType.TOP ? 200 : 720,
                        });
                    }
                    canvas.updateCanvasModel(false);
                    return;
                }

                if (value) {
                    layout.headerPosition = position;
                } else {
                    layout.headerPosition = HeaderPositionType.NONE;
                }
                canvas.updateCanvasModel(false).then(() => {
                    //this.render();
                    this.forceUpdate();
                }).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: `Sorry, the content on your slide won't fit with header on ${position}`,
                    });
                });
            }}
        />);

        // If header not allowed return nothing
        if (!template.allowHeader) return null;

        const topPosition = template.availableHeaderPositions.contains(HeaderPositionType.TOP);
        const leftPosition = template.availableHeaderPositions.contains(HeaderPositionType.LEFT);

        if (!topPosition && !leftPosition) return null;

        return (
            <Panel>
                <Label>Header</Label>
                <Options>
                    {topPosition && renderHeaderOption(HeaderPositionType.TOP, true)}
                    {leftPosition && renderHeaderOption(HeaderPositionType.LEFT, layout.trayLayout === TrayType.NONE || !layout.trayLayout)}
                </Options>
            </Panel>
        );
    }

    renderTextLayoutPanel = (canvas, template, layout) => {
        const isEmpty = !template.allowElementTextInline && !template.allowElementTextTray &&
            !template.allowElementAttribution && !template.availableTrayLayouts.contains(TrayType.LEFT_INLINE) &&
            !template.availableTrayLayouts.contains(TrayType.RIGHT_INLINE);

        if (isEmpty) return;

        const renderAllowElementTextInline = () => {
            if (!template.allowElementTextInline) return;

            const testModel = deepClone(canvas.model);
            testModel.layout.elementTextBlockPosition = ElementTextBlockPositionType.TRAY;
            const isFit = canvas.layouter.tryLayout(testModel);

            return (<RenderThumbnail
                icon="bottom_inline_text"
                enabled={isFit}
                selected={layout.elementTextBlockPosition == ElementTextBlockPositionType.INLINE}
                callback={value => {
                    if (value) {
                        if (layout.elementTextBlockPosition == ElementTextBlockPositionType.TRAY) {
                            canvas.model.elements.elementTextBlock = {
                                elementTextBlock: {
                                    blocks: canvas.model.elements.elementTextBlock.items[0].childElement.text.blocks
                                }
                            };
                        }

                        layout.elementTextBlockPosition = ElementTextBlockPositionType.INLINE;
                    } else {
                        layout.elementTextBlockPosition = ElementTextBlockPositionType.NONE;
                    }
                    canvas.updateCanvasModel(false).then(() => {
                        this.forceUpdate();
                    }).catch(() => {
                        ShowDialogAsync(BadFitDialog, {
                            title: "Sorry, the content on your slide won't fit with an extra text tray",
                        });
                    });
                }}
            />);
        };

        const renderAllowElementTextTray = () => {
            if (!template.allowElementTextTray) return;

            const testModel = deepClone(canvas.model);
            testModel.layout.elementTextBlockPosition = ElementTextBlockPositionType.TRAY;
            const isFit = canvas.layouter.tryLayout(testModel);

            return (<RenderThumbnail
                icon="bottom_tray_text"
                enabled={isFit}
                selected={layout.elementTextBlockPosition == ElementTextBlockPositionType.TRAY}
                callback={value => {
                    if (value) {
                        layout.elementTextBlockPosition = ElementTextBlockPositionType.TRAY;
                        canvas.model.elements.elementTextBlock = {
                            layoutEngineType: "bottom_tray",
                            gridLayout: {
                                cols: 12,
                                rows: 12,
                                cells: [{ x: 0, y: 0, width: 12, height: 12 }]
                            },
                            items: [{
                                componentType: "TextAndImage",
                                cellColor: "background_accent",
                                childElement: {
                                    text: {
                                        blocks: canvas.model.elements.elementTextBlock?.elementTextBlock?.blocks ?? [{
                                            type: "text",
                                            textStyle: "title",
                                            evenBreak: true,
                                        }]
                                    }
                                }
                            }],
                            // trayHeight: 110
                        };
                    } else {
                        layout.elementTextBlockPosition = ElementTextBlockPositionType.NONE;
                    }
                    canvas.updateCanvasModel(false).then(() => {
                        this.forceUpdate();
                    }).catch(() => {
                        ShowDialogAsync(BadFitDialog, {
                            title: "Sorry, the content on your slide won't fit with an extra text tray",
                        });
                    });
                }}
            />);
        };

        let trayModel = canvas.model.elements.tray;
        let blockModel;
        if (trayModel && trayModel.items && trayModel.items.length) {
            blockModel = trayModel.items[0];
        }

        return (<Panel>
            <Label>Bottom Tray</Label>
            <Options>
                {renderAllowElementTextTray()}
                {renderAllowElementTextInline()}
            </Options>
        </Panel>);
    }

    renderTrayLayoutPanel = (canvas, template, layout) => {
        if (template.availableTrayLayouts.length == 0) {
            return null;
        }

        let trayModel = canvas.model.elements.tray;
        let blockModel;
        let hasMedia = false;
        let hasText = false;
        if (trayModel && trayModel.items && trayModel.items.length) {
            blockModel = trayModel.items[0];
            hasMedia = blockModel.content_value != null;
            hasText = (blockModel.blocks && blockModel.blocks.length > 0) || (blockModel.contentBlocks && blockModel.contentBlocks.length > 0);
        }

        const renderAvailableTrayLayouts = template.availableTrayLayouts.map((trayLayout, index) => {
            let icon = trayLayout;
            if (template.allowHeader && layout.headerPosition == HeaderPositionType.TOP) {
                icon += "_header";
            } else {
                icon += "_noheader";
            }

            return (<RenderThumbnail
                key={index}
                icon={icon}
                label={trayLayout.split("_")[0].toTitleCase()}
                enabled={layout.headerPosition != HeaderPositionType.LEFT}
                selected={layout.trayLayout == trayLayout}
                callback={value => {
                    if (value) {
                        // set the new tray layout
                        layout.trayLayout = trayLayout;

                        if (!canvas.model.elements.tray) {
                            canvas.model.elements.tray = {
                                items: [{
                                    cellColor: "background_accent",
                                    componentType: "TextAndImage",
                                }]
                            };
                            trayModel = canvas.model.elements.tray;
                        }

                        canvas.updateCanvasModel(true)
                            .then(() => {
                                this.forceUpdate();
                            })
                            .catch(() => {
                                ShowDialogAsync(BadFitDialog, {
                                    title: "Sorry, the content on your slide won't fit with an extra image or text block",
                                });
                            });
                    } else {
                        if (hasText) {
                            blockModel.content_value = null;
                            blockModel.content_type = null;
                        } else {
                            // remove tray model
                            canvas.model.elements.tray = null;
                            canvas.model.layout.trayLayout = TrayType.NONE;
                        }

                        canvas.updateCanvasModel(true)
                            .then(() => {
                                this.forceUpdate();
                            })
                            .catch(() => {
                                ShowDialogAsync(BadFitDialog, {
                                    title: "Sorry, the content on your slide won't fit with an extra image or text block",
                                });
                            });
                    }
                }}
            />);
        });

        return (
            <Panel>
                <Label>Side Bar</Label>
                <Options>
                    {renderAvailableTrayLayouts}
                </Options>
            </Panel>
        );
    };

    render() {
        const { currentCanvasController } = this.props;
        const { showMoreCallouts } = this.state;
        const canvas = currentCanvasController.canvas;
        const template = canvas.layouter.template;
        const layout = canvas.model.layout;

        const canvasElement = canvas.getCanvasElement();
        let disabledFooterTooltip;
        if (!template.allowFooter) {
            disabledFooterTooltip = "This slide template does not support footers.";
        } else if (canvas.model.layout.elementTextBlockPosition == ElementTextBlockPositionType.TRAY) {
            disabledFooterTooltip = "Footer is not available when your slide has a bottom tray.";
        }

        return (<PropertyPanelContainer>
            <PropertyPanelHeader>Add Content</PropertyPanelHeader>
            <PropertySection>
                {this.renderHeaderPanel(canvas, template, layout)}
                {this.renderTrayLayoutPanel(canvas, template, layout)}
                {this.renderTextLayoutPanel(canvas, template, layout)}
            </PropertySection>
            <PropertySection>
                <FlexBox left>
                    <Label>Callouts</Label>
                    <FlexSpacer />
                    {!!canvas.getCalloutsElement() && <ShowMoreButton onClick={() => this.setState({ showMoreCallouts: !showMoreCallouts })}>Show {showMoreCallouts ? "Less" : "More"}</ShowMoreButton>}
                </FlexBox>
                <CalloutsPane {...this.props} showMore={showMoreCallouts} />
            </PropertySection>
            <PropertySection>
                <WithLabel label="Show Attribution/Footnote">
                    <ToggleSwitch value={canvas.model.layout.showElementAttribution}
                        disabled={!template.allowElementAttribution}
                        disabledTooltip="This smart slide does not support attribution trays."
                        onChange={async () => {
                            canvas.model.layout.showElementAttribution = !canvas.model.layout.showElementAttribution;
                            canvas.updateCanvasModelWithErrorDialog(true);
                        }} />
                </WithLabel>
                <WithLabel label="Show Footer">
                    <ToolTip title={disabledFooterTooltip}>
                        <ToggleSwitch
                            value={canvasElement.showFooter}
                            disabled={!template.allowFooter || canvas.model.layout.elementTextBlockPosition == ElementTextBlockPositionType.TRAY}
                            onChange={async value => {
                                canvas.model.layout.showFooter = value ? ShowFooterType.ON : ShowFooterType.OFF;
                                await canvas.updateCanvasModelWithErrorDialog(false);
                            }}
                        />
                    </ToolTip>
                </WithLabel>
            </PropertySection>
        </PropertyPanelContainer>);
    }
}

export default PresentationEditorController.withState(LayoutPanel);

