import styled, { css } from "styled-components";
import React, { Component } from "react";
import {
    DialogActions,
    Button,
    DialogTitle,
    MenuItem,
    Select
} from "@material-ui/core";
import { TextField } from "@material-ui/core";

import { app } from "js/namespaces";
import { trackActivity } from "js/core/utilities/utilities";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import { BlueButton } from "js/react/components/UiComponents";

const EVENT_CATEGORY = "CancelPlanSurvey";

const QUESTIONS = [
    {
        question: "Overall, how satisfied were you with Beautiful.ai?",
        category: "satisfaction",
        options: [
            "Very Satisfied",
            "Satisfied",
            "Neutral",
            "Unsatisfied",
            "Very Unsatisfied"
        ]
    },
    {
        question: "What was your primary reason for cancelling your subscription?",
        category: "reason",
        options: [
            "No longer need it",
            "Found an alternative",
            "Too expensive",
            "Quality was less that expected",
            "Too difficult to use",
            "Difficult to integrate with my company's existing workflow",
            "Generative AI features are not what I expected",
            "Need more control over design",
            "Customer service was less than expected",
            "Switching from Pro to Team Plan",
            "Other",
        ]
    },
    {
        question: "Would you use Beautiful.ai in the future?",
        category: "future",
        options: [
            "Definitely",
            "Probably",
            "Not Sure",
            "Probably Not",
            "Definitely Not",
        ]
    },
    {
        question: "What did you like about Beautiful.ai?",
        category: "liked",
        options: [
            "Automated slide design",
            "Design inspiration",
            "Ease of use/time savings",
            "Stock image and video library",
        ]
    }
];

const StyledSelect = styled(Select)`
    &&& {
        ${({ isMobileOrTablet }) => isMobileOrTablet ? `` : css`
        width: 300px;
        margin-left: 45px;
        `};
    }`;

function FormSelect({ value, onChange, placeholder, children }) {
    return (
        <StyledSelect isMobileOrTablet={app.isMobileOrTablet}
            variant="outlined"
            value={value}
            onChange={onChange}
            displayEmpty
            disableUnderline
            className={value == "" ? "placeholder" : ""}
            inputProps={{ className: "select-input" }}
        >
            <MenuItem value="" disabled>
                {placeholder}
            </MenuItem>
            {children}
        </StyledSelect>
    );
}

const StyledSubmitButton = styled(BlueButton)`
    &&& {
        width: 100%;
    }
`;

const StyledQuestionWrapper = styled.div`
    display: flex;
    align-items: center;

    h3 {
        margin: 0;
    }
`;

const StyledListNum = styled.span`
    flex: 0 0 auto;
`;

const StyledDialogContent = styled(DialogContent)`
    ${({ isMobileOrTablet }) => isMobileOrTablet ? css`padding: 0 15px` : ``};
`;

const StyledQuestionConatiner = styled.div`
    display: flex;
    flex-direction: column;
    gap:10px;
    padding-bottom: 10px;
`;

export class DowngradePlanDialog extends Component {
    constructor() {
        super();
        this.state = {
            satisfaction: "",
            future: "",
            reason: "",
            comments: "",
            liked: "",
        };

        trackActivity(EVENT_CATEGORY, "Started", null, null, {}, { audit: true });
    }

    submitForm = () => {
        app.user.update({
            cancelPlanSurvey: { ...this.state }
        });
        const props = {
            ...this.state,
            workspace_id: this.props.workspaceId
        };
        trackActivity(EVENT_CATEGORY, "Complete", null, null, props, { audit: true });

        this.props.closeDialog();
    };

    handleChange = (prop, value) => {
        this.setState({ [prop]: value });
    };

    submitButton = Component => (<Component onClick={this.submitForm} color="primary">Submit</Component>);

    render() {
        const { comments } = this.state;

        return (
            <BeautifulDialog {...this.props} preventClose>
                <DialogTitle>We're sorry to see you go but we'd love to hear why you decided to cancel.</DialogTitle>
                <StyledDialogContent isMobileOrTablet={app.isMobileOrTablet}>
                    {QUESTIONS.map(({ question, category, options }, i) => (
                        <StyledQuestionConatiner>
                            <StyledQuestionWrapper>
                                <StyledListNum className="list-num">{i + 1}. </StyledListNum>
                                <h3>{question}</h3>
                            </StyledQuestionWrapper>
                            <FormSelect
                                value={this.state[category]}
                                onChange={event => this.handleChange(category, event.target.value)}
                                placeholder="Choose..."
                            >
                                {options.map((option, num) => <MenuItem value={num + 1}>{option}</MenuItem>)}
                            </FormSelect>
                        </StyledQuestionConatiner>
                    ))}
                    <StyledQuestionWrapper>
                        <StyledListNum className="list-num">5. </StyledListNum>
                        <h3>What could we do to improve Beautiful.ai?</h3>
                    </StyledQuestionWrapper>

                    <div style={app.isMobileOrTablet ? {} : { marginLeft: 45 }}>
                        <TextField
                            multiline
                            fullWidth
                            value={comments}
                            onChange={event => {
                                this.setState({ comments: event.target.value });
                            }}
                        />
                    </div>
                </StyledDialogContent>
                <DialogActions>
                    {this.submitButton(app.isConstrained ? StyledSubmitButton : Button)}

                </DialogActions>
            </BeautifulDialog>
        );
    }
}
