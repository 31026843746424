function isOrganizationSsoEnabled(organization) {
    return !!organization.isDomainVerified &&
        !!organization.sso &&
        !!organization.sso.samlConfig &&
        !!organization.sso.samlConfig.certs &&
        !!organization.sso.samlConfig.entryPoint &&
        !!organization.sso.samlConfig.idpIssuer;
}

module.exports = isOrganizationSsoEnabled;
