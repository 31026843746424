import React, { Component } from "react";
import ThemeSection from "js/editor/ThemeEditor/components/ThemeSection";
import { _ } from "js/vendor";
import UploadLogo from "js/editor/ThemeEditor/components/Footer/UploadLogo";
import { BlueSwitch } from "js/react/components/Switch";
import { Gap10, Gap20, Gap30 } from "js/react/components/Gap";
import {
    FormControl,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Slider,
} from "@material-ui/core";
import styled from "styled-components";
import { trackActivity } from "js/core/utilities/utilities";
import { Divider } from "js/react/components/UiComponents";
import { SimpleLabel } from "js/react/components/SimpleLabel";

const LogoContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 12px;

    & > div {
        margin-right: 20px;
    }
`;

export default class ThemeFooter extends Component {
    constructor(props) {
        super(props);

        const { theme } = this.props;
        const {
            showFooterByDefault,
            showPageNum,
            showMessage,
            showLogo,
            footerMessage,
            logoScale,
            logoPosition,
            logoOffset
        } = theme;

        this.state = {
            showFooterByDefault,
            showPageNum,
            showMessage,
            showLogo,
            footerMessage,
            logoScale: logoScale || 1,
            logoPosition: logoPosition || "left",
            logoOffset: logoOffset || 0
        };

        this.handleChange = this.handleChange.bind(this);
        this.updateMessage = this.updateMessage.bind(this);

        this.updateMessage = _.debounce(this.updateMessage, 500);
    }

    handleChange(item) {
        this.props.update({ [item]: !this.state[item] });
        this.setState({ [item]: !this.state[item] });
    }

    handleMessageChange(e) {
        if (!e.target) return;

        this.setState({
            footerMessage: e.target.value
        });

        // updates message with 0.5s delay
        this.updateMessage();
    }

    updateMessage() {
        this.props.update({ footerMessage: this.state.footerMessage });
    }

    render() {
        let {
            theme,
            update,
            isSharedTheme,
        } = this.props;

        const {
            showFooterByDefault,
            showPageNum,
            showMessage,
            footerMessage,
            showLogo
        } = this.state;

        const onUploadSuccess = (logo_id, attribute) => {
            let trackProps = {
                [`${attribute}_changed`]: true,
                source: "theme_editor_footer",
                logo_id,
            };
            if (isSharedTheme) {
                trackActivity("OrgTheme", "LogoUpload", null, null, trackProps, { audit: false });
            } else {
                trackActivity("Theme", "LogoUpload", null, null, trackProps, { audit: false });
            }
        };

        return (
            <ThemeSection title="Slide Footer">
                <Gap20/>
                <BlueSwitch
                    checked={showFooterByDefault}
                    onChange={() => this.handleChange("showFooterByDefault")}
                    label="Show Footer By Default"
                    tooltipTitle="Sets whether the footer is on by default for slides. You can still toggle the footer on and off from the layout panel."
                />
                <Divider margin={10}/>
                <BlueSwitch
                    checked={showPageNum}
                    onChange={() => this.handleChange("showPageNum")}
                    label="Show slide number"
                    tooltipTitle="Sets whether to show the slide number when the footer is visible."
                />
                <BlueSwitch
                    checked={showMessage}
                    onChange={() => this.handleChange("showMessage")}
                    label="Show footer message"
                    tooltipTitle="Sets whether to show a message in the footer. "
                />
                <TextField
                    value={footerMessage}
                    onChange={e => this.handleMessageChange(e)}
                    variant="outlined"
                    style={{ width: "90%" }}
                    fullWidth={true}
                    inputProps={{ style: { padding: "10px 10px" } }}
                />
                <Gap20/>
                <BlueSwitch
                    checked={showLogo}
                    onChange={() => this.handleChange("showLogo")}
                    label="Show logo"
                    tooltipTitle={
                        <p>
                            <p>Sets whether to show a logo in the footer.</p>
                            <p>You can upload a logo for use on the light background and optionally one for use on dark or color backgrounds (usually a reversed white logo will work best for this).</p>
                            <p>NOTE: It is recommended that you upload logos as PNG with transparency or SVG for best results.</p>
                        </p>
                    }
                />

                <LogoContainer id="logo-container">
                    <UploadLogo
                        disableImageProcessing
                        filePickerOnClick={!theme.logo}
                        id="upload-logo-light"
                        bgColor={theme.colors.background_light}
                        src={theme.logo}
                        label="Logo on Light"
                        update={update}
                        attribute="logo"
                        onUploadSuccess={logo_id => onUploadSuccess(logo_id, "logo")}
                    />
                    <UploadLogo
                        disableImageProcessing
                        filePickerOnClick={!theme.logo_dark}
                        id="upload-logo-dark"
                        bgColor={theme.colors.background_dark}
                        src={theme.logo_dark}
                        label="Logo on Dark/Color"
                        update={update}
                        attribute="logo_dark"
                        onUploadSuccess={logo_id => onUploadSuccess(logo_id, "logo_dark")}
                    />
                </LogoContainer>

                <Gap30/>
                <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 20 }}>
                        <SimpleLabel tooltip="Adjusts the scale of the logo.">
                            Logo Scale
                        </SimpleLabel>
                        <Slider
                            value={this.state.logoScale}
                            min={0.75}
                            max={2}
                            step={0.01}
                            onChange={(event, value) => {
                                this.props.update({ logoScale: value });
                                this.setState({ logoScale: value });
                            }}
                            style={{ width: 150, marginTop: 0 }}
                        />
                    </div>

                    <div>
                        <SimpleLabel tooltip="Adjusts the position of the logo relative to the closest corner.">
                            Logo Offset
                        </SimpleLabel>
                        <Slider
                            value={this.state.logoOffset}
                            min={0}
                            max={20}
                            onChange={(event, value) => {
                                this.props.update({ logoOffset: value });
                                this.setState({ logoOffset: value });
                            }}
                            style={{ width: 150, marginTop: 0 }}
                        />
                    </div>
                </div>

                <div>
                    <SimpleLabel tooltip="Sets whether the logo is positioned on the left or right of the footer. This will swap the page number position as well.">
                        Logo Position
                    </SimpleLabel>
                    <FormControl style={{ width: 160 }}>
                        <Select
                            value={this.state.logoPosition}
                            onChange={event => {
                                this.props.update({
                                    logoPosition: event.target.value
                                });
                                this.setState({
                                    logoPosition: event.target.value
                                });
                            }}
                            input={
                                <OutlinedInput
                                    name="logoPosition"
                                    labelWidth={0}
                                />
                            }
                        >
                            <MenuItem value="left">Left</MenuItem>
                            <MenuItem value="right">Right</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <Gap30 />
            </ThemeSection>
        );
    }
}
