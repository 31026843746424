import React, { Component } from "react";
import styled from "styled-components";
import { ds } from "js/core/models/dataService";
import { Subscription } from "rxjs";
import classNames from "classnames";
import CommentsPane from "./CommentsPane";
import theme from "js/react/materialThemeOverrides";
import { Icon } from "@material-ui/core";
import { getStaticUrl } from "js/config";
import presentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

const Top = styled.div`
    height: 38px;
`;

const Bottom = styled.div`
    height: calc(100% - 38px);
    position: relative;
    display: flex;
    flex-direction: column;
`;

const TabButtons = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    * {
        flex-grow: 0;
    }
`;

const TabButton = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px 20px;
    background: #111;
    color: #ccc;

    cursor: pointer;

    &.active {
        background: ${theme.palette.primary[500]};
        color: white;
    }
`;

const CommentContainer = styled.div`
    padding: 15px;
    overflow: visible;
    margin-top: 0px;
    background: #292929;
    height: 100%;
    box-shadow: none;
    z-index: 0;
    width: 250px;
  `;

const CommentsTitleContainer = styled.div`
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    align-self: stretch;
    background: #000;
`;

const CommentsTitle = styled.div`
    flex: 1 0 0;
    color: #fff;
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
    text-transform: uppercase;
`;

const CloseButtonContainer = styled.div`
    cursor: pointer;
`;

const CloseIcon = styled.img`
    
    width: 10px;
    height: 10px;
    fill: #FFF;
`;

export class EditorCommentsPane extends Component {
    state = {
        byViewer: false,
    }

    constructor() {
        super();

        this.subs = new Subscription();
    }

    componentDidMount() {
        const {
            areCommentsByViewer$,
        } = ds.getObservables();
        this.subs.add(
            areCommentsByViewer$.subscribe(byViewer => this.setState({ byViewer }))
        );
    }

    componentWillUnmount() {
        this.subs.unsubscribe();
    }

    toggleByViewer = byViewer => {
        ds.getObservables().setCommentsAreByViewer(byViewer);
    }

    render() {
        const { byViewer } = this.state;

        const { transitionState, close, goToSlide } = this.props;

        return (
            <>
                <CommentsTitleContainer>
                    <CommentsTitle>Comments</CommentsTitle>
                    <CloseButtonContainer onClick={() => presentationEditorController.toggleComments()}>
                        <CloseIcon src={getStaticUrl("/images/close-icon.svg")}></CloseIcon>
                    </CloseButtonContainer>
                </CommentsTitleContainer>
                <CommentContainer>

                    <Top>
                        <TabButtons>
                            <TabButton
                                className={classNames({
                                    [`comment-tab`]: true,
                                    [`active`]: !byViewer,
                                })}
                                onClick={() => this.toggleByViewer(false)}
                            >Internal</TabButton>
                            <TabButton
                                className={classNames({
                                    [`comment-tab`]: true,
                                    [`active`]: byViewer,
                                })}
                                onClick={() => this.toggleByViewer(true)}
                            >Viewer</TabButton>
                        </TabButtons>
                    </Top>
                    <Bottom>
                        <CommentsPane
                            transitionState={transitionState}
                            goToSlide={goToSlide}
                            close={close}
                        />
                    </Bottom>
                </CommentContainer>
            </>
        );
    }
}
