import React from "react";

import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

import RecordPanel from "js/react/views/RecordPanel/RecordPanel";
import { PropertyPanelContainer } from "../../EditorComponents/PropertyPanel";
import AdvanceNextSlidePanel from "../PresentationEditor/Components/AdvanceNextSlidePanel";

export function RecordPropertyPanel(props) {
    return (
        <PropertyPanelContainer>
            <RecordPanel {...props} onClose={() => PresentationEditorController.setSelectedPropertyPanelTab("layout")} />
            <AdvanceNextSlidePanel {...props} />
        </PropertyPanelContainer>
    );
}

