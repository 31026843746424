import React, { Component } from "react";
import styled from "styled-components";
import { Avatar, Tooltip } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";

import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { themeColors } from "js/react/sharedStyles";
import { FlexBox } from "js/react/components/LayoutGrid";
import { UserProfile } from "js/react/components/Avatar";
import { Gap10 } from "js/react/components/Gap";
import { Box } from "js/Components/Box";
import { Popup, PopupContent, PopupPreview } from "js/Components/Popup";

import PresentationEditorController from "../PresentationEditorController";

const Container = styled(FlexBox)`
    
    .MuiAvatarGroup-root {
        cursor: pointer;
        ${props => props.showMore ? `
            div:last-child {        
                margin-left: 5px;
                letter-spacing: 1px;
                background: transparent;
                font-size: 14px;
                font-weight: 600;
                line-height: 17.6px;
            }
        ` : ""}        
    }
    .MuiAvatar-root {
        width: 30px;
        height: 30px;
        margin-left: -10px;
        border: none;
        font-family: "Source Sans Pro", sans-serif;
    }
`;

const PopupContentContainer = styled(Box)`
    width: 300px;
    gap: 12px;
    padding: 15px;
    background: #F9F9F9;
    font-family: Source Sans Pro;

    .MuiAvatar-root {
        width: 30px;
        height: 30px;
        margin-left: -10px;
        border: none;
        font-family: "Source Sans Pro", sans-serif;
    }

    .title {
        font-size: 10px;
        font-weight: 600;
        line-height: 13px;
        text-transform: uppercase;
        color: ${themeColors.mediumGray};
    }
    .collab-name {
        font-size: 14px;
        font-weight: 600;
        color: ${themeColors.darkGray};
    }
    .collab-email {
        font-size: 11px;
        color: ${themeColors.mediumGray};
    }
    .slide-tag {
        cursor: pointer;
        text-wrap: nowrap;
        font-size: 10px;
        font-weight: 400;
        color: ${themeColors.ui_blue};
    }
`;

const StyledCollaborator = styled.div`
    cursor: pointer;
    display: flex;
    margin: 5px 0px;
    padding: 5px;

    &:hover {
        background: #D6EFF9;
    }
`;

const StyledUserProfile = styled(UserProfile)`
    .can-trancate {
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
}`;

const MAX_USERS_TO_RENDER = 3;

export default class CollaborationBar extends Component {
    state = {
        collaborators: [],
        tooltipOpen: false
    };

    componentDidMount() {
        this.collaboratorsSubscription = ds.getObservables().collaborators$
            .subscribe(collaborators => {
                this.setState({
                    collaborators: Object.values(collaborators)
                        .map(collaborator => ({
                            ...collaborator,
                            displayName: collaborator.displayName === collaborator.email ? undefined : collaborator.displayName,
                        })).filter(({ uid }) => uid !== app.user.id)
                });
            });
    }

    componentWillUnmount() {
        this.collaboratorsSubscription.unsubscribe();
    }

    handleGoToSlide = (slideId, closePopup) => {
        if (slideId) PresentationEditorController.setCurrentSlideById(slideId);
        if (closePopup) closePopup();
    }

    render() {
        const { collaborators, tooltipOpen } = this.state;

        return (
            <Tooltip title="Active Collaborators" enterDelay={700} arrow
                open={tooltipOpen}
                disableHoverListener
            >
                <Container
                    onMouseEnter={() => {
                        const popUpState = PresentationEditorController.getPopupOpenState();
                        this.setState({ tooltipOpen: !popUpState });
                    } }
                    onMouseLeave={() => this.setState({ tooltipOpen: false })}
                    gap={20} showMore={collaborators.length > MAX_USERS_TO_RENDER} >
                    <Popup onShow={() => this.setState({ tooltipOpen: false }) }>
                        <PopupPreview>
                            <AvatarGroup>
                                {collaborators.slice(0, MAX_USERS_TO_RENDER).map(({ photoURL, displayName, email }) => (
                                    <Avatar key={email} alt={(displayName || email)} src={photoURL} variant="circular" />
                                ))}
                                {collaborators.length > MAX_USERS_TO_RENDER && <Avatar>+{collaborators.length - MAX_USERS_TO_RENDER}</Avatar>}
                            </AvatarGroup>
                        </PopupPreview>
                        <PopupContent>
                            {closePopup => (
                                <PopupContentContainer column>
                                    <span className="title">Active Collaborators</span>
                                    <Gap10 />
                                    {collaborators.map(({ photoURL, slideId, displayName, email }, id) => (
                                        <StyledCollaborator onClick={() => this.handleGoToSlide(slideId, closePopup)} key={id}>
                                            <FlexBox left between gap={10}>
                                                <StyledUserProfile profile={{ photoURL, displayName, email }} onClick={() => this.handleGoToSlide(slideId, closePopup) } />
                                                <span className="slide-tag" >
                                                    Slide {PresentationEditorController.presentation.getSlideIndex(slideId) + 1}</span>
                                            </FlexBox>
                                            <Gap10 />
                                        </StyledCollaborator>

                                    ))}
                                </PopupContentContainer>
                            )}
                        </PopupContent>
                    </Popup>
                </Container>
            </Tooltip>
        );
    }
}
