import { selectFile } from "common/utils/selectFile";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { JSZip } from "js/vendor";
import { Theme } from "../models/theme";
import getPPTTheme from "./getPPTTheme";
import getLogger, { LogGroup } from "js/core/logger";
import { trackActivity } from "./utilities";

const logger = getLogger(LogGroup.PPT_IMPORT);

const importPPTTheme = async source => {
    const file = await selectFile({
        accept: ".pptx",
    });

    if (!file) {
        return;
    }

    const maxFileSizeMB = 200;
    if (file.size > maxFileSizeMB * 1024 * 1024) {
        ShowErrorDialog({
            title: `File is bigger than ${maxFileSizeMB}MB`,
            message: "Please select another presentation or split this one into separate files of smaller sizes",
        });
        return;
    }

    let pptTheme;
    try {
        const zip = await JSZip.loadAsync(file);
        pptTheme = await getPPTTheme(zip);
    } catch (err) {
        logger.error(err, "importPPTTheme() failed");
        ShowErrorDialog({
            title: "Error",
            message: "Sorry, we could not process your file, try another one or contact support at support@beautiful.ai",
        });
        return;
    }

    trackActivity("PPTImport", "ThemeImport", null, null, { source }, { audit: false });
    return pptTheme;
};

export default importPPTTheme;
