import React, { Component } from "react";
import TagsInput from "react-tagsinput";
import { Chip } from "@material-ui/core";
import styled from "styled-components";

import { AutoSuggestRenderInput } from "js/react/components/InviteInput/AutosuggestInput";
import Icon from "js/react/components/Icon";
import { emailRegex, delimiterRegex } from "js/core/utilities/regex";
import { TAG_DELIMITERS } from "js/react/constants";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";

const StyledChip = styled(({ isValid, ...props }) => <Chip {...props} />)`
  background: ${props => props.isValid ? "#11a9e2" : "#ff00008c"} !important;
  height: 28px !important;
  margin: 0 10px 10px 0 !important;
  color: white !important;
  font-weight: 600 !important;
  padding-right: 8px !important;
`;

const StyledTagsInput = styled(TagsInput)`
  flex-grow: 2;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  font-size: 12px;
  height: 100%;
`;

const TagLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

//Important note: A tag can be an object if it comes from autosuggestinput or string if its entered normally!!
const EmailTag = props => {
    const { tag, keyProp, onRemove, getTagDisplayValue, classes } = props;

    let isValid;
    let displayValue;

    if (typeof tag === "object") {
        displayValue = tag.name || tag.email;
        isValid = !!(tag && tag.email.match(emailRegex));
    } else {
        displayValue = tag;
        isValid = !!(tag && tag.match(emailRegex));
    }

    return (
        <StyledChip
            key={keyProp}
            isValid={isValid}
            label={getTagDisplayValue(displayValue).toLowerCase()}
            onDelete={() => onRemove(keyProp)}
            deleteIcon={<Icon small light iconName="close" />}
        />
    );
};

class InviteInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customMessage: "",
            permission: "write",
            duplicatedEmails: []
        };

        if (this.props.tags?.length) {
            this.handleChangeTags(this.props.tags);
        }
    }

    pasteSplit = data => data.split(delimiterRegex).map(d => d.trim());

    handleChangeTags = tags => {
        const { onInputChange } = this.props;
        const emails = tags.map(t => t.email?.toLowerCase() || t?.toLowerCase());
        let isInviteDisabled;
        if (!emails.length) {
            isInviteDisabled = true;
        } else {
            isInviteDisabled = !!emails.find(email => !email.match(emailRegex));
        }

        onInputChange && onInputChange(emails, isInviteDisabled);
    };

    render() {
        const {
            tags,
            inputProps
        } = this.props;

        return (
            <div id="invite" className="invite-input">
                <StyledTagsInput
                    className="tags-input"
                    addKeys={TAG_DELIMITERS}
                    addOnBlur
                    addOnPaste
                    onlyUnique
                    maxTags={50}
                    value={tags}
                    pasteSplit={this.pasteSplit}
                    renderTag={props => <EmailTag {...props} keyProp={props.key} />}
                    onChange={this.handleChangeTags}
                    renderInput={props => (
                        <AutoSuggestRenderInput
                            {...props}
                            placeholder="Type emails..."
                            suggestionList={[]}
                            tags={tags}
                            taggedInputOnChange={this.handleChangeTags}
                            inputProps={inputProps}
                        />
                    )}
                    renderLayout={(tagComponents, inputComponent) => {
                        return (
                            <TagLayout>
                                {tagComponents}
                                {inputComponent}
                            </TagLayout>
                        );
                    }}
                />
            </div>
        );
    }
}

export default withFirebaseUser(InviteInput);
