import React, { Component } from "react";
import styled from "styled-components";

import { Icon } from "../Components/Icon";

export const ButtonDescription = styled.div`
    font-weight: 500;
    font-size: 12px;
    color: #444;
    margin-top: 6px;
    opacity: 0;
    transition: opacity 100ms;
`;

export const ButtonTitle = styled.div`
    font-weight: 600;
    font-size: 15px;
    color: #333;
    display: flex;
    align-items: center;

    .bai-icon {
        margin-right: 5px;
        color: #333;
    }
`;

const Button = styled.div`
    width: 100%;
    padding: 15px;
    background: ${props => props.disabled ? "#fde8e8" : "#e8f7fd"};
    border-bottom: solid 1px rgba(0, 0, 0, .125);
    flex-shrink: 0;
    cursor: default;

    &:hover {
        background: ${props => props.disabled ? "#f5dcdc" : "#dcedf5"};
    }
`;

export class PropertyPanelButton extends Component {
    handleMouseDown = event => {
        const { onMouseDown, disabled } = this.props;
        if (disabled) {
            event.stopPropagation();
            return;
        }

        onMouseDown && onMouseDown(event);
    }

    handleClick = event => {
        const { onClick, disabled } = this.props;
        if (disabled) {
            event.stopPropagation();
            return;
        }

        onClick && onClick(event);
    }

    render() {
        const { icon, title, description, disabled } = this.props;

        return (
            <Button onClick={this.handleClick} onMouseDown={this.handleMouseDown} disabled={disabled} hasDescription={description != null}>
                <ButtonTitle><Icon>{icon}</Icon>{title}</ButtonTitle>
            </Button>
        );
    }
}

