import React, { Component } from "react";
import styled, { css } from "styled-components";

import { ds } from "js/core/models/dataService";
import { _ } from "js/vendor";
import { app } from "js/namespaces";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { SlideTemplateInfoPage } from "js/react/views/SlideTemplateInfo/slideTemplateInfoPage";
import { AddSlideController } from "js/react/views/AddSlide/AddSlideController";
import HoldButton from "js/editor/components/HoldButton";
import { SlideLockType } from "common/constants";
import { Icon } from "@material-ui/core";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { FlexSpacer, Gap30 } from "js/react/components/Gap";
import SlideActionsMenu from "js/editor/PresentationEditor/Components/SlideActionsMenu";
import { IconButton } from "js/editor/PresentationEditor/Components/SharedComponents";
import { stopPropagation } from "js/core/utilities/stopPropagation";

const Container = styled.div.attrs(({ visible }) => ({
    style: {
        opacity: visible ? 1 : 0
    }
}))`
    transition: "250ms";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    label.always-visible {
        opacity: 1;
    }

    &:hover {
        div {
            opacity: 1;

            label {
                opacity: 1;
            }
        }
    }
`;

const Spacer = styled.div`
  flex-shrink: 0;
  height: calc(50% - 15px);
`;

const HiddenControls = styled.div`
  transition: 250ms;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;

  div { 
    opacity: 0;
  }
`;

const hoverMaterialIcon = css`
    button {
        background: none;
    }

    .material-icons {
        color: #50bbe6;
        
    }
`;

const AddSlideButton = styled(IconButton)`
    &&& {
        gap:5px;
        
        .material-icons {
            color: #fff;
            font-size: 40px;
            filter: none;
        }

        &:hover {
            ${hoverMaterialIcon};
        }
    }
`;

const OtherMenuItems = styled(AddSlideButton)`
    &&& {    
        .material-icons {
            font-size: 20px;
        }

        &:hover {
            ${hoverMaterialIcon};
        }

    }
`;

const MoreButton = styled(OtherMenuItems)`
   &&& {
        button {
            display: flex;
            flex-direction: column;
        }

        &:hover {
            ${props => props.disabled ? css`
                    opacity: 0.5;
                ` : ""};
        }
        

        ${({ $menuIsOpen }) => $menuIsOpen ? "opacity:1" : ""};
    }
`;

class SlideActions extends Component {
    state = {
        isDeleteDisabled: false,
        isMoreOptionsDisabled: false,
        isMenuOpen: false
    }

    onLockSlideChange = model => {
        if (model.attributes.lockState[SlideLockType.COLLABORATION]) {
            this.setState({
                isDeleteDisabled: true,
                isMoreOptionsDisabled: true
            });
            return;
        } else {
            this.setState({
                isDeleteDisabled: false,
                isMoreOptionsDisabled: false
            });
        }

        const orgOrTeamMember = (model.attributes.lockState[SlideLockType.ORG] || model.attributes.lockState[SlideLockType.TEAM_MEMBER]) &&
            (!app.user.has("teams") || !ds.selection.slide.isLibrarySlideInCurrentUserOrg());

        this.setState({ isDeleteDisabled: orgOrTeamMember });
    }

    showTemplateInfo = () => {
        ShowDialog(SlideTemplateInfoPage, {
            templateId: ds.selection.slide.get("template_id"), isLibrary: false, onCopySlide: slideId => {
                AddSlideController.replaceSlideInPresentation(slideId, ds.selection.slide);
            }
        });
    }

    setMenuOpen = value => this.setState({ isMenuOpen: value })

    render() {
        const { currentSlide, showCanvasControls, currentSlideLockOwner } = this.props;
        const { isMenuOpen, isDeleteDisabled } = this.state;
        const deleteAction = currentSlide.isLibrarySlide() ? "Remove" : "Delete";

        return (
            <Container visible={showCanvasControls}>
                <Spacer />
                <AddSlideButton id="add-slide-button" onMouseDown={stopPropagation(() => PresentationEditorController.showAddSlideDialog())}>
                    <Icon>add_circle</Icon>
                    <label className="always-visible">Add Slide</label>
                </AddSlideButton>
                <Gap30 />
                <HiddenControls>
                    <OtherMenuItems onMouseDown={stopPropagation(() => PresentationEditorController.duplicateSlide())} >
                        <Icon>content_copy</Icon>
                        <label>Duplicate</label>
                    </OtherMenuItems>
                    <Gap30 />
                    <MoreButton disabled={currentSlideLockOwner ? true : false} $menuIsOpen={isMenuOpen}>
                        <SlideActionsMenu
                            disabled={currentSlideLockOwner}
                            position="above"
                            onShow={() => {
                                // To make sure if we select an item from the menu, the selection will be cleared
                                PresentationEditorController.selectionLayerController.setSelectedElements([]);
                                this.setMenuOpen(true);
                            }}
                            onClose={() => this.setMenuOpen(false)}
                            setGap={false}
                            label="More"
                            icon="more_horiz" />
                    </MoreButton>
                    <FlexSpacer />
                    <HoldButton
                        icon="delete"
                        disabled={isDeleteDisabled || currentSlideLockOwner ? true : false}
                        label={deleteAction}
                        holdLabel={`Hold to ${deleteAction}`}
                        callback={() => PresentationEditorController.deleteSlide()}
                    />
                </HiddenControls>
            </Container>
        );
    }
}

export default PresentationEditorController.withState(SlideActions);
