import { TextFocusType } from "common/constants";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

export class TabKeyController {
    static handleTabKey(event) {
        event.preventDefault();

        const currentCanvasController = PresentationEditorController.getCurrentCanvasController();
        const currentSelectionLayerController = PresentationEditorController.getCurrentSelectionLayerController();

        const canvas = currentCanvasController.canvas;

        // Find next TextElement to focus
        const textElements = canvas.getCanvasElement().findChildElements("TextElement").filter(text => text.canEdit && text.textModel.blocks.length);
        if (textElements.length > 0) {
            let index;
            if (currentSelectionLayerController.selectedElements.length === 1 && currentSelectionLayerController.selectedElements[0].isInstanceOf("TextElement")) {
                index = textElements.indexOf(currentSelectionLayerController.selectedElements[0]);
                if (event.shiftKey) {
                    index -= 1;
                } else {
                    index += 1;
                }
            } else {
                index = 0;
            }

            if (index < 0) {
                index = textElements.length - 1;
            } else if (index >= textElements.length) {
                index = 0;
            }
            const element = textElements[index];

            const block = element.textModel.blocks[event.shiftKey ? Math.max(0, element.textModel.blocks.length - 1) : 0];

            currentSelectionLayerController.selectTextElementBlock(element, block.id, TextFocusType.ALL);
        }
    }
}
