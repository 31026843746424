import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

import * as geom from "js/core/utilities/geom";
import { DragBlockManagerRollover } from "js/editor/PresentationEditor/DragBlockManager";
import { DragElementManagerRollover } from "js/editor/PresentationEditor/DragElementManager";
import { ControlBarPosition, DOUBLE_CLICK_THRESHOLD_MS } from "common/constants";
import { stopPropagation } from "js/core/utilities/stopPropagation";

import PresentationEditorController from "./PresentationEditorController";
import { themeColors } from "../../react/sharedStyles";

const Container = styled.div.attrs(({ visible }) => ({
    style: {
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? "default" : "none"
    }
}))`
    width: 100%;
    height: 100%;
    transition: opacity 0.2s ease-in-out;
    position: relative;
`;

const ElementUIContainer = styled.div.attrs(({ bounds, opacity }) => ({
    style: {
        ...bounds.toObject(),
        opacity
    }
}))`
    position: absolute;

    > .drag-handle {
        display: ${props => props.hideHandles ? "none" : "default"};
    }

    > .resize-handle {
        display: ${props => props.hideHandles ? "none" : "default"};
    }
`;

const FauxCanvasContainer = styled.div.attrs(({ canvasBounds }) => ({
    style: {
        ...canvasBounds.toObject()
    }
}))`
    position: absolute;
    pointer-events: none;
`;

const CanvasDimmer = styled.div.attrs(({ canvasBounds, containersBounds }) => ({
    style: {
        ...canvasBounds.toObject(),
        clipPath: [`path(evenodd, "`,
            `M 0 0`,
            `L ${canvasBounds.width} 0`,
            `L ${canvasBounds.width} ${canvasBounds.height}`,
            `L 0 ${canvasBounds.height}`,
            `L 0 0`,
            containersBounds.map(containerBounds => [
                `M ${containerBounds.left} ${containerBounds.top}`,
                `L ${containerBounds.right} ${containerBounds.top}`,
                `L ${containerBounds.right} ${containerBounds.bottom}`,
                `L ${containerBounds.left} ${containerBounds.bottom}`,
                `L ${containerBounds.left} ${containerBounds.top}`,
            ].join(" ")).join(" "),
            `Z`,
            `")`].join(" ")
    }
}))`
    position: absolute;
    pointer-events: none;

    background: rgba(0, 0, 0, 0.4);
    z-index: 999998;
`;

export const NoFitWarning = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: ${props => props.corner ? "flex-start" : "center"};
    justify-content:  ${props => props.corner ? "flex-end" : "center"};
    top: 0px;
    left: 0px;
    z-index: 10000;

    > div.backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        //background: repeating-linear-gradient(-45deg, #D3E9F6, #D3E9F6 10px, #50bbe6 10px, #50bbe6 20px);
        background: rgba(255, 69, 0, 0.25);
        border: solid 1px rgba(255, 69, 0, 0.5);;
    }

    > div.label {
        position: absolute;
        left: 50%;
        bottom: -50px;
        transform: ${props => props.corner ? "translate(-50%, 0)" : null};
        background: ${themeColors.ui_blue};
        color: white;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        padding: 6px 10px;
    }

    .bai-icon {
        transform: ${props => props.corner ? "translate(50%, -50%)" : null};
        background: orangered;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        color: white;
        font-size: 14px;
        cursor: pointer;
        pointer-events: auto;
    }
`;

const DefaultOverlayContainer = styled.div.attrs(({ opacity }) => ({
    style: {
        opacity
    }
}))`
    width: 100%;
    height: 100%;
`;

class SelectionLayer extends React.Component {
    get selectionLayerController() {
        return this.props.selectionLayerController;
    }

    get canvasController() {
        return this.props.canvasController;
    }

    get isDraggingElement() {
        return !!this.props.dragElementState;
    }

    get isDraggingBlock() {
        return !!this.props.dragBlockState;
    }

    componentDidMount() {
        window.addEventListener("mousemove", this.handleMouseMove);
        window.addEventListener("mousedown", this.handleMouseDown);
    }

    componentWillUnmount() {
        window.removeEventListener("mousemove", this.handleMouseMove);
        window.removeEventListener("mousedown", this.handleMouseDown);
    }

    shouldComponentUpdate(nextProps) {
        const { isCanvasGenerating: willCanvasBeGenerating, freezeSelectionLayer } = nextProps;

        if (willCanvasBeGenerating) {
            // Prevent refresh before/during canvas generation
            return false;
        }

        if (freezeSelectionLayer) {
            // Prevent refresh when selection layer is frozen
            return false;
        }

        return true;
    }

    handleMouseDown = event => {
        const { isCanvasGenerating, isCanvasAnimating, freezeSelection, selectionLayerController, isCurrentSlideLockedForMe } = this.props;
        if (isCanvasGenerating || isCanvasAnimating || freezeSelection || isCurrentSlideLockedForMe) {
            return;
        }

        const position = new geom.Point(event.clientX, event.clientY);

        let isDoubleClick = false;
        if (selectionLayerController.prevMouseDownAt + DOUBLE_CLICK_THRESHOLD_MS > Date.now()) {
            const distance = position.distance(selectionLayerController.prevMouseDownPosition);
            if (distance < 5) {
                isDoubleClick = true;
            }
        }

        selectionLayerController.prevMouseDownAt = Date.now();
        selectionLayerController.prevMouseDownPosition = position;

        const elements = selectionLayerController.getElementsForSelection(event, isDoubleClick);

        // clear double click detection if we selected a different element on the first click
        // this prevents doubleclickable elements from being selected before a parent element is selected (like a picture in a sidebar)
        if (elements.length && selectionLayerController.selectedElements[0] !== elements[0] && elements[0].preventDirectDoubleClickOnChildren) {
            selectionLayerController.prevMouseDownAt = selectionLayerController.prevMouseDownPosition = null;
        }

        selectionLayerController.setSelectedElements(elements);
    }

    handleMouseMove = event => {
        const { isCanvasGenerating, isCanvasAnimating, freezeSelection, selectionLayerController, isCurrentSlideLockedForMe } = this.props;
        if (isCanvasGenerating || isCanvasAnimating || freezeSelection || isCurrentSlideLockedForMe) {
            return;
        }

        if (this.isDraggingElement || this.isDraggingBlock) {
            return;
        }

        const elements = selectionLayerController.getElementsForRollover(event);
        selectionLayerController.setRolloverElements(elements);
    }

    render() {
        const {
            hilitedElements,
            selectedElements,
            elementsWithSelection,
            rolloverElements,
            canvas,
            canvasController,
            selectionLayerController,
            dragBlockState,
            dragElementState,
            primaryElement,
            isCanvasAnimating,
            useCanvasDimmerForHilitedElements,
            showSelectionLayer
        } = this.props;

        if (canvasController.isTemplateObsolete) {
            return null;
        }

        const elementsWithDefaultOverlay = canvas.getElements().filter(element => element.showDefaultOverlay);
        const elementsWithForceShowSelection = canvas.getElements().filter(element => element.forceShowSelection);

        const elements = [...new Set([...selectedElements, ...elementsWithSelection, ...rolloverElements, ...hilitedElements, ...elementsWithDefaultOverlay, ...elementsWithForceShowSelection, primaryElement])]
            .filter(Boolean)
            .filter(element => element.isCalculated)
            .filter(element => !element.isDeleted)
            .sort((a, b) => a.getElementPath().length - b.getElementPath().length);

        let Dimmer;
        if (useCanvasDimmerForHilitedElements && hilitedElements.length > 0) {
            const canvasScreenBounds = canvasController.canvasScreenBounds;
            const containersBounds = hilitedElements.map(element => element.selectionBounds.spaceScale(canvas.getScale()));
            Dimmer = <CanvasDimmer canvasBounds={canvasScreenBounds.zeroOffset()} containersBounds={containersBounds} />;
        }

        const elementsWithUI = elements.map(element => {
            const isPrimaryElement = element === primaryElement;
            const showSelection = selectedElements.includes(element) || elementsWithSelection.includes(element) || element.forceShowSelection;
            const showControlBar = showSelection || isPrimaryElement;
            const isRollover = rolloverElements.includes(element);
            const isHilited = hilitedElements.includes(element) && !useCanvasDimmerForHilitedElements;
            const showDefaultOverlay = elementsWithDefaultOverlay.includes(element);

            const Selection = showSelection ? element.getElementSelection() : null;
            const Rollover = (isRollover || isHilited) ? element.getElementRollover() : null;
            const DefaultOverlay = showDefaultOverlay ? element.getElementDefaultOverlay() : null;
            const ControlBar = showControlBar ? element.getElementControlBar() : null;

            return {
                element,
                isPrimaryElement,
                isHilited,
                Selection,
                Rollover,
                DefaultOverlay,
                ControlBar,
            };
        });

        if (elementsWithUI.some(element => !element.isPrimaryElement && element.ControlBar?.prototype?.suppressPrimaryControlBar)) {
            elementsWithUI.forEach(elementUI => {
                if (elementUI.isPrimaryElement) {
                    elementUI.ControlBar = null;
                }
            });
        }

        return (<Container visible={!isCanvasAnimating}>
            {/* Element UI */}
            {elementsWithUI.map(({
                element,
                isPrimaryElement,
                isHilited,
                Selection,
                Rollover,
                DefaultOverlay,
                ControlBar
            }) => {
                if (!Selection && !Rollover && !DefaultOverlay && !ControlBar && !isHilited) {
                    return null;
                }

                const isBeingDragged = element.isBeingDragged || element.isParentBeingDragged;
                const isBeingResized = element.isBeingResized || element.isParentBeingResized;

                const containerOpacity = showSelectionLayer ? (isBeingDragged ? selectionLayerController.dragElementOpacity : 1) : 0;

                const containerBounds = element.selectionBounds.spaceScale(canvas.getScale());

                // Canvas bounds relative to the element selection bounds to let the editor know where the canvas is
                const canvasBounds = new geom.Rect(0, 0, canvas.CANVAS_WIDTH, canvas.CANVAS_HEIGHT)
                    .setPosition(-element.selectionBounds.left, -element.selectionBounds.top)
                    .spaceScale(canvas.getScale());

                const selectionProps = {
                    ...this.props,
                    element,
                    canvasBounds,
                    containerBounds,
                    isBeingDragged,
                    isBeingResized,
                    hasRollover: !!Rollover,
                    hasSelection: !!Selection,
                    hasDefaultOverlay: !!DefaultOverlay,
                    hasControlBar: !!ControlBar,
                };

                const {
                    containerRef,
                    defaultOverlayRef,
                    selectionRef,
                    rolloverRef,
                    controlBarRef
                } = element.uiRefs;

                return (
                    <React.Fragment key={element.uniquePath}>
                        {DefaultOverlay && element.defaultOverlayContainerRef.current && createPortal(
                            <DefaultOverlayContainer opacity={containerOpacity}>
                                <DefaultOverlay {...selectionProps} ref={defaultOverlayRef} />
                            </DefaultOverlayContainer>,
                            element.defaultOverlayContainerRef.current
                        )}
                        <ElementUIContainer
                            bounds={containerBounds}
                            onMouseDown={stopPropagation()}
                            ref={containerRef}
                            opacity={containerOpacity}
                            hideHandles={isBeingDragged}
                            data-element-path={element.uniquePath}
                            data-element-type={element.type}
                        >
                            {Selection && <Selection {...selectionProps} ref={selectionRef} />}

                            {isHilited && Rollover && <Rollover {...selectionProps} ref={rolloverRef} />}

                            {ControlBar && !isBeingDragged && !isBeingResized && (
                                <>
                                    {isPrimaryElement && (
                                        <FauxCanvasContainer canvasBounds={canvasBounds}>
                                            <ControlBar {...selectionProps} position={ControlBarPosition.CANVAS} ref={controlBarRef} />
                                        </FauxCanvasContainer>
                                    )}
                                    {!isPrimaryElement &&
                                        <ControlBar {...selectionProps} ref={controlBarRef} />
                                    }
                                </>
                            )}
                        </ElementUIContainer>
                    </React.Fragment>
                );
            })}
            {/* Canvas dimmer for hilited elements */}
            {Dimmer}
            {/* Drag managers */}
            <DragBlockManagerRollover
                canvasController={canvasController}
                selectionLayerController={selectionLayerController}
                dragState={dragBlockState}
            />
            <DragElementManagerRollover
                canvasController={canvasController}
                selectionLayerController={selectionLayerController}
                dragState={dragElementState}
            />
        </Container>);
    }
}

const SelectionLayerWrapper = React.forwardRef((props, ref) => {
    const { isCanvasRendered, canvas, isSingleSlideEditor } = props;
    if (!isCanvasRendered) {
        return null;
    }

    if (!canvas.isCurrentCanvas && !isSingleSlideEditor) {
        return null;
    }

    return <SelectionLayer {...props} ref={ref} />;
});

export default PresentationEditorController.withFullState(SelectionLayerWrapper);
