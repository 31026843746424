import { _ } from "js/vendor";
import { getStaticUrl } from "js/config";
import React from "react";

export function StaticImage(props) {
    let imgProps = _.omit(props, ["src", "width", "height"]);

    let style = {
        width: props.width ?? "100%",
        height: props.height ?? "100%",
        objectFit: "contain",
        verticalAlign: "middle"
    };

    return <img src={getStaticUrl(props.src)} style={style} {...imgProps}/>;
}
