import { Icon, IconButton } from "@material-ui/core";
import React from "react";

export function OptionsMenu({ onClick, icon = "more_vert", showArrow }) {
    return (
        <IconButton
            onClick={onClick}
            style={{ width: 30, height: 30, marginLeft: 5 }}
        >
            <Icon style={{ color: "#333", fontSize: 18 }}>
                {icon}
            </Icon>
            {showArrow && <Icon>arrow_drop_down</Icon>}
        </IconButton>
    );
}
