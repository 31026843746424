import React, { Component } from "react";
import styled from "styled-components";
import { Popover } from "@material-ui/core";

import Logos from "js/core/models/logos";
import { getLogoPath } from "common/assetUtils";
import { GradientPreview } from "../ThemeGradients";
import { Gradient } from "../Gradient";
import { FlexBox } from "js/react/components/LayoutGrid";

const BackgroundImageThumbnail = styled.div`
  width: 100%;
  height: 100%;
  outline: solid 1px #ccc;
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
  img { 
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const ColorPalletteContainer = styled.div`
    padding: 16px;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 5px;
`;

const ColorPickerContainer = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    .material-icons {
        opacity: 0;
        position: absolute;
        top: -4px;
        right: -4px;
        font-size: 16px;
    }

    &:hover .material-icons {
        opacity: 1;
    }
`;

const ColorPicker = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: solid 1px #bbb;
`;

const BackgroundPreview = styled.div`
  height: 101px;
  width: 180px;
  border: solid 1px #bbb;
`;

const ColorPopover = styled(Popover)({
    ".MuiPopover-paper": {
        maxHeight: "25vh",
        overflowY: "auto", // Enable vertical scrolling
        padding: "5px",
        width: "230px",
        display: "flex",
        flexDirection: "column",
        top: props => `${props.$top}px !important`
    },
});

export default class DefaultBackgroundColor extends Component {
    colorPopoverRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null
        };
    }

    openOptions = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    closeOptions = () => {
        this.setState({ anchorEl: null });
    };

    selectDefault = value => {
        this.props.update({ defaultBackgroundColor: value });
        this.setState({ anchorEl: null });
    };

    renderColors = () => {
        const { theme } = this.props;
        const colors = theme.colors;
        let colorSet = Object.keys(colors).map((color, index) => {
            if (color.includes("accent")) {
                return (<ColorPickerContainer key={index}>
                    <ColorPicker
                        style={{ backgroundColor: colors[color] }}
                        onClick={() => this.selectDefault(color)}
                    />
                </ColorPickerContainer>);
            }
        });

        colorSet.unshift(
            <ColorPickerContainer key={"background_light"}>
                <ColorPicker
                    style={{ backgroundColor: colors.background_light }}
                    onClick={() => this.selectDefault("background_light")}
                />
            </ColorPickerContainer>,
            <ColorPickerContainer key={"background_dark"}>
                <ColorPicker
                    style={{ backgroundColor: colors.background_dark }}
                    onClick={() => this.selectDefault("background_dark")}
                />
            </ColorPickerContainer>,
            <ColorPickerContainer key={"theme"}>
                <ColorPicker
                    style={{ backgroundColor: colors.theme }}
                    onClick={() => this.selectDefault("theme")}
                />
            </ColorPickerContainer>
        );

        return colorSet;
    };

    render() {
        const { theme } = this.props;

        const defaultBackgroundAsGradient = theme.backgroundGradients?.find(bg => bg.id === theme.defaultBackgroundColor);
        const defaultBackgroundAsImage = theme.backgroundImages?.find(bg => bg.id === theme.defaultBackgroundColor);

        return (
            <>
                {
                    defaultBackgroundAsGradient &&
                    !defaultBackgroundAsImage &&
                    <BackgroundPreview
                        ref={this.colorPopoverRef}
                        onClick={this.openOptions}
                    >
                        <Gradient gradient={defaultBackgroundAsGradient} />
                    </BackgroundPreview>
                }
                {
                    !defaultBackgroundAsGradient &&
                    defaultBackgroundAsImage &&
                    <BackgroundPreview
                        ref={this.colorPopoverRef}
                        onClick={this.openOptions}
                    >
                        <BackgroundImage
                            ref={this.colorPopoverRef}
                            backgroundImage={defaultBackgroundAsImage}
                        />
                    </BackgroundPreview>
                }
                {
                    !defaultBackgroundAsGradient &&
                    !defaultBackgroundAsImage &&
                    <BackgroundPreview
                        ref={this.colorPopoverRef}
                        onClick={this.openOptions}
                        style={{ backgroundColor: this.props.theme.colors[theme.defaultBackgroundColor] ?? theme.colors.background_light }}
                    />
                }
                <ColorPopover
                    $top={this.colorPopoverRef.offsetTop}
                    disableEnforceFocus
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.closeOptions}
                >
                    <ColorPalletteContainer>
                        {this.renderColors()}
                    </ColorPalletteContainer>

                    {
                        theme.backgroundGradients?.length > 0 &&
                        <FlexBox vertical gap={10} style={{ padding: 16, paddingTop: 4 }}>
                            {theme.backgroundGradients
                                .map(bg => (
                                    <GradientPreview
                                        key={bg.id}
                                        onClick={() => this.selectDefault(bg.id)}
                                    >
                                        <Gradient gradient={bg} />
                                    </GradientPreview>
                                ))
                            }
                        </FlexBox>
                    }

                    {
                        theme.backgroundImages?.length > 0 &&
                        <div style={{ padding: 16, paddingTop: 4 }}>
                            {theme.backgroundImages
                                .filter(bg => bg.asset)
                                .map(bg => (
                                    <BackgroundImage
                                        key={bg.id}
                                        backgroundImage={bg}
                                        onClick={() => this.selectDefault(bg.id)}
                                    />
                                ))
                            }
                        </div>
                    }

                </ColorPopover>
            </>
        );
    }
}

class BackgroundImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: null
        };
    }

    async componentDidMount() {
        let url = await Logos.getSignedUrlAndLoad(getLogoPath(this.props.backgroundImage.asset));
        this.setState({ url });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.backgroundImage.asset !== this.props.backgroundImage.asset) {
            Logos.getSignedUrlAndLoad(getLogoPath(this.props.backgroundImage.asset)).then(url => {
                this.setState({ url });
            });
        }
    }

    render() {
        let { url } = this.state;
        return (
            <BackgroundImageThumbnail onClick={this.props.onClick}>
                <img src={url} />
            </BackgroundImageThumbnail>
        );
    }
}
