import React, { Component } from "react";
import classNames from "classnames";
import { Icon, Popover, TextField, InputAdornment } from "@material-ui/core";
import styled from "styled-components";

const WhiteTextInput = styled(TextField)`
  width: 40%;
  z-index: 2;
  cursor: pointer;
  
  .MuiOutlinedInput-adornedEnd{
    pointer-events: none;
  }

  & input {
    padding: 10px;
    -webkit-text-fill-color: #333;
    color: #333;
    background-color: transparent;
    &:disabled {
      background-color: transparent;
    }
  }
`;

const IconOptionsContainer = styled.div`
    padding: 10px;
    border-radius: 8px;
    
    &.selected {
        background-color: #e6e6e6;
    }
`;

const IconOptionsLabel = styled.label`
    text-transform: uppercase;
    color: #333;
    display: block;
    margin-bottom: 6px;
`;

const IconOptionsIcon = styled.img`
    width: 36px;
    height: 36px;
    display: inline-block;
    margin-right: 2px;
`;

export default class IconOptions extends Component {
    constructor() {
        super();

        this.state = {
            anchorEl: null,
            styles: {
                chunky: "Solid",
                classic: "Simple",
                doodle: "Sketchy"
            },
            iconSamples: [
                "arrow-right",
                "figure-man",
                "inbox",
                "quarters-three",
                "thumbs-up",
                "star-none",
                "location-home",
                "add",
                "grandpa",
                "trash-can",
                "headphones"
            ]
        };

        this.showPalettes = this.showPalettes.bind(this);
        this.renderIcons = this.renderIcons.bind(this);
        this.updateStyle = this.updateStyle.bind(this);
    }

    renderIcons() {
        const {
            styles,
            iconSamples
        } = this.state;
        const {
            theme,
            iconBaseUrl,
        } = this.props;

        return Object.keys(styles).map((style, index) => {
            return (
                <IconOptionsContainer
                    key={index}
                    className={classNames({
                        selected: theme.iconStyle === style
                    })}
                    onClick={() => this.updateStyle(style)}
                >
                    <IconOptionsLabel>
                        {styles[style]}
                    </IconOptionsLabel>
                    <div>
                        {iconSamples.map((sample, index) => (
                            <IconOptionsIcon
                                key={index}
                                src={`${iconBaseUrl}/${style}/${style}-${sample}.svg`}
                            />
                        ))}
                    </div>
                </IconOptionsContainer>
            );
        });
    }
    updateStyle(style) {
        this.props.update({ iconStyle: style });
        this.setState({ anchorEl: null });
    }

    showPalettes(event) {
        this.state.anchorEl
            ? this.setState({ anchorEl: null })
            : this.setState({ anchorEl: event.currentTarget });
    }

    render() {
        const { theme } = this.props;
        const { anchorEl, styles } = this.state;
        return (
            <div>
                <WhiteTextInput
                    readOnly={true}
                    value={styles[theme.iconStyle]}
                    variant="outlined"
                    onClick={this.showPalettes}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon>arrow_drop_down</Icon>
                            </InputAdornment>
                        )
                    }}
                />

                <Popover
                    open={Boolean(anchorEl)}
                    disableEnforceFocus
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    onClose={this.showPalettes}
                >
                    <div style={{ padding: 10, width: 460 }}>
                        {this.renderIcons()}
                    </div>
                </Popover>
            </div>
        );
    }
}
