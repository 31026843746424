import React, { Component } from "react";
import styled, { css } from "styled-components";
import { Icon, CircularProgress } from "@material-ui/core";

import Api from "js/core/api";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import AppController from "js/core/AppController";
import { getStaticUrl } from "js/config";
import getUserProfile from "js/core/services/userProfiles";
import { AuthPage, AuthForm } from "js/react/views/Auth/AuthUi";
import { Authenticate } from "js/react/views/Auth/Authenticate";
import { FirebaseUserConsumer } from "js/react/views/Auth/FirebaseUserContext";
import { BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";
import ErrorDialog from "js/react/components/Dialogs/ErrorDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import TextArea from "js/react/components/TextArea";
import { BlueButton, BlueOutlinedButton } from "js/react/components/UiComponents";
import { Gap30 } from "js/react/components/Gap";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.PERMISSIONS);

const Divider = styled.hr`
    border: 1px solid #eeeeee;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    background: #F8FAFC;
`;

const RequestAccessContentContainer = styled.div.attrs(({ opacity }) => ({
    style: {
        opacity
    }
}))`
    width: 100%;
    height: 100%;
    transition: opacity 500ms;
`;

const ErrorDialogMessage = styled.p`
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;     
    letter-spacing: 0.5px;
`;

const ErrorDialogInfo = styled.p`
    font-family: Source Sans Pro;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.5px;
`;

const AuthFormHeader = styled.p`
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;
    letter-spacing: 0.5px;
    text-align: center;
    color: #333333;
`;

const AuthFormSubheader = styled.p`
    font-family: Source Sans Pro;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.5px;
    text-align: center;
    color: #222222;
`;

const AuthFormSubheaderHeavy = styled.p`
    font-family: Source Sans Pro;    
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;    
    letter-spacing: 0.5px;
    text-align: center;
    color: #222222;
`;

const RequestAccessButton = styled(BlueButton)`
&&& {
    width: 100%;
    height: 40px;
    border-radius: 1px;
}
`;

const UserInfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
`;

const UserInfo = styled.div`
    width: fit-content;
    background: #eeeeee;

    padding: 10px;
    display: flex;
    flex-flow: row;
    align-items: center;

    >img,>svg {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }

    >p {
        font-family: Source Sans Pro;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #222222;
    }
`;

const LogIntoDifferentAccountButton = styled.div.attrs(({ disabled }) => ({
    style: {
        pointerEvents: disabled ? "none" : "auto",
        color: disabled ? "#CCCCCC" : "#11A9E2",
        cursor: disabled ? "default" : "pointer"
    }
}))`
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    text-decoration-line: underline;
`;

const DoneImageContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DoneImage = styled.img`
    height: 100%;
`;

const ReturnToLibraryButtonContainer = styled.div`
    margin-top: 34px;
    margin-bottom: -16px;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ReturnToLibraryButton = styled(BlueOutlinedButton)`
    &&& {
        height: 40px;
        border: none;
    }
`;

class RequestAccessDialogContent extends Component {
    state = {
        fetchingRequestAccess: false,
        customMessage: "",
        accessRequested: false,
        error: null
    };

    handleRequestAccess = () => {
        const { folderId, goToUrl } = this.props;
        const { customMessage } = this.state;

        this.setState({ fetchingRequestAccess: true });

        Api.teamPermissions.post({
            type: "request_team_folder_access", teamFolderId: folderId, customMessage
        })
            .then(() => {
                this.setState({ fetchingRequestAccess: false, accessRequested: true });
                goToUrl("/", 10000);
            })
            .catch(err => {
                logger.error(err, "FolderAccessDialogs handleRequestAccess() failed", { folderId });

                this.setState({
                    fetchingRequestAccess: false,
                    error: {
                        message: err.status === 403 ? "Not Allowed" : "Oops! Our server had an error.",
                        info: err.status === 403 ? "You are not a member of the workspace this team folder belongs to." : "Try again later."
                    }
                });
            });
    }

    render() {
        const { firebaseUser, goToUrl } = this.props;
        const { fetchingRequestAccess, customMessage, error, accessRequested } = this.state;

        if (error) {
            return (<ErrorDialog
                message={(<>
                    <ErrorDialogMessage>{error.message}</ErrorDialogMessage>
                    <ErrorDialogInfo>{error.info}</ErrorDialogInfo>
                </>)}
                preventClose
                hideBackdrop
                closeDialog={() => goToUrl("/")}
            />);
        }

        if (accessRequested) {
            return (
                <AuthPage hero={{ image: "generic" }}>
                    <AuthForm>
                        <DoneImageContainer>
                            <DoneImage src={getStaticUrl("/images/auth/done-check.gif")} />
                        </DoneImageContainer>
                        <AuthFormHeader>Your request has been sent!</AuthFormHeader>
                        <AuthFormSubheader>We’ll notify you when you request has been approved.</AuthFormSubheader>
                        <ReturnToLibraryButtonContainer>
                            <ReturnToLibraryButton onClick={() => goToUrl("/")}>return to library</ReturnToLibraryButton>
                        </ReturnToLibraryButtonContainer>
                    </AuthForm>
                </AuthPage >
            );
        }

        return (
            <AuthPage hero={{ image: "generic" }}>
                <AuthForm title={"You don’t have access to this folder."}>
                    <AuthFormSubheader>Request access, or log in to an account with access.</AuthFormSubheader>
                    <TextArea
                        isVisible
                        readOnly={fetchingRequestAccess}
                        text={customMessage}
                        onChange={customMessage => this.setState({ customMessage })}
                        placeholder="Add a message (optional)..."
                    />
                    <RequestAccessButton
                        onClick={this.handleRequestAccess}
                        disabled={fetchingRequestAccess}
                    >
                        {fetchingRequestAccess && <CircularProgress size={24} />}
                        {!fetchingRequestAccess && "request access"}
                    </RequestAccessButton>
                    <Divider />
                    <AuthFormSubheaderHeavy>You are currently logged into Beautiful.ai as:</AuthFormSubheaderHeavy>
                    <UserInfoContainer>
                        <UserInfo>
                            {firebaseUser.photoURL && <img src={firebaseUser.photoURL} />}
                            {!firebaseUser.photoURL && <Icon>person</Icon>}
                            <p>{firebaseUser.displayName ?? firebaseUser.email}</p>
                        </UserInfo>
                    </UserInfoContainer>
                    <LogIntoDifferentAccountButton
                        disabled={fetchingRequestAccess}
                        onClick={() => goToUrl(`/logout?continue=${encodeURIComponent(window.location.pathname)}`)}
                    >
                        Log into a different account
                    </LogIntoDifferentAccountButton>
                </AuthForm>
            </AuthPage>
        );
    }
}

export class RequestAccessDialog extends Component {
    state = { isFadingOut: false };

    fadeOut = () => new Promise(resolve => {
        this.setState({ isFadingOut: true });
        setTimeout(resolve, 500);
    });

    goToUrl = (url, delayMs = 0) => {
        clearTimeout(this.gotToUrlTimeout);
        this.gotToUrlTimeout = setTimeout(
            () => this.fadeOut().then(() => window.location = url),
            delayMs
        );
    }

    render() {
        const { folderId } = this.props;
        const { isFadingOut } = this.state;

        return (
            <Container>
                <Authenticate defaultPage="signIn" reason="folder-access-request">
                    <FirebaseUserConsumer>
                        {firebaseUser => (
                            <RequestAccessContentContainer opacity={isFadingOut ? 0 : 1}>
                                <RequestAccessDialogContent
                                    firebaseUser={firebaseUser}
                                    folderId={folderId}
                                    goToUrl={this.goToUrl}
                                />
                            </RequestAccessContentContainer>
                        )}
                    </FirebaseUserConsumer>
                </Authenticate>
            </Container>
        );
    }
}

const ProvideAccessContentContainer = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    font-family: Source Sans Pro;
    line-height: 120%;
    letter-spacing: 0.5px;

    >p {
        max-width: calc(100% - 100px);
    }

    p {
        overflow-wrap: break-word; 
    }
`;

const FolderTitle = styled.p`
    margin-top: 20px;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #666666;
`;

const RequestAccessTitle = styled.p`
    margin-top: 35px;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #222222;
    line-height: 1.2;
`;

const RequestAccessSubtitle = styled.p`
    margin-top: 20px;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.5px;
    text-align: center;
    color: #222222;
`;

const CustomMessageContainer = styled.div`
    margin-top: 15px;
    width: 500px;
    background: #F7FAFC;
    border-radius: 2px;
    padding: 10px;

    >p {
        font-family: Source Sans Pro;
        font-size: 16px;
        line-height: 125%;
        color: #666666;
    }
`;

const ActionButtonsContainer = styled.div`
    margin: 40px 50px;
    width: calc(100% - 100px);
    height: 40px;

    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
`;

const StyledDialog = styled(BeautifulDialog)`
    ${props => props.isMobileOrTablet && css`
        &&& {
        .MuiDialog-paper {
            margin: 0;
            width: 100%;
        }
        } : null
    `}
`;

const CancelButton = styled(BlueOutlinedButton)`
    &&& {
        height: 40px;
        color: #666666;
        border: none;
    }
`;

const CloseButton = styled(BlueOutlinedButton)`
    &&& {
        margin: 40px 50px;
        height: 40px;
        border: none;
    }
`;

const ProvideAccessButton = styled(BlueButton)`
    &&& {
        height: 40px;
        border-radius: 1px;
    }
`;

const ProvideAccessDoneImage = styled.img`
    margin-top: 50px;
    height: 50px;
`;

class ProvideAccessDialogContent extends Component {
    state = {
        loading: true,
        fetching: false,
        accessAlreadyGranted: false,
        userRecord: null,
        teamFolder: null,
    }

    async componentDidMount() {
        const { folderId, teamMemberId } = this.props;

        const userRecord = await getUserProfile(teamMemberId);
        const teamFolder = ds.teams.get(folderId);
        const accessAlreadyGranted = !Object.keys(teamFolder.get("folderAccessRequests") || {}).includes(teamMemberId);

        this.setState({ loading: false, accessAlreadyGranted, userRecord, teamFolder });
    }

    handleProvideAccess = async () => {
        const { folderId, teamMemberId } = this.props;

        this.setState({ fetching: true });

        try {
            await Api.teamPermissions.post({
                type: "grant_team_folder_access", teamFolderId: folderId, teamMemberId
            });
            this.setState({ fetching: false, permissionProvided: true });
        } catch (err) {
            logger.error(err, "ProvideAccessDialogContent handleProvideAccess() failed", { folderId, teamMemberId });

            this.setState({
                fetching: false,
                error: {
                    message: err.status === 403 ? "Unable to add user." : "Oops! Our server had an error.",
                    info: err.status === 403 ? "User is not a member of your workspace." : "Try again later."
                }
            });
        }
    }

    render() {
        const { closeDialog, customMessage } = this.props;
        const { loading, userRecord, teamFolder, fetching, accessAlreadyGranted, permissionProvided, error } = this.state;

        if (loading) {
            return <CircularProgress />;
        }

        if (error) {
            return (
                <ErrorDialog
                    message={(<>
                        <ErrorDialogMessage>{error.message}</ErrorDialogMessage>
                        <ErrorDialogInfo>{error.info}</ErrorDialogInfo>
                    </>)}
                    closeDialog={closeDialog}
                />
            );
        }

        const displayName = userRecord.displayName || userRecord.email;
        const folderName = teamFolder.get("name");

        if (accessAlreadyGranted) {
            return (
                <BeautifulDialog closeDialog={closeDialog}>
                    <ProvideAccessContentContainer>
                        <ProvideAccessDoneImage src={getStaticUrl("/images/auth/done-check.gif")} />
                        <RequestAccessTitle>Access already granted</RequestAccessTitle>
                        <RequestAccessSubtitle>You already granted {displayName} access to {folderName} folder</RequestAccessSubtitle>
                        <CloseButton onClick={closeDialog}>
                            close
                        </CloseButton>
                    </ProvideAccessContentContainer>
                </BeautifulDialog>
            );
        }

        if (permissionProvided) {
            return (
                <BeautifulDialog closeDialog={closeDialog}>
                    <ProvideAccessContentContainer>
                        <ProvideAccessDoneImage src={getStaticUrl("/images/auth/done-check.gif")} />
                        <RequestAccessTitle>Success</RequestAccessTitle>
                        <RequestAccessSubtitle>You granted {displayName} access to {folderName} folder</RequestAccessSubtitle>
                        <CloseButton onClick={closeDialog}>
                            close
                        </CloseButton>
                    </ProvideAccessContentContainer>
                </BeautifulDialog>
            );
        }

        return (
            <StyledDialog isMobileOrTablet={app.isMobileOrTablet} closeDialog={closeDialog}>
                <ProvideAccessContentContainer>
                    <FetchingClickShield visible={fetching}><CircularProgress /></FetchingClickShield>
                    <Gap30 />
                    <Icon color="primary" fontSize="large">folder_shared</Icon>
                    <FolderTitle>{folderName}</FolderTitle>
                    <RequestAccessTitle>{displayName} is requesting access to this folder{customMessage ? ":" : ""}</RequestAccessTitle>
                    {customMessage && <CustomMessageContainer>
                        <p>{customMessage}</p>
                    </CustomMessageContainer>}
                    <ActionButtonsContainer>
                        {app.isMobileOrTablet
                            ? <ProvideAccessButton onClick={this.handleProvideAccess}>
                                allow access
                            </ProvideAccessButton>
                            : <>
                                <CancelButton onClick={closeDialog}>
                                    cancel
                                </CancelButton>
                                <div>
                                    <ProvideAccessButton onClick={this.handleProvideAccess}>
                                        allow access
                                    </ProvideAccessButton>
                                </div>
                            </>
                        }
                    </ActionButtonsContainer>
                </ProvideAccessContentContainer>
            </StyledDialog>
        );
    }
}

export class ProvideAccessDialog extends Component {
    render() {
        const { folderId, teamMemberId, closeDialog } = this.props;

        return (
            <FirebaseUserConsumer>
                {firebaseUser => (
                    <ProvideAccessDialogContent
                        firebaseUser={firebaseUser}
                        folderId={folderId}
                        teamMemberId={teamMemberId}
                        closeDialog={() => {
                            closeDialog();
                            AppController.showLibrary({
                                filter: {
                                    type: "team_folder", folderId
                                }
                            });
                        }}
                    />
                )}
            </FirebaseUserConsumer>
        );
    }
}
