import React, { Fragment } from "react";
import { Icon, Button } from "@material-ui/core";
import styled from "styled-components";

import { formatter } from "js/core/utilities/formatter";
import PromoField from "js/react/views/UserOptions/components/PromoField";
import Api from "js/core/api";
import { Last4DigitsPaymentMethod } from "js/react/views/UserOptions/Billing/PaymentMethod";
import UpdateBillingAddressDialog from "js/react/views/UserOptions/dialogs/UpdateBillingAddressDialog";
import { Divider } from "js/react/components/UiComponents";
import { Gap30, Gap10 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import PaymentIssueDialog from "js/react/views/UserOptions/Billing/PaymentIssueDialog";
import { SubscriptionStatus } from "common/constants";

export function CancelSubscribtionMessage({
    isInDunning, nextBillDate, isInTrial, isOrganization
}) {
    if (!isOrganization) {
        if (isInDunning) {
            return (<Fragment>
                You will be immediately downgraded to the Basic plan.
            </Fragment>);
        } else {
            return (<Fragment>
                Your plan will remain active until the end of your current billing cycle on {nextBillDate} after which you will be downgraded to the Basic (free) plan.
            </Fragment>);
        }
    } else {
        if (isInTrial) {
            return (<Fragment>
                <p>
                    Your plan will remain active until the end of the trial period on {nextBillDate}.
                </p>
                <p>
                    Please ensure your team transfers any presentations you'd like to continue to access to another workspace before the end of your trial.
                </p>
            </Fragment>);
        } else {
            return (<Fragment>
                <p>
                    Your plan will remain active until the end of the current billing cycle on {nextBillDate}.
                </p>
                <p>
                    Please ensure your team transfers any presentations you'd like to continue to access to another workspace before the end of your subscription.
                </p>
            </Fragment>);
        }
    }
}
function NextBillMessage({ subscription, nextInterval }) {
    const nextBillDate = formatter.formatTimestamp(subscription.current_period_end, true);

    if (subscription.status == "trialing") {
        if (subscription.cancel_at_period_end && subscription.product.includes("team")) {
            return (
                <span>
                    Your trial has been canceled.
                    <br />
                    <br />
                </span>
            );
        } else if (subscription.cancel_at_period_end) {
            return (
                <span>
                    Your subscription has been canceled. You will be downgraded to the Basic (free) plan when your
                    trial ends on {nextBillDate}.
                </span>
            );
        } else {
            return (
                <span>
                    Your first {nextInterval}ly bill of <strong>${(subscription.upcomingInvoice.amount_due / 100).toFixed(2)}</strong> will be due when your free trial ends
                    on <strong>{nextBillDate}</strong>.
                </span>
            );
        }
    } else {
        if (subscription.cancel_at_period_end && subscription.product.includes("team")) {
            return (
                <span>
                    Your plan has been canceled.
                    <br />
                    <br />
                </span>
            );
        } else if (subscription.cancel_at_period_end) {
            return (
                <span>
                    Your subscription has been canceled. You will be downgraded to the Basic (free) plan at the end
                    of your current billing period on <strong>{nextBillDate}</strong>.
                </span>
            );
        } else {
            return (
                <span>
                    Your next {nextInterval}ly bill of <strong>${(subscription.upcomingInvoice.amount_due / 100).toFixed(2)}</strong> is due on{" "}
                    <strong>{nextBillDate}</strong>.
                </span>
            );
        }
    }
}

export const Discount = function Discount({ subscription, onPromoApplied, callback, handleOnClear, error, nextInterval }) {
    if (!subscription.upcomingInvoice) {
        return null;
    }
    return (
        <div style={{ marginTop: ".5em" }}>
            {(subscription.upcomingInvoice.discount && error !== null)
                ? <DiscountDescription subscription={subscription} />
                : <DiscountField nextInterval={nextInterval} callback={callback} subscription={subscription} onPromoApplied={onPromoApplied}
                    error={error}
                    handleOnClear={handleOnClear} />
            }
        </div>
    );
};

function DiscountDescription({ subscription }) {
    return (
        <span style={{
            fontSize: 15,
            textTransform: "uppercase",
            fontWeight: 900,
        }}><PromoIcon /> {subscription.upcomingInvoice.discount.name}{" "}
            ({subscription.upcomingInvoice.discount.offer})
        </span>
    );
}

function PromoIcon() {
    return (
        <Icon style={{ color: "#8FB130", fontSize: 22.5, verticalAlign: -6, paddingRight: 3 }}>confirmation_number</Icon>
    );
}

class DiscountField extends React.Component {
    state = {
        isEditing: false,
        error: null
    };

    handleFetchPromo = async code => {
        const { callback, subscription, nextInterval } = this.props;
        // basic validation to report nice errors
        const promo = await Api.promos.get({ code });

        // apply on click allows us to show the promo description
        // before applying it to the invoice
        if (promo.error) {
            throw new Error(promo.error);
        }

        // Stripe is willing to accept coupons which fail the appliesTo condition,
        // it just doesn't result in a discount at invoice-time. That's not ideal, so catch it.
        const plan = subscription.product.includes("team") ? "team" : "pro";
        if (promo.appliesTo && !promo.appliesTo.includes(plan)) {
            throw new Error(`This coupon can't be applied to a ${plan} plan`);
        }

        let hasMinimumAmountRestriction = false;
        if (promo.minimumAmount) {
            // verify the promo code is valid for the correct amount
            await Api.promos.put({
                code: promo.code,
                subscription: subscription.id,
                product: {
                    planName: subscription.product,
                    productType: nextInterval ?? subscription.interval
                },
                action: "validate"
            }).then(async promoSuccess => {
                if (promoSuccess.error) {
                    throw new Error(promoSuccess.error);
                } else {
                    hasMinimumAmountRestriction = true;
                }
            });
        }

        if (callback) {
            // allow the caller to check the promo before applying it
            return callback(promo);
        }

        return await this.applyPromo(promo, hasMinimumAmountRestriction);
    };

    applyPromo = async (promo, hasMinimumAmountRestriction = false) => {
        const { subscription, onPromoApplied } = this.props;
        let apply = null;

        // coupon does not have a minimum amount restriction
        if (!hasMinimumAmountRestriction) {
            // stripe performs full restriction validation
            apply = await Api.promos.post({
                promoCodeId: promo.id,
                subscription: subscription.id
            });
        } else {
            apply = await Api.promos.put({
                subscription: subscription.id,
                promoCouponId: promo.promoCouponId,
                action: "apply"
            });
        }

        if (apply.error) {
            throw new Error(apply.error);
        }

        if (onPromoApplied) {
            // give the user a chance to see it worked
            setTimeout(() => onPromoApplied(promo), 2000);
        }

        return promo;
    };

    render() {
        const { isEditing } = this.state;
        const { handleOnClear, subscription, error } = this.props;
        if (!isEditing && error === null) {
            return (
                <span
                    style={{
                        cursor: "pointer",
                        color: "#159BCD",
                        fontSize: 14,
                        textTransform: "uppercase",
                        fontWeight: 900,
                    }}
                    onClick={() => this.setState({ isEditing: true })}
                >
                    <PromoIcon />
                    Add Promo Code
                </span>
            );
        } else {
            return (
                <div style={{ maxWidth: 400 }}>
                    <PromoField
                        error={error}
                        fetchPromo={this.handleFetchPromo}
                        fetchKey={subscription.planId}
                        onClear={() => {
                            this.setState({ error: null });
                            if (handleOnClear) {
                                handleOnClear();
                            }
                        }}
                        autoFocus
                    />
                </div>
            );
        }
    }
}

const BillingDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const BillingDetailsContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #666;
`;

const StyledDivider = styled(Divider)`
&&& {
    margin-top: 12px;
    margin-bottom: 24px;
}`;

export const NextPaymentSection = function({
    isTeam,
    subscription,
    nextInterval,
    availableCredit,
    paymentMethod,
    onChangePaymentMethod,
    onUpdateBillingDetails,
    onUpdateAddress,
    reloadBillingData,
    charge,
    orgId
}) {
    const handleConfirmPayment = () => {
        ShowDialog(PaymentIssueDialog, {
            orgId,
            subscription,
            charge,
            callback: success => success && reloadBillingData()
        });
    };

    const formatAddress = ({ line1, line2, city, state, postal_code, country }) => (
        <span>
            {line1 && `${line1}, `}
            {line2 && `${line2}, `}
            {city && `${city}, `}
            {state && `${state} `}
            {postal_code && `${postal_code} `}
            {country && `${country}`}
        </span>
    );

    // infer credit usage
    let invoiceCreditUsed = 0;
    if (subscription.upcomingInvoice) {
        const invoice = subscription.upcomingInvoice;
        if (invoice.amount_due < invoice.total) {
            // invoice is paid partly from balance
            invoiceCreditUsed = invoice.total - invoice.amount_due;
        } else if (invoice.total < 0) {
            // invoice itself contains net credit
            invoiceCreditUsed = availableCredit - (-invoice.total);
        } else {
            // invoice contains credit but not net
            invoiceCreditUsed += subscription.upcomingInvoice.referralCredit;
        }
    }

    const isEnterprise = subscription.product === "enterprise";
    const price = subscription.items.data[0].price;
    const isRegularPricing = price.nickname.startsWith("Team-") || price.nickname.startsWith("Pro-");
    const isPastDue = subscription.status === SubscriptionStatus.PAST_DUE;

    const { customerAddress, customerEmail, customerName, paymentMethodAddress } = subscription;

    return (
        <div className="billing-block">
            <div className="title">Payment method</div>
            <div className="contents">
                <div style={{
                    fontSize: 14,
                    marginBottom: 10
                }}>
                    {!isEnterprise && <>
                        <FlexBox center horizontalAlign="between">
                            <div>
                                {
                                    paymentMethod
                                        ? <Last4DigitsPaymentMethod
                                            key={paymentMethod.id}
                                            {...paymentMethod}
                                            fullWidth={false}
                                        />
                                        : <div>Credit card data missing</div>
                                }
                            </div>
                            <Button
                                color="primary"
                                onClick={onChangePaymentMethod}
                            >
                                {paymentMethod ? "Edit" : "Add"}
                            </Button>
                        </FlexBox>
                        <StyledDivider/>
                    </>}
                    <>
                        <BillingDetailsContainer>
                            <div className="title">Billing details</div>
                            <FlexBox center horizontalAlign="between">
                                <BillingDetailsContent>
                                    {customerName && <div>{customerName}</div>}
                                    {customerEmail && <div>{customerEmail}</div>}
                                    <div>
                                        {formatAddress(customerAddress ?? paymentMethodAddress ?? {
                                            line1: "",
                                            line2: "",
                                            city: "",
                                            state: "",
                                            postal_code: "",
                                            country: "",
                                        })}
                                    </div>
                                </BillingDetailsContent>

                                <Button
                                    color="primary"
                                    onClick={onUpdateBillingDetails}
                                >
                                    Edit
                                </Button>
                            </FlexBox>
                        </BillingDetailsContainer>
                        <StyledDivider/>
                    </>
                    <NextBillMessage subscription={subscription} nextInterval={nextInterval} />
                    {!isEnterprise && isRegularPricing && (
                        <>
                            <Gap30 />
                            <Discount subscription={subscription} onPromoApplied={reloadBillingData} />
                        </>
                    )}
                    {!isEnterprise && !isTeam && !subscription.cancel_at_period_end && availableCredit > 0 && (
                        <div style={{ fontSize: "90%", paddingTop: 10 }}>
                            <Icon style={{ color: "#fa0", fontSize: "1.25em", verticalAlign: -5, paddingRight: 3 }}>monetization_on</Icon>{" "}
                            {invoiceCreditUsed == availableCredit
                                ? <>Your <b>${(invoiceCreditUsed / 100).toFixed(2)}</b> credit has been applied to this bill.</>
                                : <><b>${(invoiceCreditUsed / 100).toFixed(2)}</b> of your <b>${(availableCredit / 100).toFixed(2)}</b> credit has been applied to this bill.</>
                            }
                        </div>
                    )}
                    {isEnterprise && <div
                        onClick={() => ShowDialog(UpdateBillingAddressDialog, { address: subscription.customerAddress, onAccept: onUpdateAddress, name: subscription.customerName, isOrganization: !!isTeam || !!isEnterprise })}
                        style={{ color: "#159BCD", cursor: "pointer", textDecoration: "underline", fontWeight: 900, fontSize: 14, marginTop: 10 }}
                    >
                        Update billing address
                    </div>}
                    {isPastDue && <>
                        <Gap10 />
                        <div className="past-due">
                            Past Due
                            {!isEnterprise && charge?.failure_code === "requires_action" && <>
                                <Button
                                    onClick={handleConfirmPayment}
                                >
                                    Confirm payment
                                </Button>
                            </>}
                        </div>
                    </>}
                </div>
            </div>
        </div>
    );
};
