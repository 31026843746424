import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ThemeProvider } from "@material-ui/core/styles";
import {
    PlayCircleFilled as PlayCircleFilledIcon,
    Stop as StopIcon
} from "@material-ui/icons";
import getLogger, { LogGroup } from "js/core/logger";
import { prepareToUnlockAudio, unlockAudio } from "js/core/utilities/audioUtilities";
import * as geom from "js/core/utilities/geom";
import { Key } from "js/core/utilities/keys";
import { BlueButton } from "js/react/components/UiComponents";

import { Gap10 } from "js/react/components/Gap";
import { PropertyPanelHeader } from "../../../editor/ElementEditor/PropertyPanelHeader";
import { dialogTheme } from "../../materialThemeOverrides";
import { AnimationEditor } from "./Components/AnimationEditor/AnimationEditor";

const logger = getLogger(LogGroup.ANIMATION);

export const AnimationPanelTabType = {
    ANIMATION: "animation",
    AUDIO: "audio"
};

const Container = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background: #f1f1f1;
`;

const ContentContainer = styled.div`
    width: 100%;
    height: calc(100% - 100px);
    padding: 10px;
`;

const PreviewButtonContainer = styled.div`
    width: 100%;
    padding: 10px;
`;

const PreviewButton = styled(BlueButton)`
    &&& {
        height: 40px;
        pointer-events: auto;
        border-radius: 4px;
    }
`;

function AnimationPanel({
    isReadOnly,
    currentCanvasController,
    ...props
}) {
    const canvas = currentCanvasController.canvas;

    const [isPreviewDisabled, setPreviewDisabled] = useState(true);
    const [isAnimating, setAnimating] = useState(canvas.isAnimating);
    const [animatingAnimationIds, setAnimatingAnimationIds] = useState([]);
    const previewButtonContainerRef = useRef();

    useEffect(() => {
        prepareToUnlockAudio()
            .then(() => setPreviewDisabled(false))
            .catch(err => logger.error(err, "[AnimationPanel] prepareToUnlockAudio() failed"));
    }, [0]);

    const animate = async () => {
        if (isAnimating) {
            canvas.stopAnimation();
            return;
        }

        unlockAudio();

        const previewButtonBounds = geom.Rect.FromBoundingClientRect(previewButtonContainerRef.current.getBoundingClientRect()).deflate(10);

        const removeClickShield = canvas.showClickShield(
            event => {
                if (previewButtonBounds.contains(event.pageX, event.pageY)) {
                    canvas.stopAnimation();
                }
            },
            event => {
                if (event.which == Key.ESCAPE) {
                    canvas.stopAnimation();
                }
            },
            "wait"
        );

        setAnimating(true);
        canvas.animate(
            (progress, animationHasFutureWaitForClick, animatingAnimationIds) => setAnimatingAnimationIds(animatingAnimationIds),
            false
        ).then(() => {
            setAnimatingAnimationIds([]);
            setAnimating(false);

            removeClickShield();
        });
    };

    return (
        <ThemeProvider theme={dialogTheme}>
            <Container>
                <PropertyPanelHeader>Animation</PropertyPanelHeader>
                <Gap10 />
                <PreviewButtonContainer ref={previewButtonContainerRef}>
                    <PreviewButton
                        onClick={animate}
                        startIcon={isAnimating ? <StopIcon /> : <PlayCircleFilledIcon />}
                        disabled={isPreviewDisabled}
                        fullWidth
                        disableElevation
                    >
                        {isAnimating ? "Press ESC to stop" : "Preview"}
                    </PreviewButton>
                </PreviewButtonContainer>
                <ContentContainer>
                    <AnimationEditor
                        {...props}
                        canvas={canvas}
                        isReadOnly={isReadOnly}
                        animate={animate}
                        isAnimating={isAnimating}
                        animatingAnimationIds={animatingAnimationIds}
                    />
                </ContentContainer>
            </Container>
        </ThemeProvider>
    );
}

export default AnimationPanel;
