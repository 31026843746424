import { GenerateSlideNoteType } from "../../../common/aiConstants";
import aiGenerationService from "../services/aiGeneration";
import { trackActivity } from "../utilities/utilities";
import { _ } from "../../vendor";
import getLogger, { LogGroup } from "../logger";

const logger = getLogger(LogGroup.AI);

type GenSlideNotesTrackProps = {
    notesType: GenerateSlideNoteType,
    results?: string[],
};

type GenSlideNotesResult = {
    slideNotes: string,
    trackProps: GenSlideNotesTrackProps,
}

type GenSlideNotesState = Partial<{
    results: GenSlideNotesResult[],
    isGenerating: boolean,
    error: any,
}>

type GenSlideNotesProps = {
    notesType: GenerateSlideNoteType,
    slideIndex: number,
    slideText?: string,
    notesText?: string,
    prevSlideText?: string,
    nextSlideText?: string,
    prevSlideNotes?: string,
    onReportState?: (state: GenSlideNotesState) => void,
}

export async function generateSlideNotes({
    notesType,
    slideText = "",
    notesText = "",
    slideIndex = 0,
    prevSlideText = "",
    nextSlideText = "",
    prevSlideNotes = "",
    onReportState = () => { },
}: GenSlideNotesProps) {
    onReportState({
        results: [],
        isGenerating: true,
        error: null,
    });

    let textResults: string[] = [];
    try {
        for (let retryCount = 0; retryCount < 1; retryCount++) {
            const { results } = await aiGenerationService.generateSlideNotes({
                notesType: notesType,
                slideText: slideText,
                notesText: notesText,
                slideIndex: slideIndex,
                prevSlideText: prevSlideText,
                nextSlideText: nextSlideText,
                prevSlideNotes: prevSlideNotes
            });

            textResults.push(...results);
        }

        // Throw an error if we have no valid result
        if (!textResults.length) {
            throw new Error("[generateSlideNotes] Unable to generate a results.");
        }

        const trackProps: GenSlideNotesTrackProps = {
            notesType: notesType,
            results: textResults,
        };

        trackActivity("SlideNotes", "Generate", "", 0, trackProps);

        const results = textResults.map(slideNotes => (<GenSlideNotesResult>{
            slideNotes,
            trackProps,
        }));

        onReportState({
            results,
            isGenerating: false,
        });
    } catch (error) {
        logger.error(error, "[GenerateSlideNotes]", { textResults });

        onReportState({
            isGenerating: false,
            error,
        });
    }
}
