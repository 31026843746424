import React, { Component } from "react";
import { Popover, Icon } from "@material-ui/core";
import { ChromePicker } from "react-color";
import styled from "styled-components";

const ColorChitContainerDiv = styled.div`
    position: relative;
    width: 32px;
    height: 32px;
    float: left;
    display: inline-block;
    border-radius: 50%;
    background: ${props => props.previewBackground};
    .material-icons {
        opacity: 0;
        position: absolute;
        top: -4px;
        right: -4px;
        font-size: 16px;
        border-radius: 50%;
        background: white;
        cursor: pointer;
    }
    &:hover {
        .material-icons {
            opacity: 1
        }
    }
`;

const ColorChitDiv = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: solid 1px #bbb;
`;

class ColorChit extends Component {
    constructor(props) {
        super(props);

        this.colorChitDivRef = React.createRef();

        this.state = {
            anchorEl: null,
        };
    }

    componentDidUpdate = () => {
        if (this.props.isNewColor) {
            this.openPicker();
        }
    }

    componentDidMount = () => {
        window.addEventListener("keydown", this.enterPressed);
    }

    componentWillUnmount = () => {
        window.removeEventListener("keydown", this.enterPressed);
    }

    enterPressed = event => {
        if (event.key === "Enter") {
            this.closePicker();
        }
    }

    handleChange = color => {
        let rgba = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;

        this.props.onChange && this.props.onChange(rgba);
    };

    openPicker = () => {
        this.setState({
            anchorEl: this.colorChitDivRef.current,
        });
    };

    closePicker = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { color, onRemove } = this.props;

        return (
            <ColorChitContainerDiv style={this.props.style} data-color={color} previewBackground={this.props.previewBackground || "white"}>
                <ColorChitDiv
                    ref={this.colorChitDivRef}
                    onClick={this.openPicker}
                    style={{ backgroundColor: color || "#ccc" }}
                />
                {onRemove && <Icon onClick={onRemove}>cancel</Icon>}
                <Popover
                    open={Boolean(this.state.anchorEl)}
                    anchorEl={this.state.anchorEl}
                    disableEnforceFocus
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    onClose={this.closePicker}
                >
                    <ChromePicker
                        color={color}
                        style={{ boxShadow: "none" }}
                        onChange={this.handleChange}
                        onChangeComplete={this.handleChange}
                    />
                </Popover>
            </ColorChitContainerDiv>
        );
    }
}

export default ColorChit;
