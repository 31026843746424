import React, { Component } from "react";
import styled from "styled-components";

import { DialogTitle, DialogActions, TextField } from "@material-ui/core";
import { AddressElement } from "@stripe/react-stripe-js";

import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import { _ } from "js/vendor";
import { TaxIdForm } from "js/react/views/UserOptions/Billing/TaxIdForm";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import { BlueButton, BlueOutlinedButton } from "js/react/components/UiComponents";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import withElements from "js/react/views/UserOptions/Billing/withElements";
import withStripe from "js/react/views/UserOptions/Billing/withStripe";
import { FlexBox } from "js/react/components/LayoutGrid";
import { emailRegex } from "js/core/utilities/regex";
import { guessUserCountry } from "js/react/views/UserOptions/Billing/utils";

import { ShowErrorDialog } from "../../../components/Dialogs/BaseDialog";

const logger = getLogger(LogGroup.BILLING);

const appearance = {
    labels: "floating",
    variables: {
        fontFamily: "Source Sans Pro, sans-serif",
        borderRadius: "0px",
        colorPrimary: "#11a9e2",
        colorDanger: "#ff0000",
        gridRowSpacing: "12px",
        colorTextSecondary: "#666",
        inputFloatingPaddingTop: "0",
        fontSizeSm: "14px",
        fontWeightNormal: "400",
        fontWeightBold: "600",
        colorText: "black",
    },
    rules: {
        ".Input": {
            fontSize: "16px",
            color: "black",
            backgroundColor: "#fff",
            border: "none",
            borderBottom: "1px solid #ced4da",
            padding: "7px 0",
            outline: "none",
            transition: "border-color 0.3s ease-in-out",
            boxShadow: "none",
        },
        ".Field--labelResting": {
            paddingTop: "0",
        },
        ".Label--empty": {
            margin: "10px 0",
            fontSize: "1rem",
            color: "var(--colorTextSecondary)",
        },
        ".Label--focused": {
            margin: "0",
            color: "#11a9e2",
        },
        ".Label--floating": {
            color: "var(--colorTextSecondary)",
            fontSize: "var(--labelRestingFontSize)",
            lineHeight: "16px",
            opacity: "1",
            padding: "0",
            transition: "all 300ms cubic-bezier(0.5, 0, 0.2, 1)",
        },
        ".Input:focus": {
            borderColor: "#11a9e2",
            boxShadow: "none",
        },
        ".Input:focus:hover": {
            borderColor: "#11a9e2",
            boxShadow: "none",
        },
        ".Input--invalid": {
            borderBottom: "1px solid red",
            boxShadow: "none",
        },
        ".Input:hover": {
            borderColor: "#11a9e2"
        }
    }
};

const ErrorMessage = styled.div`
    margin-top: 4px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.112px;
    color: red;
`;

const StyledTextField = styled(TextField)`
    &&&{
        .MuiInputBase-input::-webkit-input-placeholder {
            font-style: normal;
        }

        .MuiInput-underline:not(.Mui-disabled)::before {
            border-bottom: ${({ warning }) => warning ? `2px solid red` : `2px solid #11a9e2`};
        }  

        .MuiInputLabel-shrink{
            transform: translateY(7px) scale(0.8888888888888888);
            font-size: 16px;
            font-weight: 600;
            opacity: 0.8;
        }

    }
`;

// TaxIdForm has inner style, avoid overriding it as much as possible
// because it might show unexpected behavior
const StyledTaxIdForm = styled(TaxIdForm)`
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-bottom: 12px;

    .MuiInputBase-input::-webkit-input-placeholder {
        font-style: normal;
    }

    .MuiInput-underline:hover:not(.Mui-disabled)::before {
        border-bottom: 2px solid #11a9e2;
    }      

    .MuiInputLabel-shrink{
        transform: translateY(7px) scale(0.8888888888888888);
        font-size: 16px;
        font-weight: 600;
        opacity: 0.8;
    }
`;

class UpdateBillingDetailsDialog extends Component {
    constructor(props) {
        super(props);

        const { customerEmail, customerAddress, customerName, taxInfo, paymentMethodAddress } = props.subscription;

        this.state = {
            name: customerName || "",
            customerEmail: customerEmail ?? "",
            isEmailValid: true,
            address: { ...(customerAddress || paymentMethodAddress || {}) },
            isAddressValid: true,
            fetching: false,
            taxIdValue: taxInfo.value || "",
            taxIdType: taxInfo.type || "",
            isTaxIdValid: true,
            isTaxIdFieldDirty: false,
            isEmailFieldDirty: false,
            isAddressFieldDirty: null,
            taxIdError: null,
            showError: false
        };
    }

    handleAddressChange = ({ complete, value: { address, name } }) => {
        const { customerAddress, paymentMethodAddress } = this.props.subscription;

        const addressToCompore = customerAddress || paymentMethodAddress || {};

        const customizer = (objValue, othValue) => {
            if ((objValue === "" && othValue === null) || (objValue === null && othValue === "")) {
                return true;
            }
        };

        const areEqual = _.isEqualWith(address, addressToCompore, customizer);

        if (!areEqual) {
            this.setState({ address: { ...address }, isAddressValid: complete, name, isAddressFieldDirty: true });
        }
    }

    handleTaxIdChange = ({ taxId, taxIdType, isTaxIdValid }) => {
        const { taxInfo } = this.props.subscription;

        const areEqual = _.isEqualWith(taxInfo.value, taxId);

        if (!areEqual) {
            this.setState({ taxIdValue: taxId, taxIdType, isTaxIdValid, isTaxIdFieldDirty: true, taxIdError: null });
        }
    }

    handleEmailChange = event => {
        const email = event.target.value;
        this.setState({ customerEmail: email, isEmailValid: emailRegex.test(email), isEmailFieldDirty: true });
    }

    handleUpdate = async () => {
        const { onAccept, closeDialog, orgId, customerType } = this.props;
        const { address, name, taxIdValue, taxIdType, customerEmail, isAddressFieldDirty, isEmailFieldDirty, isTaxIdFieldDirty } = this.state;

        this.setState({ fetching: true });

        Api.subscriptions.put({
            customerType,
            ...isAddressFieldDirty ? { address, name } : {},
            ...isEmailFieldDirty ? { customerEmail } : {},
            ...isTaxIdFieldDirty ? { taxIdValue, taxIdType } : {},
            orgId,
            type: "update_billing_details",
        }).then(async () => {
            await onAccept();
            closeDialog();
        }).catch(err => {
            this.setState({ fetching: false });
            logger.error(err, "updateBillingDetails() failed", { workspaceId: orgId ?? "personal" });

            if (err.code === "tax_id_invalid") {
                this.setState({ taxIdError: err.message });
            } else {
                const message = err.status === 400
                    ? <>{err.message}</>
                    : <>Sorry, we couldn't save your changes. Please contact us at <a href="mailto:support@beautiful.ai">support@beautiful.ai</a></>;

                ShowErrorDialog({
                    title: "Error",
                    message
                });
            }
        });
    }

    handleBlur = () => {
        this.setState({ showError: true });
    };

     handleFocus = () => {
         this.setState({ showError: false });
     };

     render() {
         const { hideBackdrop, closeDialog, canCancel = true, isOrganization = false } = this.props;
         const { fetching,
             address,
             isAddressValid,
             name,
             customerEmail,
             isTaxIdValid,
             taxIdValue,
             isEmailValid,
             isEmailFieldDirty,
             isAddressFieldDirty,
             isTaxIdFieldDirty,
             taxIdError,
             showError } = this.state;

         return (
             <BeautifulDialog
                 hideBackdrop={hideBackdrop}
                 closeDialog={() => { }}
             >
                 <DialogTitle>
                     Update Billing Details
                 </DialogTitle>
                 <DialogContent>
                     <FetchingClickShield visible={fetching} backgroundColor="rgba(255,255,255,0.5)" />

                     <FlexBox left style={{
                         display: "flex",
                         flexDirection: "column",
                         marginBottom: "12px"
                     }}>
                         <StyledTextField
                             label="Billing email"
                             warning={!isEmailValid}
                             onBlur={this.handleBlur}
                             onFocus={this.handleFocus}
                             fullWidth
                             onChange={this.handleEmailChange}
                             value={customerEmail}
                         />
                         {!isEmailValid && showError && <ErrorMessage>{"Invalid Email"}</ErrorMessage>}
                     </FlexBox>
                     <StyledTaxIdForm
                         isSignUpB
                         variant="standard"
                         countryCode={address.country || guessUserCountry()}
                         initialTaxId={taxIdValue}
                         onChange={this.handleTaxIdChange}
                         disabled={false}
                         helperText={taxIdError}
                     />
                     <AddressElement
                         options={{
                             mode: "billing",
                             defaultValues: {
                                 name,
                                 address
                             },
                             display: {
                                 name: isOrganization ? "organization" : "full"
                             },
                         }}
                         onChange={this.handleAddressChange}
                     />
                 </DialogContent>
                 <DialogActions>
                     {canCancel && <BlueOutlinedButton
                         variant="text"
                         color="default"
                         disabled={fetching}
                         onClick={closeDialog}>
                         Cancel
                     </BlueOutlinedButton>}
                     <BlueButton
                         variant="text"
                         disabled={
                             fetching ||
                                (isAddressFieldDirty && !isAddressValid) ||
                                (isTaxIdFieldDirty && !isTaxIdValid) ||
                                (isEmailFieldDirty && !isEmailValid)
                         }
                         onClick={this.handleUpdate}>
                         Save
                     </BlueButton>
                 </DialogActions>
             </BeautifulDialog>
         );
     }
}

export default withStripe(withElements(UpdateBillingDetailsDialog, { appearance }));
