import React, { Component, Fragment } from "react";
import { v4 as uuid } from "uuid";
import moment from "moment";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { Icon as MaterialIcon, Tooltip, DialogTitle } from "@material-ui/core";

import getLogger, { LogGroup } from "js/core/logger";
import { app } from "js/namespaces";
import Loadable from "js/react/components/Loadable";
import { BlueButton, DarkButton } from "js/react/components/UiComponents";
import { TextInput } from "js/react/views/UserOptions/components";
import { BeautifulDialog, DialogContent, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { BlueSwitch } from "js/react/components/Switch";
import Api from "js/core/api";
import { Gap20 } from "js/react/components/Gap";
import Icon from "js/react/components/Icon";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import { Divider } from "js/Components/Divider";

const logger = getLogger(LogGroup.SSO);

const SubSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .title-text {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }
    .description-text {
        font-size: 14px;
        color: #666;
    }
`;

const ApplyChangeButton = styled(BlueButton)`
    &&& {
        &:disabled {
            opacity: 0.6;
            background-color: #11a9e2;
            span {
                color: white;
            }
        }
    }
`;

const styles = {
    adminContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: "10px",
        "& button": {
            marginLeft: "20px"
        }
    },
    avatar: {
        color: "#ccc",
        backgroundColor: "#424242"
    },
    admin: {
        marginLeft: "15px",
        whiteSpace: "nowrap"
    },
    organizationName: {
        fontSize: "18px",
        marginBottom: "10px"
    },
    organizationInfo: {
        lineHeight: "24px"
    },
    organizationInfoWarning: {
        lineHeight: "24px",
        color: "#ff6d6d"
    },
    textInput: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
        width: "100%",
        gap: "5px"
    },
    buttonsContainer: {
        marginTop: "20px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: "20px"
    },
    applyButton: {
        backgroundColor: "#11a9e2",
    },
    metadataContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        marginTop: "10px",
    },
    parseMetadataButton: {
        width: "160px",
        whiteSpace: "nowrap",
        marginLeft: "10px"
    },
    ssoNotesContainer: {
        backgroundColor: "#383a3f",
        padding: "10px 20px",
        margin: "5px 0",
        "& h3": {
            color: "white",
            fontSize: "15px",
            fontWeight: 600,
            marginBottom: "5px"
        },
        "& p": {
            lineHeight: "20px"
        },
        "& pre": {
            margin: 0
        }
    },
    addAdminButton: {
        backgroundColor: "#11a9e2",
        marginTop: "20px"
    },
    verifyDomainButton: {
        backgroundColor: "#11a9e2",
        marginTop: "10px"
    },
    certTitle: {
        display: "block",
        fontSize: 14,
        color: "#aaa",
        margin: "0 5px 5px 0"
    },
    isVerifiedText: {
        lineHeight: "24px",
        marginLeft: 3,
        display: "inline-flex"
    },
    textAlignRight: {
        textAlign: "right"
    },
    displayFlex: {
        display: "flex"
    },
    fontSizeSmall: {
        fontSize: "15px !important"
    },
    certsContainer: {
        padding: "20px 10px"
    },
    domainVericationDescription: {
        color: "#aaa",
        marginBottom: 16
    },
    bold: {
        fontWeight: 900
    }
};

class SSOConfigPane extends Component {
    initialState = {
        authUser: app.user.getAuthUser(),
        isOrganizationFetching: true,
        isOrganizationUpdateFetching: false,
        hasChanges: false,
        metadataUrl: "",
        admins: [],
        certsReadyToDelete: {},
        isCopied: false,
        certs: []
    };

    state = { ...this.initialState };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.organizationId !== this.props.organizationId) {
            this.setState(this.initialState, () => {
                this.loadData();
            });
        }
    }

    loadData() {
        Api.sso.get({ id: this.props.organizationId })
            .then(organization => {
                this.setStateFromOrganization(organization);
                this.setState({ isOrganizationFetching: false });
            })
            .catch(err => {
                logger.error("[SSOConfigPane] loadData() failed");

                ShowErrorDialog({
                    error: "Error",
                    message: "Failed to load your organization. Please email support@beautiful.ai for assistance."
                });
            });
    }

    /**
     * Converts an organization model object to a plain state object
     */
    setStateFromOrganization(organization) {
        this.setState({
            // This field is used for reverting not applied changes
            rawOrganization: organization,

            domain: organization.domain,
            domainVerificationKey: organization.domainVerificationKey,
            isDomainVerified: organization.isDomainVerified,
            domainVerifiedAt: organization.domainVerifiedAt,
            domainVerificationError: organization.domainVerificationError,

            teamId: organization.defaultTeamId,

            admins: organization.admins,

            allowJit: !!organization.userProvisioning?.allowJit,
            allowScim: !!organization.userProvisioning?.allowScim,
            scimToken: organization.userProvisioning?.scimToken ?? uuid(),

            allowIdpInitiated: !!organization.sso?.allowIdpInitiated,
            strict: !!organization.sso?.strict,

            certs: Object.values(organization?.sso?.samlConfig?.certs ?? {}),
            entryPoint: organization?.sso?.samlConfig?.entryPoint ?? "",
            idpIssuer: organization?.sso?.samlConfig?.idpIssuer ?? "",
        });
    }

    handleCopytoClipboard = () => {
        const { domainVerificationKey } = this.state;
        this.setState({ isCopied: true });
        clipboardWrite({
            [ClipboardType.TEXT]: domainVerificationKey,
        });
        setTimeout(() => {
            this.setState({ isCopied: false });
        }, 1000);
    }

    /**
     * Converts the component state into an organization model object.
     */
    getOrganizationUpdatesFromState() {
        return {
            userProvisioning: {
                allowJit: this.state.allowJit,
                allowScim: this.state.allowScim,
                scimToken: this.state.scimToken
            },
            sso: {
                allowIdpInitiated: this.state.allowIdpInitiated,
                samlConfig: {
                    audience: this.state.audience,
                    entryPoint: this.state.entryPoint,
                    idpIssuer: this.state.idpIssuer,
                    issuer: this.state.issuer
                },
                strict: this.state.strict
            }
        };
    }

    handleFieldChange(fieldName, fieldValue) {
        this.setState({ [fieldName]: fieldValue, hasChanges: true });
    }

    applyChanges() {
        this.setState({ isOrganizationUpdateFetching: true });
        const organizationUpdates = this.getOrganizationUpdatesFromState();

        Api.sso.put({ id: this.props.organizationId }, organizationUpdates)
            .then(updatedOrganization => {
                this.setStateFromOrganization(updatedOrganization);
                this.setState({ isOrganizationUpdateFetching: false, hasChanges: false });
                this.props.onConnect();
            })
            .catch(err => {
                logger.error("[SSOConfigPane] applyChanges() failed");
                this.setState({ isOrganizationUpdateFetching: false });

                ShowErrorDialog({
                    error: "Error",
                    message: "Failed to apply changes. Please email support@beautiful.ai for assistance."
                });
            });
    }

    disableSSO() {
        this.setState({ isOrganizationUpdateFetching: true });

        const organizationUpdates = { sso: null, userProvisioning: null };

        Api.sso.put({ id: this.props.organizationId }, organizationUpdates)
            .then(updatedOrganization => {
                this.setStateFromOrganization(updatedOrganization);
                this.setState({ isOrganizationUpdateFetching: false, hasChanges: false });
                this.props.onDisconnect();
            })
            .catch(err => {
                logger.error("[SSOConfigPane] disableSSO() failed");
                this.setState({ isOrganizationUpdateFetching: false });

                ShowErrorDialog({
                    error: "Error",
                    message: "Failed to apply changes. Please email support@beautiful.ai for assistance."
                });
            });
    }

    parseMetadata() {
        this.setState({ isOrganizationUpdateFetching: true });

        Api.parseSamlMetadata.get({ id: this.props.organizationId, url: this.state.metadataUrl })
            .then(parsedSamlConfig => {
                this.setState({
                    certs: parsedSamlConfig.certs,
                    idpIssuer: parsedSamlConfig.issuerUrl,
                    entryPoint: parsedSamlConfig.samlSSOEndpoint,
                    hasChanges: true,
                    metadataUrl: "",
                    isOrganizationUpdateFetching: false
                });
            })
            .catch(err => {
                logger.error("[SSOConfigPane] parseMetadata() failed", { url: this.state.metadataUrl });
                this.setState({ isOrganizationUpdateFetching: false });

                ShowErrorDialog({
                    error: "Error",
                    message: err.status === 400 ? err.message : "Failed to parse metadata"
                });
            });
    }

    verifyDomain() {
        this.setState({ isOrganizationUpdateFetching: true });

        Api.verifySsoDomain.post({ id: this.props.organizationId })
            .then(({ isDomainVerified, domainVerificationError, domainVerifiedAt }) => {
                this.setStateFromOrganization({
                    ...this.state.rawOrganization,
                    isDomainVerified,
                    domainVerificationError,
                    domainVerifiedAt,
                    isOrganizationUpdateFetching: false
                });
                this.setState({ isOrganizationUpdateFetching: false });
            })
            .catch(err => {
                logger.error("[SSOConfigPane] verifyDomain() failed");
                this.setState({ isOrganizationUpdateFetching: false });

                ShowErrorDialog({
                    error: "Error",
                    message: "We hit an unexpected error while trying to verify your domain. Please email support@beautiful.ai for assistance."
                });
            });
    }

    render() {
        const { isOrganizationFetching, isOrganizationUpdateFetching, hasChanges, allowJit, allowScim, scimToken, domain, isDomainVerified, domainVerificationError, domainVerificationKey, domainVerifiedAt, allowIdpInitiated, strict, metadataUrl, certs, entryPoint, idpIssuer, isCopied } = this.state;
        const { classes } = this.props;

        return (
            <Loadable isLoading={isOrganizationFetching}>
                <SubSection>
                    <div className="title-text">Domain Verification</div>
                    <Gap20 />
                    <div className={classes.organizationInfo} style={{ marginBottom: isDomainVerified ? 0 : 16 }}>
                        <span className={classes.bold}>Domain:</span> {domain}
                        {isDomainVerified && (
                            <span className={classes.isVerifiedText}>
                                is verified
                                <Icon
                                    style={{
                                        fontSize: 15,
                                        color: "#11a9e2",
                                        marginLeft: 4,
                                        alignSelf: "center"
                                    }}
                                    iconName="verified"
                                />
                            </span>
                        )}
                    </div>
                    {!isDomainVerified && (
                        <Fragment>
                            <div className={classes.domainVericationDescription}>
                                <p>We need to verify ownership of your domain to ensure you can administer accounts
                                    for {domain}. This is required to enable Single Sign On.</p>
                                <p>Please add the following TXT record to your domain's DNS records.</p>
                            </div>
                            <p className={classes.organizationInfo}>
                                <span className={classes.bold}>Name/Host/Alias:</span> Please leave blank.
                            </p>
                            <p className={classes.organizationInfo} style={{ marginBottom: 16 }}>
                                <span className={classes.bold}>Domain verification key:</span> {domainVerificationKey}
                                <Tooltip title="Add a TXT record containing this value to your DNS configuration to get your domain verified. Click to copy to clipboard." placement="bottom-start">
                                    <MaterialIcon
                                        style={{
                                            fontSize: "14px",
                                            marginLeft: "7px",
                                            marginBottom: "-2px",
                                            cursor: "pointer"
                                        }}
                                        onClick={this.handleCopytoClipboard}
                                    >
                                        {isCopied ? "check" : "file_copy"}
                                    </MaterialIcon>
                                </Tooltip>
                            </p>
                            <div>
                                <p className={classes.organizationInfoWarning}>
                                    <MaterialIcon
                                        style={{
                                            fontSize: "15px",
                                            marginRight: "2px",
                                            marginBottom: "-2px",
                                        }}
                                    >
                                        error
                                    </MaterialIcon>
                                    Domain is not verified, last attempt was {domainVerifiedAt ? moment(domainVerifiedAt).fromNow() : "never"}
                                </p>
                                {domainVerificationError && <p className={classes.organizationInfoWarning}>Verification error: {domainVerificationError}</p>}
                                <BlueButton
                                    className={classes.verifyDomainButton}
                                    disabled={isOrganizationUpdateFetching}
                                    onClick={() => this.verifyDomain()}>
                                    Verify domain now
                                </BlueButton>
                            </div>
                        </Fragment>
                    )}
                </SubSection>
                <Divider margin={20} />
                <SubSection>
                    <div className="title-text">SAML SSO</div>
                    <Gap20 />
                    {!isDomainVerified &&
                        <p className={classes.organizationInfoWarning}>Please verify your domain before setting up SSO</p>}
                    {isDomainVerified && (
                        <div>
                            <div className={classes.metadataContainer}>
                                <TextInput
                                    tooltipTitle="Entering this url will automatically fill out the next two fields. If you don’t have this you can skip this and manually enter the information in the following fields."
                                    label="IDP Metadata URL"
                                    labelAlignment="left"
                                    id="metadataUrl"
                                    curValue={metadataUrl}
                                    handleChange={event => this.setState({ metadataUrl: event.target.value })}
                                    disabled={isOrganizationUpdateFetching}
                                    classNames={[classes.textInput]}
                                />
                                <BlueButton
                                    disabled={!metadataUrl || isOrganizationUpdateFetching}
                                    onClick={() => this.parseMetadata()}
                                    className={classes.parseMetadataButton}>
                                    Parse Metadata
                                </BlueButton>
                            </div>
                            <Gap20 />
                            <TextInput
                                tooltipTitle="This is the URL we will redirect your users when they try to login in via Beautiful.ai instead of your Identity Provider."
                                label="SAML SSO Endpoint"
                                labelAlignment="left"
                                id="entryPoint"
                                curValue={entryPoint}
                                handleChange={event => this.handleFieldChange("entryPoint", event.target.value)}
                                disabled={isOrganizationUpdateFetching}
                                classNames={[classes.textInput]}
                            />
                            <Gap20 />
                            <TextInput
                                tooltipTitle="This is the unique identifier of your Identity Provider. We use this to validate the SAML assertions we receive are issued from your identity provider."
                                label="Issuer URL"
                                labelAlignment="left"
                                id="idpIssuer"
                                curValue={idpIssuer}
                                handleChange={event => this.handleFieldChange("idpIssuer", event.target.value)}
                                disabled={isOrganizationUpdateFetching}
                                classNames={[classes.textInput]}
                            />
                            <Gap20 />
                            <div className={classes.displayFlex}>
                                <label className={classes.certTitle}>X509 Certificate(s)</label>
                                <Tooltip title="This is the public key that your IdP will send with every SAML Response. We will use this to ensure the Response we received is from your IdP (matches this X509 Certificate and that the assertion has not been tampered)." placement="bottom-start">
                                    <i className={`micon ${classes.fontSizeSmall}`}>
                                        info
                                    </i>
                                </Tooltip>
                            </div>
                            <div className={`block ${classes.certsContainer}`}>
                                {certs.map((cert, index) => (<div key={index}>{cert.substr(0, 10)}...{cert.substr(-8, cert.length - 1)}</div>))}
                            </div>
                            <Gap20 />
                            <BlueSwitch
                                tooltipTitle="Enabling IdP initiated access will allow users to login into beautiful.ai via your identity provider’s application portal. Disabling access will only allow SP (Service Provider) Initiated access (i.e. users will have to start their authentication process at beautiful.ai/login)."
                                checked={allowIdpInitiated}
                                onChange={() => this.handleFieldChange("allowIdpInitiated", !allowIdpInitiated)}
                                label="Allow IDP initiated flow"
                                disabled={isOrganizationUpdateFetching}
                            />
                            <BlueSwitch
                                tooltipTitle="With Strict SSO enabled, users will be required to login with SSO. With Strict SSO disabled, users will be able to login with both SSO and email/password.
Please note: If there are users (including you) from your organization who have been using beautiful.ai prior to SSO, they will be locked out if they don’t have access provisioned in your identity provider’s platform. We recommend keeping  the Strict SSO option disabled first and testing SSO functionality before enabling Strict SSO."
                                checked={strict}
                                onChange={() => this.handleFieldChange("strict", !strict)}
                                label="Enforce strict SSO"
                                disabled={isOrganizationUpdateFetching}
                            />
                            <div className={classes.buttonsContainer}>
                                <ApplyChangeButton
                                    disabled={!hasChanges || isOrganizationUpdateFetching}
                                    onClick={() => this.applyChanges()}
                                    className={classes.applyButton}
                                >
                                    Apply Changes and Enable SSO
                                </ApplyChangeButton>
                                <DarkButton onClick={() => this.disableSSO()}>
                                    Clear Form and Disable SSO
                                </DarkButton>
                            </div>
                        </div>
                    )}
                </SubSection>
                <Divider margin={20} />
                <SubSection>
                    <div className="title-text">User Provisioning</div>
                    <Gap20 />
                    <BlueSwitch
                        checked={allowJit}
                        onChange={() => this.handleFieldChange("allowJit", !allowJit)}
                        label="Allow JIT provisioning"
                        disabled={isOrganizationUpdateFetching}
                    />
                    <BlueSwitch
                        checked={allowScim}
                        onChange={() => this.handleFieldChange("allowScim", !allowScim)}
                        label="Enable SCIM provisioning"
                        disabled={isOrganizationUpdateFetching}
                    />
                    {allowScim &&
                        <TextInput
                            label="SCIM Bearer Token"
                            id="scimToken"
                            curValue={scimToken}
                            handleChange={event => this.handleFieldChange("scimToken", event.target.value)}
                            disabled={isOrganizationUpdateFetching}
                            classNames={[classes.textInput]}
                        />}
                </SubSection>
            </Loadable>
        );
    }
}

export const SSOConfigPaneWithStyles = withStyles(styles)(SSOConfigPane);

export class SSOConfigDialog extends Component {
    render() {
        return (
            <BeautifulDialog closeDialog={this.props.closeDialog} closeButton>
                <DialogTitle>Single Sign-On</DialogTitle>
                <DialogContent>
                    <SSOConfigPaneWithStyles {...this.props} />
                </DialogContent>
            </BeautifulDialog>
        );
    }
}
