import React, { Component } from "react";
import styled from "styled-components";
import { ReactMic } from "react-mic";
import { CircularProgress, LinearProgress } from "@material-ui/core";

import Waveform from "../Waveform";
import SavingAudioDialog from "./SavingAudioDialog";

import {
    ShowErrorDialog
} from "js/react/components/Dialogs/BaseDialog";
import { ds } from "js/core/models/dataService";
import getLogger, { LogGroup } from "js/core/logger";
import { AssetType, TaskType, TaskState } from "common/constants";
import { uploadFileAndCreateTask } from "js/core/services/tasks";
import { ShowWarningDialog, ShowDialogAsync } from "js/react/components/Dialogs/BaseDialog";
import { Key } from "js/core/utilities/keys";
import { Gap10, Gap20 } from "../../../../components/Gap";
import { HorizontalPropertyList } from "../../../../../EditorComponents/PropertyPanel";
import { Icon } from "js/Components/Icon";
import { Button } from "js/Components/Button";
import { IconButton, RoundIconButton } from "../../../../../Components/IconButton";
import { WithLabel } from "../../../../../Components/WithLabel";
import { FlexBox } from "../../../../components/LayoutGrid";
import { themeColors } from "../../../../sharedStyles";

const logger = getLogger(LogGroup.AUDIO);

const RECORDED_AUDIO_NAME = "recorded-audio";

const Container = styled.div`
    width: 100%;
`;

const AudioPreviewContainer = styled.div`
    height: 80px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
`;

const WaveformAndControlsContainer = styled.div`
    //width: 100%;
    //background: #ffffff;
    //padding: 10px;
`;

const WaveformContainer = styled.div.attrs(({ height = 80 }) => ({
    style: {
        height: `${height}px`
    }
}))`
    position: relative;
    width: 100%;
    display: block;
    overflow: hidden;
    background: white;
    border: 1px solid #EEEEEE;
`;

const WaveformPreloader = styled(LinearProgress)`
    &&& {
        position: absolute;
        left: 40px;
        top: 50%;
        width: calc(100% - 80px);
        height: 2px;
        transform: translateY(-1px);
    }
`;

const WaveformPlaceholder = styled.div`
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-1px);
    width: calc(100% - 80px);
    height: 2px;
    background: #11a9e2;
`;

const AudioSizeNoteText = styled.div`
    margin-top: auto;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 300;
    color: #333333;
    position: absolute;
    bottom: 0;
`;

const AudioDescriptionContainer = styled.div`
    position: absolute;
    bottom: ${({ centerPositioned }) => centerPositioned ? "50%" : "6px"};
    transform: ${({ centerPositioned }) => centerPositioned ? "translateY(50%)" : "none"};
    right: 6px;
    width: calc(100% - 12px);
    z-index: 9999;
    color: ${({ hasError }) => hasError ? "#ff0000" : "#777"};

    display: flex;
    justify-content: space-between;
    align-items: center;

    text-transform: uppercase;
    font-size: 10px;
    font-weight: 300;
    font-family: monospace;

    > span {
        max-width: ${({ hasError }) => hasError ? "unset" : "45%"};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

function AudioDescription({ name = "", duration = "", errorMessage = "", centerPositioned = false }) {
    return (
        <AudioDescriptionContainer centerPositioned={centerPositioned} hasError={!!errorMessage}>
            {errorMessage && <span>{errorMessage}</span>}
            {!errorMessage && <>
                <span>{name}</span>
                <span>{duration}</span>
            </>}
        </AudioDescriptionContainer>
    );
}

const ReactMicContainer = styled.div.attrs(({ hidden }) => ({
    style: {
        opacity: hidden ? 0 : 1
    }
}))`
    position: absolute;
    height: 100%;
    width: 100%;
`;

const ReactMicStyled = styled(ReactMic)`
    height: 100%;
    width: 100%;
`;

function padZeros(number, length) {
    let string = `${number}`;
    while (string.length < length) {
        string = `0${string}`;
    }
    return string;
}

function formatDuration(durationSeconds) {
    let seconds = durationSeconds;
    const hours = Math.floor(seconds / (60 * 60));
    seconds -= hours * 60 * 60;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return `${padZeros(hours, 2)}:${padZeros(minutes, 2)}:${seconds.toFixed(3)}`;
}

export class AudioEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            audioUrl: null,
            audioName: null,
            audioDuration: null,
            isFetchingAudio: true,
            isAudioFailedToLoad: false,
            isImportingAudio: false,
            isRecordingAudio: false,
            isInRecordingMode: false,
            isUploadingRecordedAudio: false,
            isPlayingAudio: false,
            audioRecordPermission: "denied",
        };

        this.recordedAudioFile = null;
        this.actualRecordedAudioMimeType = null;
        this.recordingDurationSeconds = 0;
        this.recordingTimer = null;

        this.hasUploadedAudio = false;

        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onKeyDown);

        this.loadAudio();
        this.loadAudioRecordPermission();
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown);

        if (this.uploadFileAndCreateTaskPromise) {
            ShowDialogAsync(SavingAudioDialog, {
                uploadFileAndCreateTaskPromise: this.uploadFileAndCreateTaskPromise
            });
        }
    }

    onKeyDown = event => {
        const { isRecordingAudio, isPlayingAudio, isImportingAudio } = this.state;

        if (event.which !== Key.ESCAPE) {
            return;
        }

        if (isRecordingAudio || isPlayingAudio || isImportingAudio) {
            event.stopPropagation();

            if (isRecordingAudio) {
                this.handleStopRecording();
            } else if (isPlayingAudio) {
                this.onAudioFinishedPlaying();
            }
        }
    }

    loadAudioRecordPermission = async () => {
        let permission;
        if (navigator.permissions && navigator.permissions.query) {
            permission = await navigator.permissions.query({ name: "microphone" });
        }

        const permissionState = permission?.state ?? "granted";
        this.setState({ audioRecordPermission: permissionState });

        if (permissionState === "denied" || permissionState === "prompt") {
            // Force the browser to show the mic option in toolbar
            navigator.mediaDevices.getUserMedia({ audio: true });
        }

        if (permission) {
            permission.onchange = event => {
                this.setState({ audioRecordPermission: event.target.state });
            };
        }
    }

    loadAudio = async () => {
        const { canvas } = this.props;

        try {
            // Make sure we refresh w/o audioUrl to unmount the Waveform component
            await new Promise(resolve => this.setState({
                audioUrl: null,
                audioName: null,
                isFetchingAudio: true,
                isAudioFailedToLoad: false
            }, resolve));

            const audioAssetId = canvas.dataModel.get("audioAsset");
            if (!audioAssetId) {
                this.setState({ isFetchingAudio: false });
                return;
            }

            const asset = await ds.assets.getAssetById(audioAssetId, AssetType.AUDIO);
            const audioUrl = await asset.getBaseUrl();
            const audioName = asset.get("name");
            this.setState({ audioUrl, audioName });
        } catch (err) {
            logger.error(err, "AudioEditor loadAudio() failed", { slideId: canvas.dataModel.id });
        }
    }

    onAudioLoaded = ({ durationSeconds }) => {
        this.setState({ isFetchingAudio: false, isAudioFailedToLoad: false, audioDuration: formatDuration(durationSeconds) });
    }

    onAudioLoadFailed = () => {
        this.setState({ isFetchingAudio: false, isAudioFailedToLoad: true });

        if (this.hasUploadedAudio) {
            ShowErrorDialog({
                title: "Failed to load audio",
                message: "Please try another audio file"
            });
        }
    }

    onAudioFinishedPlaying = () => {
        this.setState({ isPlayingAudio: false });
    }

    handlePlayAudio = () => {
        this.setState({ isPlayingAudio: true });
    }

    handleImportAudio = () => {
        const { isInRecordingMode } = this.state;
        if (isInRecordingMode) {
            // Force exit record mode
            this.setState({ isInRecordingMode: false });
        }

        this.fileInputRef.current.click();
    }

    handleFileSelected = async event => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }

        this.setState({ isImportingAudio: true });
        await this.uploadAndTranscodeAudio(file);
        this.loadAudio();
        this.setState({ isImportingAudio: false });
    }

    checkFileSize = file => {
        const maxAudioSizeBytes = 10 * 1024 * 1024; // 10 MB

        if (file.size > maxAudioSizeBytes) {
            throw new Error("File is too large to upload");
        }
    }

    uploadAndTranscodeAudio = async file => {
        const { canvas } = this.props;

        let assetId;
        try {
            this.checkFileSize(file);

            this.uploadFileAndCreateTaskPromise = new Promise((resolve, reject) => {
                uploadFileAndCreateTask(
                    file,
                    TaskType.AUDIO_UPLOAD,
                    task => {
                        switch (task.state) {
                            case TaskState.ERROR:
                                return reject(new Error(task.errorMessage));
                            case TaskState.FINISHED:
                                resolve(task.assetId);
                        }
                    },
                    {
                        name: file.name.includes(".") ? file.name.split(".").slice(null, -1).join(".") : file.name
                    })
                    .catch(err => reject(err));
            });
            assetId = await this.uploadFileAndCreateTaskPromise;
            this.hasUploadedAudio = true;
            this.uploadFileAndCreateTaskPromise = null;
        } catch (err) {
            ShowWarningDialog({
                title: "Failed to process audio",
                message: err.message,
            });
            return;
        }

        canvas.dataModel.update({
            audioAsset: assetId
        });
    }

    handleEnterRecordingMode = () => {
        return new Promise(resolve => this.setState({ isInRecordingMode: true }, resolve));
    }

    handleExitRecordingMode = () => {
        this.recordedAudioFile = null;
        this.actualRecordedAudioMimeType = null;

        this.setState({ isInRecordingMode: false });
        this.loadAudio();
    }

    handleStartRecording = () => {
        if (this.soundtrack) {
            this.handleStopPreview();
        }

        this.recordedAudioFile = null;
        this.actualRecordedAudioMimeType = null;

        this.setState({ isRecordingAudio: true, audioUrl: null, audioName: RECORDED_AUDIO_NAME, audioDuration: formatDuration(0) });

        this.recordingDurationSeconds = 0;
        this.recordingTimer = setInterval(() => {
            this.recordingDurationSeconds += 0.01;
            this.setState({ audioDuration: formatDuration(this.recordingDurationSeconds) });
        }, 10);
    }

    handleStopRecording = () => {
        clearInterval(this.recordingTimer);

        this.setState({ isRecordingAudio: false, isUploadingRecordedAudio: true });
    }

    onReactMicRecordingStopped = async recordedAudioFile => {
        const { canvas } = this.props;
        const { isUploadingRecordedAudio } = this.state;

        this.recordedAudioFile = recordedAudioFile;

        this.setState({ audioUrl: null }, () =>
            this.setState({ audioUrl: this.recordedAudioFile.blobURL })
        );

        if (isUploadingRecordedAudio) {
            try {
                const file = new File([this.recordedAudioFile.blob], RECORDED_AUDIO_NAME, { type: this.actualRecordedAudioMimeType });
                await this.uploadAndTranscodeAudio(file);
                this.loadAudio();
                this.setState({ isUploadingRecordedAudio: false });
                this.handleExitRecordingMode();
            } catch (err) {
                logger.error(err, "AudioEditor onReactMicRecordingStopped() failed", { slideId: canvas.dataModel.id });
            }
        }
    }

    onReactMicData = recordedAudioFile => {
        this.actualRecordedAudioMimeType = recordedAudioFile.type;
    }

    handleDeleteAudio = () => {
        const { canvas } = this.props;

        canvas.dataModel.update({
            audioAsset: null
        });

        this.loadAudio();
    }

    handlePreview = () => {
        const { audioUrl } = this.state;

        this.soundtrack = new Audio(audioUrl);

        this.soundtrack.addEventListener("ended", () => {
            this.handleStopPreview();
        });
        this.soundtrack.play();

        this.setState({ isPlaying: true });
    }

    handleStopPreview = () => {
        this.soundtrack.pause();
        this.soundtrack.currentTime = 0;

        this.soundtrack = null;

        this.setState({ isPlaying: false });
    }

    render() {
        const {
            isReadOnly,
            isAnimating
        } = this.props;
        const {
            audioUrl,
            audioName,
            audioDuration,
            isFetchingAudio,
            isImportingAudio,
            isRecordingAudio,
            isPlayingAudio,
            isInRecordingMode,
            isUploadingRecordedAudio,
            audioRecordPermission,
            isPlaying,
            isAudioFailedToLoad
        } = this.state;

        const hasRecordedAudio = audioName === RECORDED_AUDIO_NAME;

        return (
            <Container>
                {(isInRecordingMode || hasRecordedAudio || audioUrl) && !isImportingAudio &&
                    <AudioPreviewContainer>
                        <WaveformContainer>
                            {isInRecordingMode &&
                                <ReactMicContainer hidden={!isRecordingAudio}>
                                    <ReactMicStyled
                                        record={isRecordingAudio}
                                        onStop={this.onReactMicRecordingStopped}
                                        onData={this.onReactMicData}
                                        strokeColor="#11a9e2"
                                        backgroundColor="white"
                                        mimeType="audio/webm;codecs=opus" // Safari ignores this and records MP4-wrapped AAC
                                    />
                                    <AudioDescription duration={audioDuration} />
                                </ReactMicContainer>
                            }
                            {!isRecordingAudio && <>
                                {audioUrl && <>
                                    <Waveform
                                        src={audioUrl}
                                        height={80}
                                        waveColor={themeColors.ui_blue}
                                        cursorColor="#ffffff"
                                        interact={false}
                                        fillParent={true}
                                        isPlaying={isPlayingAudio}
                                        onLoaded={this.onAudioLoaded}
                                        onLoadFailed={this.onAudioLoadFailed}
                                        onFinishedPlaying={this.onAudioFinishedPlaying}
                                    />
                                    {!isFetchingAudio && <AudioDescription duration={audioDuration} />}
                                </>}
                                {isFetchingAudio && <WaveformPreloader />}
                                {!isFetchingAudio && !audioUrl && <WaveformPlaceholder />}
                            </>}
                        </WaveformContainer>
                        {!isPlaying &&
                            <RoundIconButton small icon="play_arrow" size={30} fill
                                onClick={this.handlePreview}
                                disabled={isAnimating || audioRecordPermission !== "granted" || isPlayingAudio || isImportingAudio || isFetchingAudio || isReadOnly} />
                        }
                        {isPlaying &&
                            <RoundIconButton small icon="stop" size={30} fill
                                onClick={this.handleStopPreview}
                                disabled={isAnimating || audioRecordPermission !== "granted" || isPlayingAudio || isImportingAudio || isFetchingAudio || isReadOnly}
                            />
                        }
                    </AudioPreviewContainer>
                }
                {!isInRecordingMode && !hasRecordedAudio && !audioUrl && !isImportingAudio &&
                    <AudioPreviewContainer>
                        Record your narration<br />or upload an audio file...
                    </AudioPreviewContainer>
                }
                {isImportingAudio &&
                    <AudioPreviewContainer>
                        <CircularProgress />
                    </AudioPreviewContainer>
                }

                <Gap10 />

                <FlexBox gap={10} center height={40}>
                    {!isInRecordingMode &&
                        <Button small onClick={() => this.handleEnterRecordingMode().then(() => this.handleStartRecording())}
                            disabled={isAnimating || audioRecordPermission !== "granted" || isPlayingAudio || isImportingAudio || isFetchingAudio || isReadOnly}
                        >
                            <Icon>mic</Icon>Record
                        </Button>
                    }
                    {isInRecordingMode && !isUploadingRecordedAudio &&
                        <Button small onClick={this.handleStopRecording}
                            disabled={isAnimating || audioRecordPermission !== "granted" || isPlayingAudio || isImportingAudio || isFetchingAudio || isReadOnly}
                        >
                            <Icon>stop</Icon>Stop Recording
                        </Button>
                    }

                    {isInRecordingMode && isUploadingRecordedAudio &&
                        <FlexBox gap={10}>
                            <CircularProgress size={20} />
                            Saving Recording
                        </FlexBox>
                    }

                    {!isInRecordingMode &&
                        <Button small onClick={this.handleImportAudio}
                            disabled={isAnimating || audioRecordPermission !== "granted" || isPlayingAudio || isRecordingAudio || isImportingAudio || isFetchingAudio || isReadOnly}
                        >
                            <Icon>cloud_upload</Icon>Upload File...
                        </Button>
                    }

                </FlexBox>
                <input
                    ref={this.fileInputRef}
                    type="file"
                    hidden
                    accept="audio/*"
                    onChange={this.handleFileSelected}
                />
            </Container>
        );
    }
}

