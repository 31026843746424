import React, { Component } from "react";
import { Icon } from "../../Components/Icon";
import { MenuItem } from "../../Components/Menu";
import { Popup, PopupContent, PopupPreview } from "../../Components/Popup";
import { ToggleSwitch } from "../../Components/ToggleSwitch";
import { WithLabel } from "../../Components/WithLabel";
import { PropertyPanelContainer, PropertySection, SectionDescription, SectionTitle } from "../../EditorComponents/PropertyPanel";
import { PropertyPanelButton } from "../../EditorComponents/PropertyPanelButton";
import { CanvasExportType } from "../PresentationEditor/CanvasController";
import PresentationEditorController from "../PresentationEditor/PresentationEditorController";
import { PropertyPanelHeader } from "./PropertyPanelHeader";
import ProBadge from "js/react/components/ProBadge";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";
import { ShowUpgradeDialog } from "js/react/components/Dialogs/BaseDialog";
import { FeatureType } from "common/features";
import { stopPropagation } from "../../core/utilities/stopPropagation";
import { FlexBox } from "js/react/components/LayoutGrid";
import { isInternalBAIUser } from "js/config";
import AppController from "js/core/AppController";
import DebugController from "js/core/DebugController";

class ManageSlidePanel extends Component {
    render() {
        const { presentation, currentCanvasController, currentSlide } = this.props;

        const workspaceId = presentation.getWorkspaceId();
        const isLibrarySlide = currentSlide.isLibrarySlide();
        const canEditLibraryItems = app.user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, workspaceId);
        const allowSlideSharing = currentSlide.get("template_id") !== "slidePlaceholder";
        const isSlideSkipped = presentation.isSlideSkipped(currentSlide);
        const isLibOrOwner = ds.teams.isUserLibrarianOrOwner();
        const userIsBasicInWorkspace = app.user.features.isFeatureEnabled(FeatureType.UPGRADE, workspaceId);

        return (
            <PropertyPanelContainer onMouseDown={stopPropagation()}>
                <PropertyPanelHeader>Manage Slide</PropertyPanelHeader>
                {!isLibrarySlide &&
                    canEditLibraryItems &&
                    <PropertyPanelButton icon="present_to_all"
                        title="Share Slide With Team"
                        description="Add this slide to your team resources so other members of your team can reuse it."
                        disabled={!allowSlideSharing}
                        onClick={currentCanvasController.convertToSharedSlide}
                    />
                }

                {isLibrarySlide &&
                    <PropertyPanelButton icon="info"
                        title="Shared Slide Settings"
                        description="View and edit your Shared Slide properties."
                        disabled={!isLibOrOwner}
                        onClick={currentCanvasController.viewSharedSlideProperties}
                    />
                }

                {!isLibrarySlide &&
                    <PropertySection>
                        <div>
                            <SectionTitle><Icon>{isSlideSkipped ? "visibility_off" : "visibility"}</Icon>Skip Slide</SectionTitle>
                            <SectionDescription>You can skip this slide during playback.</SectionDescription>
                        </div>
                        <WithLabel label="Skip this Slide during playback">
                            <ToggleSwitch value={isSlideSkipped}
                                onChange={value => PresentationEditorController.setSkipSlide(currentSlide, value)}
                            />
                        </WithLabel>
                    </PropertySection>
                }

                {!isLibrarySlide &&
                <PropertyPanelButton icon="chat" title="Show Speaker Notes"
                    onClick={() => PresentationEditorController.toggleNotes()}
                />
                }

                {!isLibrarySlide &&
                    <Popup overrideContainerStyles={{ flexShrink: 0 }}>
                        <PopupPreview>
                            <PropertyPanelButton icon="cloud_download"
                                title="Export Slide..."
                                description="Export this slide to PPT, Google or PDF."
                            />
                        </PopupPreview>
                        <PopupContent>
                            {closePopup => (
                                <>
                                    <MenuItem onClick={() => {
                                        currentCanvasController.exportCanvas({ type: CanvasExportType.JPEG, slide: currentSlide });
                                        closePopup();
                                    }}>
                                        <Icon>panorama</Icon>Export Slide to JPEG
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        currentCanvasController.exportCanvas({ type: CanvasExportType.PPTX, slide: currentSlide });
                                        closePopup();
                                    }}>
                                        <Icon>cloud_download</Icon>Export Slide to PPTX
                                    </MenuItem>
                                    <MenuItem divider onClick={() => {
                                        currentCanvasController.exportCanvas({ type: CanvasExportType.GOOGLE, slide: currentSlide });
                                        closePopup();
                                    }}>
                                        <Icon>add_to_drive</Icon>Export Slide to Google Slides
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        PresentationEditorController.exportPresentation();
                                        closePopup();
                                    }}>
                                        Export Entire Presentation...
                                    </MenuItem>
                                </>
                            )}
                        </PopupContent>
                    </Popup>
                }

                {!isLibrarySlide &&
                    <FlexBox>
                        <PropertyPanelButton
                            icon="restore"
                            title="Revert to Previous Version..."
                            description="Show the version history for this slide and revert to a previous version if desired."
                            onClick={() => {
                                if (!userIsBasicInWorkspace) {
                                    PresentationEditorController.setSelectedPropertyPanelTab("versions");
                                } else {
                                    ShowUpgradeDialog({
                                        type: UpgradePlanDialogType.UPGRADE_PLAN,
                                        analytics: { cta: "revertToPreviousVersion", ...presentation.getAnalytics() },
                                        workspaceId
                                    });
                                }
                            }}
                        />
                        <ProBadge
                            upgradeType={UpgradePlanDialogType.UPGRADE_PLAN}
                            show={userIsBasicInWorkspace}
                            analytics={{ cta: "revertToPreviousVersion", ...presentation.getAnalytics() }}
                            workspaceId={workspaceId}
                            style={{ position: "absolute", width: 30, right: 5 }}
                        />
                    </FlexBox>
                }
                {isInternalBAIUser(AppController.user.getEmail()) &&
                    <PropertyPanelButton icon="bug_report"
                        title="Debug Panel"
                        description="Activate Debug Panel"
                        onClick={() => {
                            DebugController.showDebugButton();
                            PresentationEditorController.setSelectedPropertyPanelTab("debug");
                        }}
                    />
                }
            </PropertyPanelContainer>
        );
    }
}

export default PresentationEditorController.withState(ManageSlidePanel);
