import DecorationStyles from "js/react/components/DecorationStyles";
import { Gap10, Gap20 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import { ColorChit } from "js/react/views/Editor/Components/ColorPalette";
import { PopupMenu } from "js/react/views/Editor/PopupMenu";
import { tinycolor } from "js/vendor";
import { Fragment } from "react";
import React, { Component } from "reactn";
import styled from "styled-components";

const PaletteWarning = styled.div`
  position: absolute;
  top: 12px;
  background: rgba(255,255,255,0.85);
  padding: 10px;
  width: 100%;
  left: 0px;
  text-align: center;
  z-index: 100;
  font-weight: 600;
  color: black;
`;

export class ColorPalettePopupMenu extends Component {
    state = {
        showPresets: true,
        selectedColor: this.props.selectedColor
    }

    handleSelectColor(value) {
        this.setState({ selectedColor: value });
        this.props.onSelectColor(value);

        if (!this.props.showDecorationStyles) {
            this.props.closeDialog();
        }
    }

    render() {
        let { selectedColor } = this.state;
        let {
            palette,
            showDecorationStyles,
            showMutedDecorationStyles = true,
            position,
            onSelectDecorationStyle,
            autoLabel
        } = this.props;

        let selected = palette.find(c => c.value == selectedColor);
        let color;
        if (selected) {
            color = tinycolor(selected.color);
        }

        let presets = (
            <Fragment>
                <div className="color_palette" style={{ padding: 15 }}>
                    {palette.map(color => (
                        <ColorChit key={color.value}
                            {...color}
                            selected={color.value == selectedColor}
                            onClick={() => this.handleSelectColor(color.value)}
                            autoLabel={autoLabel}
                        />
                    ))}
                </div>

                {showDecorationStyles && showDecorationStyles() &&
                    <DecorationStyles
                        style={{ background: color?.getLuminance() > .95 ? "#ddd" : "#fff", padding: 15 }}
                        selectedColor={selectedColor}
                        showLabel={false}
                        allowGap={false}
                        showMutedDecorationStyles={showMutedDecorationStyles}
                        handleChangePresetOption={value => onSelectDecorationStyle(value)}/>
                }

            </Fragment>
        );

        let advanced = (
            <Fragment>
                <FlexBox>
                    <label>Fill</label>
                    <ColorChit color="red"/>
                    <Gap10/>
                    <label>Stroke</label>
                    <ColorChit color="blue"/>
                    <input type="number"/>
                </FlexBox>
                <Gap20/>
                <div className="toggle-button" onClick={() => this.setState({ showPresets: true })}>
                    Show Presets
                </div>
            </Fragment>
        );

        return (
            <PopupMenu {...this.props} position={position} className="style-popup-menu beautiful-ui" style={{ padding: 0 }}>
                {this.state.showPresets ? presets : advanced}
            </PopupMenu>
        );
    }
}

