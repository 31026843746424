import React, { Component } from "reactn";
import {
    Button,
    DialogActions,
    DialogTitle,
    DialogContent,
    Checkbox,
    FormControlLabel,
    FormControl,
    FormGroup
} from "@material-ui/core";
import { _ } from "js/vendor";
import { app } from "js/namespaces";
import { RowGrid } from "js/react/components/LayoutGrid";
import { Gap20 } from "js/react/components/Gap";
import { BeautifulDialog, ShowErrorDialog } from "../../../components/Dialogs/BaseDialog";
import Api from "../../../../core/api";
import getLogger, { LogGroup } from "js/core/logger";
import { trackActivity } from "js/core/utilities/utilities";
import { ds } from "js/core/models/dataService";
import getUserProfile from "js/core/services/userProfiles";

const logger = getLogger(LogGroup.TEAMS);

export default class AddExistingMemberDialog extends Component {
    state = {
        users: []
    }

    async componentDidMount() {
        const { team, organizationId } = this.props;
        const defaultTeam = ds.teams.defaultTeamForOrg(organizationId);
        const allMemberIds = Object.keys(defaultTeam.get("members"));
        const teamMemberIds = team.members.map(member => member.id);
        const nonDuplicateUserIds = _.difference(allMemberIds, teamMemberIds);
        const userProfiles = await Promise.all(nonDuplicateUserIds.map(memberId => getUserProfile(memberId)));
        const users = userProfiles.map(user => ({ email: user.email, isChecked: false, id: user.uid }));
        this.setState({ users });
    }

    static get type() {
        return "AddExistingMemberDialog";
    }

    handleAddMemberConfirm = async () => {
        const { team, organizationId } = this.props;
        const { users } = this.state;
        const checkedUsers = users.filter(user => user.isChecked);
        const existingTeamMemberCount = Object.keys(team.members).length;
        const newTeamMemberCount = checkedUsers.length;
        try {
            const userIds = checkedUsers.map(user => user.id);
            await Api.teams.put({
                teamId: team.id,
                orgId: this.props.organizationId,
                type: "add_existing_members",
                userIds
            });

            const props = {
                workspace_id: organizationId,
                team_id: team.id,
                team_name: team.name,
                userid_added: userIds,
                userid_removed: null,
                num_team_members: existingTeamMemberCount + newTeamMemberCount,
                num_changed: newTeamMemberCount,
                change_mechanism: "admin initiated"
            };
            trackActivity("OrgTeam", "MembershipChanged", null, null, props);

            this.props.closeDialog();
            this.props.callback();
        } catch (err) {
            logger.error(err, "AddExistingMemberDialog handleAddMemberConfirm() failed", { teamId: team.id, orgId: this.props.organizationId });

            ShowErrorDialog({
                error: "Unable to add team members.",
                message: err.message
            });
        }
    };

    handleChange = inputUser => {
        this.setState(prevState => {
            const users = prevState.users.map(user => {
                if (user.email === inputUser.email) {
                    return { email: user.email, isChecked: !user.isChecked, id: user.id };
                }
                return user;
            });

            return {
                ...prevState,
                users
            };
        });
    }

    render() {
        const { team } = this.props;
        const { users } = this.state;
        const disableAddMember = users.length ? !users.some(user => user.isChecked) : true;

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog} preventClose>
                <DialogTitle>Add users from your organization to {team.name}</DialogTitle>
                <DialogContent>
                    <Gap20 />
                    <RowGrid>
                        <FormControl>
                            <FormGroup>
                                {users.map((user, index) => {
                                    const label = app.user.getEmail() === user.email ? "me" : user.email;
                                    return (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    checked={user.isChecked}
                                                    onChange={() => this.handleChange(user)}
                                                    value={user.email}
                                                />
                                            }
                                            label={label}
                                        />
                                    );
                                })}
                            </FormGroup>
                        </FormControl>
                        <DialogActions>
                            <Button onClick={this.props.closeDialog}>Cancel</Button>
                            <Button disabled={disableAddMember} onClick={this.handleAddMemberConfirm} color="primary">
                                Confirm
                            </Button>
                        </DialogActions>
                    </RowGrid>
                </DialogContent>
            </BeautifulDialog>
        );
    }
}
