import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Icon } from "@material-ui/core";

import { app } from "js/namespaces";
import { Gap5 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import { stopPropagation } from "js/core/utilities/stopPropagation";

const IconButtonContainer = styled.div`
    margin: 0px 2px;
    opacity: 1;

    .MuiIcon-root {
        font-size: 20px !important;
        cursor: pointer;
        color: white;

        &:hover {
            color: #50bbe6 !important;
        }
    }

    &[disabled] {
        opacity: 0.3;

        .MuiIcon-root {
            color: white !important;
        }
    }
`;

const UndoRedo = () => {
    const [stackPosition, setStackPosition] = useState(app.undoManager.stackPosition);
    const [undoStack, setUndoStack] = useState(app.undoManager.undoStack);

    useEffect(() => {
        app.undoManager.on("undo redo undoStackChanged", onStackChange);
        return () => {
            return app.undoManager.off("undo redo undoStackChanged", onStackChange);
        };
    }, []);

    const onStackChange = () => {
        setStackPosition(app.undoManager.stackPosition);
        setUndoStack(app.undoManager.undoStack);
    };

    const onUndoClick = () => {
        app.undoManager.undo();
    };

    const onRedoClick = () => {
        app.undoManager.redo();
    };

    return (
        <FlexBox onMouseDown={stopPropagation()}>
            <IconButtonContainer
                onClick={onUndoClick}
                disabled={stackPosition === -1}
            >
                <Icon>undo</Icon>
            </IconButtonContainer>
            <Gap5 />
            <IconButtonContainer
                onClick={onRedoClick}
                disabled={stackPosition === undoStack.length - 1}
            >
                <Icon>redo</Icon>
            </IconButtonContainer>
        </FlexBox>
    );
};

export default UndoRedo;
