import React, { Component } from "react";
import { TextField as MaterialTextField } from "@material-ui/core";

export class TextField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value != this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    render() {
        const { value: propValue, onChange, onBlur, ...rest } = this.props;
        const { value } = this.state;

        return (
            <MaterialTextField
                {...rest}
                value={value}
                onKeyDown={event => {
                    event.stopPropagation();
                    if (event.key == "Enter") {
                        event.target.blur();
                    }
                }}
                onBlur={event => {
                    onBlur && onBlur(event.target.value);
                }}
                onChange={event => {
                    this.setState({ value: event.target.value });
                    onChange && onChange(event.target.value);
                }}
            />
        );
    }
}

