import React, { Component } from "react";
import styled from "styled-components";

import { $, _ } from "js/vendor";

const CanvasFrame = styled.div.attrs(({ opacity, width, height, boxShadow }) => ({
    style: {
        opacity: opacity ?? 1,
        width,
        height,
        boxShadow
    }
}))`
    &.disable-user-select {
        user-select: none !important;
    }
`;

export class CanvasWrapper extends Component {
    componentDidMount() {
        this.renderCanvas();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { canvas } = this.props;

        if (prevProps.canvas !== canvas) {
            this.renderCanvas();
        }
    }

    handleTransitionEnd = event => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    }

    async renderCanvas() {
        const { canvas, wrapperRef } = this.props;
        if (!canvas) {
            return;
        }

        $(wrapperRef.current).empty();
        $(wrapperRef.current).append(canvas.$el);
    }

    render() {
        const { canvasWidth, canvasHeight, slide, currentSlide, opacity, disableUserSelect, wrapperRef } = this.props;

        const showCanvasShadow = slide.get("template_id") !== "slidePlaceholder";

        return (
            <CanvasFrame
                ref={wrapperRef}
                opacity={opacity}
                width={canvasWidth}
                height={canvasHeight}
                boxShadow={showCanvasShadow ? "rgb(0 0 0 / 40%) 0px 26px 40px" : "none"}
                className={[currentSlide === slide ? "current_slide" : null, disableUserSelect ? "disable-user-select" : null].filter(Boolean).join(" ")}
            />
        );
    }
}

