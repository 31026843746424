import React from "react";
import styled from "styled-components";

const ThemeContainer = styled.div`
    padding: 0 0 0 36px;
    
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
        color: #333;
        margin-bottom: 10px !important;
        margin-right: 12px;
        margin-top: 18px;
    }

    & hr {
        margin: 30px 30px 30px 0px;
        background: #bbb;
    }
`;

const ThemeSection = props => {
    const { children } = props;
    return <ThemeContainer>{children}</ThemeContainer>;
};
export default ThemeSection;
