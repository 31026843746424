import React from "react";

export function Divider(props) {
    let styles;
    if (props.vertical) {
        styles = {
            width: 1,
            background: props.color || "rgba(0, 0, 0, 0.1)",
            height: "100%",
            marginLeft: props.margin != null ? props.margin : 0,
            marginRight: props.margin != null ? props.margin : 0,
            paddingLeft: 0,
            paddingRight: 0,
            ...props.style
        };
    } else {
        styles = {
            height: 1,
            background: props.color || "rgba(0, 0, 0, 0.1)",
            width: "100%",
            marginTop: props.margin != null ? props.margin : 0,
            marginBottom: props.margin != null ? props.margin : 0,
            paddingTop: 0,
            paddingBottom: 0,
            ...props.style
        };
    }

    return (
        <div className="divider" style={styles} />
    );
}
