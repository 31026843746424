import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";

const BaseSuggestion = ({ classes, suggestion }) => {
    return (
        <div>
            {suggestion.photoUrl
                ? <img className={classes.avatar} src={suggestion.photoUrl}/>
                : <i className="micon"
                    style={{ verticalAlign: "middle", paddingRight: "4px", color: "#797676" }}
                >person</i>
            }
            {suggestion.name ? (
                <Fragment>
                    <span className={classes.suggestionName}>
                        {suggestion.name}
                    </span>
                    <span className={classes.suggestionName}>
                        ({suggestion.email})
                    </span>
                </Fragment>
            ) : (
                <span className={classes.suggestionName}>
                    {suggestion.email}
                </span>
            )}
        </div>
    );
};

const Suggestion = withStyles({
    avatar: {
        width: 19,
        height: 19,
        display: "inline-block",
        marginRight: 5,
        verticalAlign: "middle"
    },
    suggestionName: {
        display: "inline-block",
        verticalAlign: "middle",
        marginRight: 5
    }
})(BaseSuggestion);

export { Suggestion };
