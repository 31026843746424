import { app } from "js/namespaces";
import React, { Component } from "reactn";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip, Icon } from "@material-ui/core";
import {
    BlueButton,
    Section,
    UIPane,
    UIPaneContents,
    UIPaneHeader
} from "js/react/components/UiComponents";
import { BlueSwitch } from "js/react/components/Switch";
import Loadable from "js/react/components/Loadable";
import Api from "js/core/api";
import { Gap20 } from "js/react/components/Gap";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import getLogger from "js/core/logger";

const logger = getLogger();

const styles = {
    apiKeyContainer: {
        display: "flex",
        flexFlow: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "800px",
        backgroundColor: "#383a3f",
        height: "26px",
        marginBottom: "3px",
        borderRadius: "5px"
    },
    apiKey: {
        marginLeft: "10px",
        fontWeight: 600,
        color: "#ccc"
    },
    apiKeyIcon: {
        fontSize: "16px",
        marginLeft: "7px",
        marginRight: "7px",
        marginBottom: "-2px",
        cursor: "pointer",
        color: "#bebfc1",
        "&:active": {
            color: "#ccc"
        }
    },
    noKeysNoticeText: {
        fontSize: "14px",
        width: "70%",
        opacity: 0.8
    }
};

class ApiPane extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Ensuring backwards compatibility, assuming isApiEnabled == false unless it's explicitly set to true
            isApiEnabled: app.user.get("isApiEnabled") === true ? true : false,
            apiKeys: {},
            initialFetching: true,
            userSettingsFetching: false,
            apiKeysFetching: false
        };
    }

    componentDidMount() {
        Api.apiKeys.get()
            .then(apiKeys => {
                this.setState({ apiKeys, initialFetching: false });
            })
            .catch(err => {
                logger.error(err, "[ApiPane] componentDidMount() failed");
            });
    }

    async toggleIsApiEnabled() {
        const { isApiEnabled } = this.state;

        this.setState({ isApiEnabled: !isApiEnabled, userSettingsFetching: true });

        try {
            app.user.update({ isApiEnabled: !isApiEnabled });
            await app.user.updatePromise;
        } catch (err) {
            logger.error(err, "[ApiPane] toggleIsApiEnabled() failed");
            this.setState({ isApiEnabled });
        } finally {
            this.setState({ userSettingsFetching: false });
        }
    }

    async addApiKey() {
        this.setState({ apiKeysFetching: true });

        try {
            const updatedApiKeys = await Api.apiKeys.post();
            this.setState({ apiKeys: updatedApiKeys });
        } catch (err) {
            logger.error(err, "[ApiPane] addApiKey() failed");
        } finally {
            this.setState({ apiKeysFetching: false });
        }
    }

    async deleteApiKey(apiKey) {
        this.setState({ apiKeysFetching: true });

        try {
            const updatedApiKeys = await Api.apiKeys.delete({ apiKey });
            this.setState({ apiKeys: updatedApiKeys });
        } catch (err) {
            logger.error(err, "[ApiPane] deleteApiKey() failed");
        } finally {
            this.setState({ apiKeysFetching: false });
        }
    }

    render() {
        const { initialFetching, apiKeysFetching, userSettingsFetching, isApiEnabled, apiKeys } = this.state;
        const { classes } = this.props;

        const hasApiKeys = Object.keys(apiKeys).length > 0;

        return (
            <UIPane>
                <UIPaneHeader>Beautiful.ai API</UIPaneHeader>
                <UIPaneContents>
                    <Loadable isLoading={initialFetching}>
                        <Section title="Enable Beautiful.ai API">
                            <BlueSwitch
                                checked={isApiEnabled}
                                onChange={() => this.toggleIsApiEnabled()}
                                disabled={userSettingsFetching}
                                label="Enable Beautiful.ai API"
                            />
                        </Section>
                        <Section title="API Keys">
                            {hasApiKeys && Object.keys(apiKeys).map(apiKey => (
                                <div className={classes.apiKeyContainer} key={apiKey}>
                                    <span className={classes.apiKey} data-test="api-key">{apiKey}</span>
                                    <div>
                                        <Tooltip title="Copy to clipboard">
                                            <Icon
                                                disabled={apiKeysFetching}
                                                className={classes.apiKeyIcon}
                                                onClick={() =>
                                                    clipboardWrite({
                                                        [ClipboardType.TEXT]: apiKey,
                                                    })
                                                }>
                                                file_copy
                                            </Icon>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <Icon
                                                disabled={apiKeysFetching}
                                                className={classes.apiKeyIcon}
                                                onClick={() => this.deleteApiKey(apiKey)}>
                                                delete
                                            </Icon>
                                        </Tooltip>
                                    </div>
                                </div>
                            ))}
                            {!hasApiKeys && <div className={classes.noKeysNoticeText}>You have no API keys yet</div>}
                            <Gap20 />
                            <BlueButton
                                disabled={apiKeysFetching}
                                onClick={() => this.addApiKey()}
                            >
                                Add API Key
                            </BlueButton>
                        </Section>
                    </Loadable>
                </UIPaneContents>
            </UIPane>
        );
    }
}

export default withStyles(styles)(ApiPane);
