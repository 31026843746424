import { ColorPicker } from "js/Components/ColorPicker";
import ThemeEditorController from "js/editor/ThemeEditor/ThemeEditorController";
import { NumericStepper } from "js/Components/NumericStepper";
import { Icon } from "@material-ui/core";
import React, { Component } from "react";
import styled from "styled-components";
import { themeColors } from "js/react/sharedStyles";
import { Input } from "js/Components/Input";

const DeleteStopButton = styled.div`
    background: ${themeColors.ui_blue};
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    opacity: 0;
    cursor: pointer;

    .MuiIcon-root {
        font-size: 12px;
    }
`;

const GradientStopRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0px;
    gap: 10px;

    &:hover {
        ${DeleteStopButton} {
            opacity: 1;
        }
    }
`;

class ColorTextInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.stop.toHexString()
        };
    }

    inputRef = React.createRef();

    componentDidUpdate(prevProps, prevState, snapshot) {
        let prevValue = prevProps.stop.color;
        let newValue = this.props.stop.color;
        if (newValue && newValue !== prevValue) {
            this.setState({ value: this.props.stop.toHexString() });
        }
    }

    handleChange(value, stop) {
        let newColor = ThemeEditorController.getColor(value);

        if (newColor.isValid()) {
            stop.color = newColor;
            this.setState({ value: newColor.toHexString().toUpperCase() });
            return stop;
        } else {
            this.setState({ value: this.props.stop.toHexString() });
            return stop;
        }
    }

    render() {
        const { value: propValue, index, stops, onChange, stop, ...rest } = this.props;
        const { value } = this.state;

        return (
            <Input
                value={value}
                width={120}
                onBlur={(event => {
                    const newStop = this.handleChange(event.target.value, stop);
                    onChange(newStop);
                })}
                onChange={event => {
                    this.setState({ value: event.target.value });

                    let newColor = ThemeEditorController.getColor(event.target.value);
                    if (newColor.isValid()) {
                        stop.color = newColor;
                        onChange(stop);
                    }
                }}
            />);
    }
}

export class GradientEditorRow extends Component {
    render() {
        const { stops, stop, disabled, onChange } = this.props;

        const gradientPaletteColors = ThemeEditorController.gradientPaletteColors();

        return (<GradientStopRow>
            <ColorPicker
                value={stop.color}
                onChange={newColor => {
                    stop.color = newColor;
                    onChange(stop);
                }}
                showColorPicker
                disableAlpha
                customPaletteColors = {gradientPaletteColors}
                disabled={disabled}
            />
            <ColorTextInput
                stop={stop}
                index={stop.order}
                width={120}
                onChange={newStop=> onChange(newStop)}
            />
            <NumericStepper
                value={ stop.position / 100}
                onChange={position => {
                    stop.position = Math.clamp(0, 100, position * 100);
                    onChange(stop);
                }}
                min={0} max={1} step={0.01} format="percent"
            />
            {stops.length > 2 &&
                <DeleteStopButton
                    onClick={() => this.props.onDelete(stop)}>
                    <Icon>close</Icon>
                </DeleteStopButton>}

        </GradientStopRow>);
    }
}
