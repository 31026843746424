import React, { Component } from "reactn";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";

import DialogTitleWithClose from "js/react/components/DialogTitleWithClose";
import { RowGrid } from "js/react/components/LayoutGrid";
import { BeautifulDialog, DialogContent, ShowErrorDialog, ShowMessageDialog } from "js/react/components/Dialogs/BaseDialog";
import { Gap10, FlexSpacer } from "js/react/components/Gap";
import Spinner from "js/react/components/Spinner";
import Api from "js/core/api";
import { trackActivity } from "js/core/utilities/utilities";
import Icon from "js/react/components/Icon";
import InviteInput from "js/react/components/InviteInput/InviteInput";
import { BlueButton } from "js/react/components/UiComponents";
import { sanitizeHtml } from "js/core/utilities/dompurify";
import getLogger from "js/core/logger";

const logger = getLogger();

export default class ReferralEmailDialog extends Component {
    state = {
        previewHTML: null,
        showSpinner: false,
        isInviteDisabled: true,
        emails: [],
    };

    static get type() {
        return "EmailReferral";
    }

    handleInputChange = (emails, isInviteDisabled) => {
        this.setState({ emails, isInviteDisabled });
    };

    handleAccept = async () => {
        const emails = this.state.emails;
        try {
            this.setState({ showSpinner: true });

            // check for registered emails and warn
            const res = await Api.referrals.post({
                type: "check",
                emails
            });

            const registeredEmails = Object.entries(res)
                .filter(([, info]) => info !== null)
                .map(([email]) => email);

            if (registeredEmails.length > 0) {
                ShowMessageDialog({
                    title: "",
                    message: (
                        <>
                            <Icon
                                iconName={"new_releases"}
                                style={{ position: "absolute", color: "#EBA928" }}
                            />
                            <div style={{ paddingLeft: 40 }}>
                                <p>
                                    The following recipients are already in our system
                                    and have been omitted from your invitations:
                                </p>
                                <p>
                                    <i>{registeredEmails.join(", ")}</i>
                                </p>
                            </div>
                        </>
                    ),
                    buttonText: "Got It"
                });
            }

            const unregisteredEmails = Object.entries(res)
                .filter(([, info]) => info === null)
                .map(([email]) => email);

            // send to the unregistered emails
            if (unregisteredEmails.length > 0) {
                await Api.referrals.put({
                    type: "send",
                    emails: unregisteredEmails
                });
            }

            trackActivity("Referral", "SendEmails", null, null, {
                recipient_emails: emails,
            });

            if (unregisteredEmails.length > 0) {
                ShowMessageDialog({
                    title: "Thanks for sharing Beautiful.ai!",
                    message: "Your invitations have been sent. You can check on the status of your referrals here at any time.",
                    buttonText: "Got it"
                });
            }
        } catch (err) {
            ShowErrorDialog({
                error: "Error while sharing",
                message: err.message
            });
            logger.error(err, "[ReferralEmailDialog] handleAccept() failed");
        } finally {
            this.props.closeDialog();
        }
    };

    handlePreviewEmail = async () => {
        this.setState({
            showSpinner: true
        });
        const response = await Api.renderReferralEmail.post();
        this.setState({
            previewHTML: response.html,
            showSpinner: false
        });
    };

    closePreviewEmail = async () => {
        this.setState({
            previewHTML: null
        });
    };

    render() {
        const { previewHTML, showSpinner } = this.state;

        return (
            <React.Fragment>
                {/* Email Preview Dialog */}
                <Dialog
                    open={!!previewHTML}
                    onClose={(e, reason) => {
                        if (reason === "escapeKeyDown") {
                            e.stopPropagation();
                        } else {
                            this.closePreviewEmail();
                        }
                    }}
                    maxWidth={false}
                >
                    <DialogTitleWithClose
                        onClose={this.closePreviewEmail}
                        showCloseButton
                    >
                        Preview
                    </DialogTitleWithClose>
                    <DialogContent
                        dangerouslySetInnerHTML={{ __html: sanitizeHtml(previewHTML) }}
                    ></DialogContent>
                </Dialog>

                {/* Main Content Dialog */}
                <BeautifulDialog
                    closeDialog={this.props.closeDialog}
                >
                    {showSpinner && <Spinner />}
                    <DialogTitle>
                        Thanks for sharing Beautiful.ai!
                    </DialogTitle>
                    <DialogContent>
                        <h3>
                            Add emails of those you’d like to invite and we’ll do the rest.
                        </h3>
                        <Gap10 />
                        <RowGrid>
                            <InviteInput
                                onInputChange={this.handleInputChange}
                                tags={this.state.emails}
                            />
                        </RowGrid>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handlePreviewEmail}>Preview Email</Button>
                        <FlexSpacer />
                        <BlueButton
                            color="primary"
                            disabled={this.state.isInviteDisabled}
                            onClick={this.handleAccept}
                        >Send Email
                        </BlueButton>
                    </DialogActions>

                </BeautifulDialog>
            </React.Fragment>
        );
    }
}
